import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card"
import { Label } from "@/components/ui/label"
import { Input } from "@/components/ui/input"
import { Switch } from "@/components/ui/switch"
import { Separator } from "@/components/ui/separator"

interface SettingsFeesProps {
  orgForm: {
    lateSignOutFeeAmount: number | null
    lateSignOutFeeIntervalMinutes: number | null
    lateSignOutFeeMinutesGracePeriod: number | null
    autoAddLateSignOutFee: boolean
  }
  handleOrgChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleSwitchChange: (name: string, checked: boolean) => void
}

export function SettingsFees({
  orgForm,
  handleOrgChange,
  handleSwitchChange,
}: SettingsFeesProps) {
  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">Fee Settings</h3>
        <p className="text-sm text-muted-foreground">
          Manage your organization's fee structure and policies.
        </p>
      </div>
      <Separator />

      <div className="space-y-6">
        <div>
          <h3 className="text-lg font-medium">Late Pickup Fees</h3>
          <p className="text-sm text-muted-foreground">
            Configure how late pickup fees are calculated and applied.
          </p>
        </div>

        <div className="space-y-8">
          <div className="flex flex-row items-center justify-between rounded-lg border p-4">
            <div className="space-y-0.5">
              <Label htmlFor="autoAddLateSignOutFee">Automatic Late Fees</Label>
              <p className="text-sm text-muted-foreground">
                Automatically add late pickup fees when children are picked up after closing time.
              </p>
            </div>
            <Switch
              id="autoAddLateSignOutFee"
              checked={orgForm.autoAddLateSignOutFee}
              onCheckedChange={(checked) =>
                handleSwitchChange("autoAddLateSignOutFee", checked)
              }
            />
          </div>

          <div className="grid gap-6">
            <div className="flex flex-col space-y-2">
              <Label htmlFor="lateSignOutFeeAmount">Fee Amount</Label>
              <div className="grid gap-4 sm:grid-cols-2">
                <div className="grid gap-2">
                  <Input
                    id="lateSignOutFeeAmount"
                    name="lateSignOutFeeAmount"
                    type="number"
                    value={orgForm.lateSignOutFeeAmount || ""}
                    onChange={handleOrgChange}
                    placeholder="0.00"
                  />
                  <p className="text-[0.8rem] text-muted-foreground">
                    Amount charged per interval in dollars
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col space-y-2">
              <Label htmlFor="lateSignOutFeeIntervalMinutes">Fee Interval</Label>
              <div className="grid gap-4 sm:grid-cols-2">
                <div className="grid gap-2">
                  <Input
                    id="lateSignOutFeeIntervalMinutes"
                    name="lateSignOutFeeIntervalMinutes"
                    type="number"
                    value={orgForm.lateSignOutFeeIntervalMinutes || ""}
                    onChange={handleOrgChange}
                    placeholder="15"
                  />
                  <p className="text-[0.8rem] text-muted-foreground">
                    How often the fee is charged (in minutes)
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col space-y-2">
              <Label htmlFor="lateSignOutFeeMinutesGracePeriod">Grace Period</Label>
              <div className="grid gap-4 sm:grid-cols-2">
                <div className="grid gap-2">
                  <Input
                    id="lateSignOutFeeMinutesGracePeriod"
                    name="lateSignOutFeeMinutesGracePeriod"
                    type="number"
                    value={orgForm.lateSignOutFeeMinutesGracePeriod || ""}
                    onChange={handleOrgChange}
                    placeholder="5"
                  />
                  <p className="text-[0.8rem] text-muted-foreground">
                    Minutes allowed after closing time before fees begin
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
