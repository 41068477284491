import { useState, useEffect } from "react"
import { gql, useQuery } from "@apollo/client"
import { useLocation } from "wouter"
import { usePostHog } from "posthog-js/react"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { Card, CardHeader, CardContent } from "@/components/ui/card"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Input } from "@/components/ui/input"
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination"
import { Skeleton } from "@/components/ui/skeleton"
import { Spinner } from "@/components/ui/spinner"
import { Users } from "lucide-react"
import { CreateStudentDialog } from "@/components/dialogs/CreateStudentDialog"

const STUDENTS_QUERY = gql`
  query GetStudents($after: String, $first: Int!, $archived: Boolean, $searchTerm: String) {
    students(after: $after, first: $first, archived: $archived, searchTerm: $searchTerm) {
      edges {
        node {
          uuid
          firstName
          lastName
          dateOfBirth
          gender
          enrolled
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`

const StudentsPageSkeleton = () => {
  return (
    <div className="space-y-6 p-6">
      {/* Title and Controls */}
      <div className="flex justify-between items-center">
        <div className="space-y-1">
          <h2 className="text-2xl font-bold tracking-tight">Students</h2>
        </div>
        <div className="flex items-center space-x-2">
          <Skeleton className="h-10 w-[280px]" /> {/* Search input */}
          <Skeleton className="h-10 w-[180px]" /> {/* Status dropdown */}
          <Skeleton className="h-10 w-[100px]" /> {/* Create button */}
        </div>
      </div>

      {/* Table */}
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>First Name</TableHead>
              <TableHead>Last Name</TableHead>
              <TableHead>Date of Birth</TableHead>
              <TableHead>Gender</TableHead>
              <TableHead>Enrolled</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {Array.from({ length: 5 }).map((_, index) => (
              <TableRow key={index}>
                <TableCell><Skeleton className="h-4 w-[120px]" /></TableCell>
                <TableCell><Skeleton className="h-4 w-[120px]" /></TableCell>
                <TableCell><Skeleton className="h-4 w-[100px]" /></TableCell>
                <TableCell><Skeleton className="h-4 w-[80px]" /></TableCell>
                <TableCell><Skeleton className="h-4 w-[60px]" /></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

export default function Students() {
  const [currentPage, setCurrentPage] = useState(1)
  const [filter, setFilter] = useState("active")
  const [searchTerm, setSearchTerm] = useState("")
  const [students, setStudents] = useState([])
  const [isFiltering, setIsFiltering] = useState(false)
  const studentsPerPage = 10

  const [location, navigate] = useLocation()
  const posthog = usePostHog()

  const { data, loading, refetch } = useQuery(STUDENTS_QUERY, {
    variables: {
      after: null,
      first: studentsPerPage,
      archived: filter === "archived",
      searchTerm: searchTerm,
    },
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    posthog.capture("students_page_viewed")
  }, [posthog])

  useEffect(() => {
    if (data?.students?.edges) {
      console.log(`[${new Date().toISOString()}] Setting students:`, {
        raw: data,
        edges: data.students.edges,
        currentPage,
        studentsPerPage
      });
      setStudents(data.students.edges);
    }
  }, [data]);

  useEffect(() => {
    setIsFiltering(true);
    const timeoutId = setTimeout(() => {
      refetch({
        archived: filter === "archived",
        searchTerm: searchTerm,
        after: null,
        first: studentsPerPage,
      }).finally(() => {
        setIsFiltering(false);
      });
      setCurrentPage(1);
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [filter, searchTerm]);

  const pageInfo = data?.students?.pageInfo

  const totalPages = Math.max(
    Math.ceil(students.length / studentsPerPage),
    currentPage + (pageInfo?.hasNextPage ? 1 : 0)
  )

  const handlePageChange = (newPage: number) => {
    if (newPage > currentPage && pageInfo?.hasNextPage) {
      refetch({
        after: pageInfo.endCursor,
        first: studentsPerPage,
        archived: filter === "archived",
        searchTerm: searchTerm,
      })
    }
    setCurrentPage(newPage)
  }

  const displayedStudents = students.slice(
    (currentPage - 1) * studentsPerPage,
    currentPage * studentsPerPage
  );
  console.log(`[${new Date().toISOString()}] Calculated displayedStudents:`, {
    displayedStudents,
    studentsLength: students.length,
    currentPage,
    studentsPerPage
  });

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    })
  }

  return (
    <div className="space-y-6 p-6">
      <div className="flex justify-between items-center">
        <div className="space-y-1">
          <h2 className="text-2xl font-bold tracking-tight">Students</h2>
        </div>
        <div className="flex items-center space-x-2">
          <Input
            type="text"
            placeholder="Search students..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-[280px]"
          />
          <Select value={filter} onValueChange={setFilter}>
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Select status" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="active">Active</SelectItem>
              <SelectItem value="archived">Archived</SelectItem>
            </SelectContent>
          </Select>
          <CreateStudentDialog />
        </div>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>First Name</TableHead>
              <TableHead>Last Name</TableHead>
              <TableHead>Date of Birth</TableHead>
              <TableHead>Gender</TableHead>
              <TableHead>Enrolled</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {loading && !data ? (
              Array.from({ length: 5 }).map((_, index) => (
                <TableRow key={index}>
                  <TableCell><Skeleton className="h-4 w-[100px]" /></TableCell>
                  <TableCell><Skeleton className="h-4 w-[100px]" /></TableCell>
                  <TableCell><Skeleton className="h-4 w-[100px]" /></TableCell>
                  <TableCell><Skeleton className="h-4 w-[80px]" /></TableCell>
                  <TableCell><Skeleton className="h-4 w-[60px]" /></TableCell>
                </TableRow>
              ))
            ) : isFiltering ? (
              <TableRow>
                <TableCell colSpan={5} className="text-center">
                  <div className="flex items-center justify-center space-x-2">
                    <Spinner className="h-4 w-4" />
                    <span>Loading...</span>
                  </div>
                </TableCell>
              </TableRow>
            ) : displayedStudents.length > 0 ? (
              displayedStudents.map(({ node: student }) => (
                <TableRow
                  key={student.uuid}
                  className="cursor-pointer hover:bg-muted/50"
                  onClick={() => navigate(`/students/${student.uuid}`)}
                >
                  <TableCell className="font-medium">
                    {student.firstName}
                  </TableCell>
                  <TableCell>{student.lastName}</TableCell>
                  <TableCell>{formatDate(student.dateOfBirth)}</TableCell>
                  <TableCell>{student.gender}</TableCell>
                  <TableCell>{student.enrolled ? "Yes" : "No"}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} className="text-center py-12">
                  <div className="space-y-2">
                    <Users className="h-12 w-12 text-muted-foreground mx-auto" />
                    <p className="text-md font-semibold text-muted-foreground">
                      No students found
                    </p>
                    <p className="text-sm text-muted-foreground">
                      {filter === "active"
                        ? "There are no active students matching your search."
                        : "There are no archived students matching your search."}
                    </p>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      {pageInfo?.hasNextPage && (
        <div className="mt-4 flex justify-center">
          <Pagination>
            <PaginationContent>
              <PaginationItem>
                <PaginationPrevious
                  onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
                  className={currentPage === 1 ? "pointer-events-none opacity-50" : ""}
                />
              </PaginationItem>
              {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                <PaginationItem key={page}>
                  <PaginationLink
                    isActive={currentPage === page}
                    onClick={() => handlePageChange(page)}
                  >
                    {page}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem>
                <PaginationNext
                  onClick={() => pageInfo?.hasNextPage && handlePageChange(currentPage + 1)}
                  className={!pageInfo?.hasNextPage ? "pointer-events-none opacity-50" : ""}
                />
              </PaginationItem>
            </PaginationContent>
          </Pagination>
        </div>
      )}
    </div>
  )
}
