import { useState, useEffect } from "react"
import { gql, useQuery, useMutation } from "@apollo/client"
import { useUser } from "@/hooks/useUser"
import { useLocation } from "wouter"
import { usePostHog } from "posthog-js/react"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination"
import { Badge } from "@/components/ui/badge"
import { Skeleton } from "@/components/ui/skeleton"
import {
  BanknoteIcon,
  AlertCircleIcon,
  ClockIcon,
  FileTextIcon,
  ArrowUpRightIcon,
  DownloadIcon,
} from "lucide-react"
import { Spinner } from "@/components/ui/spinner"
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "@/components/ui/alert-dialog"
import { toast } from "react-hot-toast"

const GET_INVOICES_QUERY = gql`
  query BillingPageInvoices($status: String, $searchTerm: String, $after: String, $first: Int!) {
    billingPageInvoices(status: $status, searchTerm: $searchTerm, after: $after, first: $first) {
      edges {
        node {
          uuid
          number
          dueDate
          date
          paid
          total
          amountPaid
          voided
          status
          student {
            uuid
            firstName
            lastName
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

const GET_BILLING_METRICS_QUERY = gql`
  query BillingMetrics {
    billingMetrics
  }
`;

const GENERATE_INVOICE_PDF = gql`
  mutation GenerateInvoicePdf($invoiceId: ID!) {
    generateInvoicePdf(invoiceId: $invoiceId) {
      pdfBase64
    }
  }
`;

const BillingPageSkeleton = () => {
  return (
    <div className="space-y-6 p-6">
      {/* Title Skeleton */}
      <div className="space-y-0.5">
        <Skeleton className="h-8 w-32" /> {/* Title */}
      </div>

      {/* Metrics Cards */}
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
        {[...Array(4)].map((_, i) => (
          <Card key={i}>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <Skeleton className="h-4 w-24" />
              <Skeleton className="h-8 w-8 rounded-md" />
            </CardHeader>
            <CardContent>
              <Skeleton className="h-7 w-20 mb-1" />
              <Skeleton className="h-4 w-32" />
            </CardContent>
          </Card>
        ))}
      </div>

      {/* Table Card */}
      <Card>
        <CardHeader className="flex justify-between items-center">
          <Skeleton className="h-5 w-32" />
          <div className="flex space-x-2">
            <Skeleton className="h-10 w-[280px]" /> {/* Search input */}
            <Skeleton className="h-10 w-[180px]" /> {/* Status dropdown */}
          </div>
        </CardHeader>
        <CardContent>
          <div className="rounded-md border">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead><Skeleton className="h-4 w-16" /></TableHead>
                  <TableHead><Skeleton className="h-4 w-32" /></TableHead>
                  <TableHead><Skeleton className="h-4 w-24" /></TableHead>
                  <TableHead><Skeleton className="h-4 w-24" /></TableHead>
                  <TableHead><Skeleton className="h-4 w-20" /></TableHead>
                  <TableHead><Skeleton className="h-4 w-24" /></TableHead>
                  <TableHead><Skeleton className="h-4 w-24" /></TableHead>
                  <TableHead><Skeleton className="h-4 w-20" /></TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {Array.from({ length: 5 }).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell><Skeleton className="h-4 w-16" /></TableCell>
                    <TableCell><Skeleton className="h-4 w-32" /></TableCell>
                    <TableCell><Skeleton className="h-4 w-24" /></TableCell>
                    <TableCell><Skeleton className="h-4 w-24" /></TableCell>
                    <TableCell><Skeleton className="h-4 w-20" /></TableCell>
                    <TableCell><Skeleton className="h-4 w-24" /></TableCell>
                    <TableCell><Skeleton className="h-4 w-24" /></TableCell>
                    <TableCell>
                      <div className="flex space-x-2">
                        <Skeleton className="h-8 w-8" /> {/* Download button */}
                        <Skeleton className="h-8 w-8" /> {/* Navigate button */}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

// Helper functions
const formatCurrency = (amount: number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount)
}

const formatDate = (dateString: string) => {
  return new Date(dateString).toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  })
}

// Status Badge component
const StatusBadge = ({ status }: { status: string }) => {
  const getVariant = (status: string) => {
    switch (status) {
      case 'PAID':
        return 'success'
      case 'OVERDUE':
        return 'destructive'
      case 'OPEN':
        return 'warning'
      default:
        return 'default'
    }
  }

  return (
    <Badge variant={getVariant(status)}>
      {status}
    </Badge>
  )
}

const downloadPDF = (base64PDF: string, filename: string) => {
  const link = document.createElement('a');
  link.href = `data:application/pdf;base64,${base64PDF}`;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default function BillingPage() {
  const [statusFilter, setStatusFilter] = useState("ALL")
  const [searchTerm, setSearchTerm] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [metrics, setMetrics] = useState({})
  const [invoices, setInvoices] = useState([])
  const [isFiltering, setIsFiltering] = useState(false)
  
  const invoicesPerPage = 10

  const { data, loading, refetch } = useQuery(GET_BILLING_METRICS_QUERY)
  const { data: invoicesData, refetch: invoicesRefetch, fetchMore } = useQuery(
    GET_INVOICES_QUERY,
    {
      variables: {
        status: statusFilter === "ALL" ? null : statusFilter,
        searchTerm: searchTerm,
        after: null,
        first: invoicesPerPage,
      },
      onCompleted: (data) => {
        console.log("GraphQL Response:", data);
      },
    }
  )

  const [location, navigate] = useLocation()
  const { user } = useUser()
  const posthog = usePostHog()

  const [generateInvoicePDF] = useMutation(GENERATE_INVOICE_PDF);

  useEffect(() => {
    posthog.capture('billing_page_viewed');
  }, [posthog]);

  useEffect(() => {
    if (data) {
      setMetrics(JSON.parse(data?.billingMetrics) || {});
    }
  }, [data]);

  useEffect(() => {
    if (invoicesData) {
      console.log(`[${new Date().toISOString()}] Setting invoices:`, {
        raw: invoicesData,
        edges: invoicesData?.billingPageInvoices?.edges,
        currentPage,
        invoicesPerPage
      });
      setInvoices(invoicesData?.billingPageInvoices?.edges || []);
    }
  }, [invoicesData]);

  useEffect(() => {
    console.log(`[${new Date().toISOString()}] Initial fetch effect triggered`);
    refetch();
    invoicesRefetch();
  }, []);

  useEffect(() => {
    setIsFiltering(true);
    const timeoutId = setTimeout(() => {
      invoicesRefetch({
        status: statusFilter === "ALL" ? null : statusFilter,
        searchTerm: searchTerm,
        after: null,
        first: invoicesPerPage,
      }).finally(() => {
        setIsFiltering(false);
      });
      setCurrentPage(1);
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [statusFilter, searchTerm]);

  const handleDownloadInvoice = async (invoice: any) => {
    toast.loading('Generating invoice...', { id: 'invoice-loading' });
    try {
      const { data } = await generateInvoicePDF({
        variables: { invoiceId: invoice.uuid }
      });
      downloadPDF(data.generateInvoicePdf.pdfBase64, `invoice-${invoice.number}.pdf`);
      toast.dismiss('invoice-loading');
    } catch (error) {
      toast.dismiss('invoice-loading');
      toast.error('Failed to generate invoice PDF');
    }
  };

  if (loading) return <BillingPageSkeleton />

  const pageInfo = invoicesData?.billingPageInvoices?.pageInfo;
  const totalPages = Math.max(
    Math.ceil(invoices.length / invoicesPerPage),
    currentPage + (pageInfo?.hasNextPage ? 1 : 0)
  );

  const handlePageChange = (newPage: number) => {
    if (newPage > currentPage && pageInfo?.hasNextPage) {
      fetchMore({
        variables: { 
          after: pageInfo.endCursor, 
          first: invoicesPerPage,
          status: statusFilter === "ALL" ? null : statusFilter,
          searchTerm: searchTerm,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            billingPageInvoices: {
              ...fetchMoreResult.billingPageInvoices,
              edges: [
                ...prev.billingPageInvoices.edges,
                ...fetchMoreResult.billingPageInvoices.edges,
              ],
            },
          };
        },
      });
    }
    setCurrentPage(newPage);
  };

  const displayedInvoices = invoices.slice(
    (currentPage - 1) * invoicesPerPage,
    currentPage * invoicesPerPage
  );
  console.log(`[${new Date().toISOString()}] Calculated displayedInvoices:`, {
    displayedInvoices,
    invoicesLength: invoices.length,
    currentPage,
    invoicesPerPage
  });

  return (
    <div className="space-y-6 p-6">
      <div className="space-y-0.5">
        <h2 className="text-2xl font-bold tracking-tight">Billing</h2>
      </div>
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
        {/* Outstanding Card */}
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Outstanding</CardTitle>
            <div className="rounded-md bg-yellow-50 p-2 dark:bg-yellow-900/20">
              <ClockIcon className="h-4 w-4 text-yellow-600 dark:text-yellow-400" />
            </div>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">
              {metrics.openInvoiceCount}
            </div>
            <p className="text-xs text-muted-foreground">
              Open invoices
            </p>
          </CardContent>
        </Card>

        {/* Open Balance Card */}
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Open Balance</CardTitle>
            <div className="rounded-md bg-blue-50 p-2 dark:bg-blue-900/20">
              <FileTextIcon className="h-4 w-4 text-blue-600 dark:text-blue-400" />
            </div>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">
              {formatCurrency(metrics.openInvoiceSum)}
            </div>
            <p className="text-xs text-muted-foreground">
              Total unpaid amount
            </p>
          </CardContent>
        </Card>

        {/* Overdue Card */}
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Overdue</CardTitle>
            <div className="rounded-md bg-red-50 p-2 dark:bg-red-900/20">
              <AlertCircleIcon className="h-4 w-4 text-red-600 dark:text-red-400" />
            </div>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">
              {formatCurrency(metrics.overdueInvoiceSum)}
            </div>
            <p className="text-xs text-muted-foreground">
              Past due amount
            </p>
          </CardContent>
        </Card>

        {/* Recent Payments Card */}
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Recent Payments</CardTitle>
            <div className="rounded-md bg-green-50 p-2 dark:bg-green-900/20">
              <BanknoteIcon className="h-4 w-4 text-green-600 dark:text-green-400" />
            </div>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">
              {formatCurrency(metrics.recentPaymentsSum)}
            </div>
            <p className="text-xs text-muted-foreground">
              Last 30 days
            </p>
          </CardContent>
        </Card>
      </div>
      <div className="flex justify-between items-center">
        <div className="space-y-1">
          <h3 className="text-lg font-medium">Invoices</h3>
        </div>
        <div className="flex items-center space-x-2">
          <Input
            type="text"
            placeholder="Search by student name..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-[280px]"
          />
          <Select
            value={statusFilter}
            onValueChange={(value) => setStatusFilter(value)}
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Select status" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="ALL">All Statuses</SelectItem>
              <SelectItem value="OPEN">Open</SelectItem>
              <SelectItem value="OVERDUE">Overdue</SelectItem>
              <SelectItem value="PAID">Paid</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Number</TableHead>
              <TableHead>Student</TableHead>
              <TableHead>Date</TableHead>
              <TableHead>Due Date</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Amount</TableHead>
              <TableHead>Paid</TableHead>
              <TableHead className="w-[50px]">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {loading ? (
              Array.from({ length: 5 }).map((_, index) => (
                <TableRow key={index}>
                  <TableCell><Skeleton className="h-4 w-[100px]" /></TableCell>
                  <TableCell><Skeleton className="h-4 w-[200px]" /></TableCell>
                  <TableCell><Skeleton className="h-4 w-[100px]" /></TableCell>
                  <TableCell><Skeleton className="h-4 w-[100px]" /></TableCell>
                  <TableCell><Skeleton className="h-4 w-[80px]" /></TableCell>
                  <TableCell><Skeleton className="h-4 w-[100px]" /></TableCell>
                  <TableCell><Skeleton className="h-4 w-[100px]" /></TableCell>
                  <TableCell><Skeleton className="h-4 w-[40px]" /></TableCell>
                </TableRow>
              ))
            ) : isFiltering ? (
              <TableRow>
                <TableCell colSpan={8} className="text-center">
                  <div className="flex items-center justify-center space-x-2">
                    <Spinner className="h-4 w-4" />
                    <span>Loading...</span>
                  </div>
                </TableCell>
              </TableRow>
            ) : Array.isArray(displayedInvoices) && displayedInvoices.length > 0 ? (
              displayedInvoices.map(({ node: invoice }) => (
                <TableRow 
                  key={invoice.uuid}
                  className="cursor-pointer hover:bg-muted/50"
                  onClick={() => navigate(`/students/${invoice.student.uuid}/billing`)}
                >
                  <TableCell className="font-medium">{invoice.number}</TableCell>
                  <TableCell>{`${invoice.student.firstName} ${invoice.student.lastName}`}</TableCell>
                  <TableCell>{formatDate(invoice.date)}</TableCell>
                  <TableCell>{formatDate(invoice.dueDate)}</TableCell>
                  <TableCell>
                    <StatusBadge status={invoice.status} />
                  </TableCell>
                  <TableCell>{formatCurrency(invoice.total)}</TableCell>
                  <TableCell>{formatCurrency(invoice.amountPaid)}</TableCell>
                  <TableCell>
                    <div className="flex space-x-2">
                      <AlertDialog>
                        <AlertDialogTrigger asChild>
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            title="Download Invoice"
                          >
                            <DownloadIcon className="h-4 w-4" />
                          </Button>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                          <AlertDialogHeader>
                            <AlertDialogTitle>Download Invoice</AlertDialogTitle>
                            <AlertDialogDescription>
                              Are you sure you want to download this invoice?
                            </AlertDialogDescription>
                          </AlertDialogHeader>
                          <AlertDialogFooter>
                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                            <AlertDialogAction
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDownloadInvoice(invoice);
                              }}
                            >
                              Download
                            </AlertDialogAction>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialog>
                      
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/students/${invoice.student.uuid}/billing`);
                        }}
                      >
                        <ArrowUpRightIcon className="h-4 w-4" />
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} className="text-center py-12">
                  <div className="space-y-2">
                    <FileTextIcon className="h-12 w-12 text-muted-foreground mx-auto" />
                    <p className="text-md font-semibold text-muted-foreground">No invoices found</p>
                    <p className="text-sm text-muted-foreground">
                      {invoices.length === 0 
                        ? "No invoices match your current filters."
                        : "Error displaying invoices."}
                    </p>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      {pageInfo?.hasNextPage && (
        <div className="mt-4 flex justify-between">
          <Pagination>
            <PaginationContent>
              <PaginationItem>
                <PaginationPrevious 
                  onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
                  className={currentPage === 1 ? "pointer-events-none opacity-50" : ""}
                />
              </PaginationItem>
              {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                <PaginationItem key={page}>
                  <button
                    className={`px-3 py-1 rounded-md ${
                      currentPage === page
                        ? "bg-primary text-primary-foreground"
                        : "hover:bg-muted"
                    }`}
                    onClick={() => handlePageChange(page)}
                  >
                    {page}
                  </button>
                </PaginationItem>
              ))}
              <PaginationItem>
                <PaginationNext 
                  onClick={() => pageInfo?.hasNextPage && handlePageChange(currentPage + 1)}
                  className={(!pageInfo?.hasNextPage && currentPage === totalPages) ? "pointer-events-none opacity-50" : ""}
                />
              </PaginationItem>
            </PaginationContent>
          </Pagination>
        </div>
      )}
    </div>
  )
}
