import { Button } from "@/components/ui/button"
import { ArrowUpRight, AlertTriangle } from "lucide-react"
import { CheckCircle2 } from "lucide-react"
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"

interface SettingsStripeProps {
  user: any;
  handleStripeSetup: () => void;
}

export function SettingsStripe({ user, handleStripeSetup }: SettingsStripeProps) {
  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">Payment Processing</h3>
        <p className="text-sm text-muted-foreground">
          Configure your payment processing settings with Stripe to start accepting payments.
        </p>
      </div>
      {user?.organization?.stripeActionRequired && (
        <Alert variant="destructive" className="border-red-500 bg-red-50 dark:bg-red-900/20">
          <AlertTriangle className="h-5 w-5" />
          <AlertTitle className="font-semibold">Action Required: Stripe Account Update</AlertTitle>
          <AlertDescription className="mt-2">
            Your Stripe account requires immediate attention. Please review your account settings
            to continue processing payments. Failing to address this may impact your ability to process payments.
          </AlertDescription>
          <div className="mt-4">
            <Button variant="destructive" onClick={handleStripeSetup}>
              Review Stripe Account Now
              <ArrowUpRight className="w-4 h-4 ml-2" />
            </Button>
          </div>
        </Alert>
      )}
      <div className="flex items-center justify-between p-4 rounded-lg bg-muted border">
        <div className="space-y-1">
          <div className="flex items-center gap-2">
            {user?.organization?.stripeOnboardingComplete && <CheckCircle2 className="w-5 h-5 text-primary" />}
            <h4 className="font-medium">
              {user?.organization?.stripeOnboardingComplete
                ? "Stripe Account Connected"
                : "Connect Stripe Account"}
            </h4>
          </div>
          <p className="text-sm text-muted-foreground">
            {user?.organization?.stripeOnboardingComplete
              ? "Your Stripe account is connected and ready to process payments"
              : "Set up your Stripe account to start accepting payments from your customers"}
          </p>
        </div>
        <Button onClick={handleStripeSetup}>
          {user?.organization?.stripeOnboardingComplete
            ? "Manage Stripe Account"
            : "Get Started with Stripe"}
          <ArrowUpRight className="w-4 h-4 ml-2" />
        </Button>
      </div>
    </div>
  )
} 