import { Label } from "@/components/ui/label"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"
import { HelpCircle } from "lucide-react"
import { cn } from "@/lib/utils"

interface DayPickerProps {
  value: string[]
  onChange: (days: string[]) => void
}

const DAYS = [
  { short: 'Sun', full: 'Sunday' },
  { short: 'Mon', full: 'Monday' },
  { short: 'Tue', full: 'Tuesday' },
  { short: 'Wed', full: 'Wednesday' },
  { short: 'Thu', full: 'Thursday' },
  { short: 'Fri', full: 'Friday' },
  { short: 'Sat', full: 'Saturday' }
] as const

export function DayPicker({ value, onChange }: DayPickerProps) {
  return (
    <div className="space-y-2">
      <div className="flex items-center gap-2 mb-2">
        <Label>Days of Week</Label>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <HelpCircle className="h-4 w-4 text-muted-foreground" />
            </TooltipTrigger>
            <TooltipContent>
              Choose the days of the week that the program will be active. If drop-in, leave blank.
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <div className="flex justify-between gap-2">
        {DAYS.map(({ short, full }) => (
          <label
            key={short}
            className="group relative cursor-pointer"
          >
            <input
              type="checkbox"
              value={full}
              checked={value.includes(full)}
              onChange={(e) => {
                if (e.target.checked) {
                  onChange([...value, full])
                } else {
                  onChange(value.filter(d => d !== full))
                }
              }}
              className="peer sr-only"
            />
            <div className={cn(
              "flex h-9 w-12 select-none items-center justify-center rounded-md border text-sm font-medium",
              "transition-all duration-200 ease-in-out",
              "border-input bg-background",
              "hover:bg-accent hover:text-accent-foreground",
              "peer-checked:border-primary peer-checked:bg-primary peer-checked:text-primary-foreground",
              "peer-focus-visible:outline-none peer-focus-visible:ring-2 peer-focus-visible:ring-ring peer-focus-visible:ring-offset-2",
              "disabled:pointer-events-none disabled:opacity-50"
            )}>
              {short}
            </div>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger className="hidden sm:block">
                  <div className="absolute left-1/2 -translate-x-1/2 -bottom-8 opacity-0 transition-opacity group-hover:opacity-100" />
                </TooltipTrigger>
                <TooltipContent>
                  <p className="text-xs">{full}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </label>
        ))}
      </div>
    </div>
  )
} 