import { useState } from "react"
import { Button } from "@/components/ui/button"
import { Separator } from "@/components/ui/separator"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip"
import { FileText, HelpCircle, MoreHorizontal, Pencil, Trash } from "lucide-react"
import { useQuery, gql, useMutation } from "@apollo/client"
import { CreatePaperwork } from "@/components/dialogs/CreatePaperwork"
import { Skeleton } from "@/components/ui/skeleton"
import { useLocation } from "wouter"
import { toast } from "@/hooks/use-toast"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog"

const GET_PAPERWORK = gql`
  query Paperwork {
    paperwork {
      id
      name
      documentUrl
      slug
      expires
      lastUpdated
      createdAt
      signed
      notSigned
    }
  }
`

const DELETE_PAPERWORK = gql`
  mutation DeletePaperwork($input: DeletePaperworkInput!) {
    deletePaperwork(input: $input) {
      success
    }
  }
`

export function SettingsPaperwork() {
  const { data: paperworkData, refetch: refetchPaperwork, loading } = useQuery(GET_PAPERWORK)
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
  const [selectedPaperworkId, setSelectedPaperworkId] = useState<string | null>(null)
  const [, navigate] = useLocation()
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [paperworkToDelete, setPaperworkToDelete] = useState<any>(null)

  const [deletePaperwork] = useMutation(DELETE_PAPERWORK, {
    onCompleted: () => {
      toast({
        title: "Success",
        description: "Document deleted successfully",
      })
      refetchPaperwork()
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      })
    },
  })

  const handleOpenEditPaperwork = (paperwork: any) => {
    navigate(`/settings/paperwork/edit/${paperwork.id}`)
  }

  const handleOpenDeletePaperworkAlert = (paperwork: any) => {
    setPaperworkToDelete(paperwork)
    setDeleteDialogOpen(true)
  }

  const handleDeletePaperwork = async () => {
    if (paperworkToDelete) {
      await deletePaperwork({
        variables: { 
          input: { 
            id: paperworkToDelete.id 
          } 
        }
      })
    }
    setDeleteDialogOpen(false)
    setPaperworkToDelete(null)
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="space-y-1">
          <h3 className="text-lg font-medium">Paperwork</h3>
          <p className="text-sm text-muted-foreground">
            Manage your organization's required documents and forms.
          </p>
        </div>
        <div className="flex justify-end">
          <CreatePaperwork refetch={refetchPaperwork} />
        </div>
      </div>
      <Separator />
      <div className="space-y-6">    
        <div className="rounded-md border">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Name</TableHead>
                <TableHead className="text-center">Signed</TableHead>
                <TableHead className="text-center">Not Signed</TableHead>
                <TableHead>
                  <div className="flex items-center gap-1">
                    Expires
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger>
                          <HelpCircle className="h-4 w-4 text-muted-foreground" />
                        </TooltipTrigger>
                        <TooltipContent>
                          When this document expires and needs to be renewed
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                </TableHead>
                <TableHead>Created</TableHead>
                <TableHead>Last Updated</TableHead>
                <TableHead className="w-[50px]"></TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {loading ? (
                Array.from({ length: 5 }).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell><Skeleton className="h-4 w-[200px]" /></TableCell>
                    <TableCell className="text-center"><Skeleton className="h-4 w-[30px] mx-auto" /></TableCell>
                    <TableCell className="text-center"><Skeleton className="h-4 w-[30px] mx-auto" /></TableCell>
                    <TableCell><Skeleton className="h-4 w-[100px]" /></TableCell>
                    <TableCell><Skeleton className="h-4 w-[100px]" /></TableCell>
                    <TableCell><Skeleton className="h-4 w-[100px]" /></TableCell>
                    <TableCell><Skeleton className="h-8 w-8" /></TableCell>
                  </TableRow>
                ))
              ) : paperworkData?.paperwork ? (
                paperworkData.paperwork.map((doc) => (
                  <TableRow key={doc.id}>
                    <TableCell>{doc.name}</TableCell>
                    <TableCell className="text-center">{doc.signed || 0}</TableCell>
                    <TableCell className="text-center">{doc.notSigned || 0}</TableCell>
                    <TableCell>{doc.expires}</TableCell>
                    <TableCell>{new Date(doc.createdAt).toLocaleDateString()}</TableCell>
                    <TableCell>{new Date(doc.lastUpdated).toLocaleDateString()}</TableCell>
                    <TableCell>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button variant="ghost" className="h-8 w-8 p-0">
                            <span className="sr-only">Open menu</span>
                            <MoreHorizontal className="h-4 w-4" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                          <DropdownMenuItem onClick={() => handleOpenEditPaperwork(doc)}>
                            <Pencil className="mr-2 h-4 w-4" />
                            Edit
                          </DropdownMenuItem>
                          <DropdownMenuItem 
                            onClick={() => handleOpenDeletePaperworkAlert(doc)}
                            className="text-destructive"
                          >
                            <Trash className="mr-2 h-4 w-4" />
                            Delete
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} className="text-center py-12">
                    <div className="space-y-2">
                      <FileText className="h-12 w-12 text-muted-foreground mx-auto" />
                      <p className="text-xl font-semibold text-muted-foreground">No paperwork found</p>
                      <p className="text-sm text-muted-foreground">
                        Add your first document by clicking the "Add Paperwork" button above.
                      </p>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
      <AlertDialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This will permanently delete the document "{paperworkToDelete?.name}". 
              This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDeletePaperwork}
              className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  )
}
