import "./App.css";
import { useAuth } from "./hooks/useAuth";
import { useEffect } from "react";
import { Router, Route, Switch, Redirect } from "wouter";
import { AuthenticatedUserLayout } from "./layouts/AuthenticatedUserLayout/AuthenticatedUser";
import AuthenticationPage from "./pages/AuthenticationPage/AuthenticationPage";
import Spinner from "./components/catalyst/spinner";
import OnboardingPage from "./pages/OnboardingPage/OnboardingPage";
import { useUser } from "./hooks/useUser";
import StudentPage from "./pages/StudentPage/StudentPage";
import { Toaster } from "@/components/ui/toaster";
import GuardianSignUp from "./pages/GuardianSignUp/GuardianSignUp";
import AuthenticatedGuardianLayout from "./layouts/AuthenticatedGuardianLayout/AuthenticatedGuardianLayout";
import GuardianStudents from "./pages/GuardianStudents/GuardianStudents";
import PasswordResetPage from "./pages/PasswordResetPage/PasswordResetPage";
import ReportsPage from "./pages/ReportsPage/ReportsPage";
import Settings from "@/pages/Settings/Settings"
import Home from "@/pages/Home/Home"
import { Loader2 } from "lucide-react"
import { Card, CardContent } from "@/components/ui/card"
import { EditPaperwork } from "@/pages/EditPaperwork/EditPaperwork"
import Reports from "@/pages/Reports/Reports"
import Billing from "@/pages/Billing/Billing"
import Students from "@/pages/Students/Students"
function App() {
  const { isAuthenticated, authLoaded } = useAuth();
  const { user } = useUser();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://do.featurebase.app/js/sdk.js';
    script.id = 'featurebase-sdk';
    document.head.appendChild(script);

    const win = window;

    if (typeof win.Featurebase !== 'function') {
      win.Featurebase = function () {
        (win.Featurebase.q = win.Featurebase.q || []).push(arguments);
      };
    }

    win.Featurebase('initialize_feedback_widget', {
      organization: 'sprout', // Replace with your organization name
      theme: 'light',
      email: user?.email, // Use user email if available
      locale: 'en', // Set language
    });

    return () => {
      const existingScript = document.getElementById('featurebase-sdk');
      if (existingScript) {
        document.head.removeChild(existingScript);
      }
    };
  }, [user]);

  const Loader = () => {
    return (
      <div className="flex min-h-svh items-center justify-center bg-background p-4">
        <Card className="w-full max-w-md">
          <CardContent className="flex flex-col items-center justify-center space-y-4 pt-6">
            <Loader2 className="h-8 w-8 animate-spin text-muted-foreground" />
            <p className="text-sm text-muted-foreground">
              Getting everything ready for you...
            </p>
          </CardContent>
        </Card>
      </div>
    );
  };

  return (
    <>
      {authLoaded ? (
        <Router>
          {isAuthenticated ? (
            !user ? (
              <Loader />
            ) : user.role === "AD" || user.role === "ST" ? (
              <AuthenticatedUserLayout>
                <Switch>
                  <Route path="/" exact>
                    <Home/>
                  </Route>
                  <Route path="/students" exact>
                    <Students />
                  </Route>
                  <Route path="/billing" exact>
                    <Billing />
                  </Route>
                  <Route path="/reports" exact>
                    <Reports />
                  </Route>
                  <Route path="/settings" exact>
                    <Redirect to="/settings/general" />
                  </Route>
                  <Route path="/settings/paperwork/edit/:id">
                    <EditPaperwork />
                  </Route>
                  <Route path="/settings/:*">
                    <Settings />
                  </Route>
                  <Route>
                    <Redirect to="/" />
                  </Route>
                </Switch>
              </AuthenticatedUserLayout>
            ) : (
              <AuthenticatedGuardianLayout>
                <Switch>
                  <Route path="/students" exact>
                    <GuardianStudents />
                  </Route>
                  <Route path="/students/:id/:tab?" exact>
                    <StudentPage />
                  </Route>
                  <Redirect to="/students" />
                </Switch>
              </AuthenticatedGuardianLayout>
            )
          ) : (
            <Switch>
              <Route path="/guardian/signup/:id" exact>
                {(params) => <GuardianSignUp id={params.id} />}
              </Route>
              <Route path="/reset-password/:token">
                {(params) => <PasswordResetPage token={params.token} />}
              </Route>
              <Route path="*">
                <AuthenticationPage />
              </Route>
            </Switch>
          )}
        </Router>
      ) : (
        <Loader />
      )}
      <Toaster />
    </>
  );
}

export default App;
