import { useState, useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import { useLocation } from "wouter";
import Card from "../../components/catalyst/card";
import { Text } from "../../components/catalyst/text";
import { Heading } from "../../components/catalyst/heading";
import { Badge } from "../../components/catalyst/badge";
import { Avatar } from "../../components/catalyst/avatar";
import { generateAvatar } from "../../utils/generateAvatar";
import { usePostHog } from 'posthog-js/react';

const GET_GUARDIAN_STUDENTS = gql`
  {
    guardianStudents {
      uuid
      firstName
      lastName
      attendanceStatus
      avatar
      enrolled
      currentProgramName
    }
  }
`;

const StudentCardSkeleton = () => {
  return (
    <Card className="mb-4 animate-pulse">
      <Card.Body>
        <div className="flex items-center gap-4">
          <div className="h-12 w-12 rounded-lg bg-gray-200" />
          <div className="flex-1">
            <div className="h-5 w-32 bg-gray-200 rounded mb-2" />
            <div className="h-4 w-24 bg-gray-200 rounded" />
          </div>
          <div className="h-6 w-24 bg-gray-200 rounded-full" />
        </div>
      </Card.Body>
    </Card>
  );
};

export default function GuardianStudents() {
  const [location, navigate] = useLocation();
  const { data, loading } = useQuery(GET_GUARDIAN_STUDENTS);
  const [students, setStudents] = useState([]);
  const posthog = usePostHog();

  useEffect(() => {
    posthog.capture('guardian_students_viewed');
  }, [posthog]);

  useEffect(() => {
    if (data?.guardianStudents) {
      setStudents(data.guardianStudents);
    }
  }, [data]);

  if (loading) {
    return (
      <div className="space-y-6 p-6">
        <Heading>My Students</Heading>
        {[...Array(3)].map((_, index) => (
          <StudentCardSkeleton key={index} />
        ))}
      </div>
    );
  }

  return (
    <div className="space-y-6 p-6">
      <Heading>My Students</Heading>
      
      {students.length > 0 ? (
        <div className="space-y-4">
          {students.map((student) => (
            <Card 
              key={student.uuid}
              className="cursor-pointer hover:bg-gray-50 transition-colors"
              onClick={() => navigate(`/students/${student.uuid}`)}
            >
              <Card.Body>
                <div className="flex items-center gap-4">
                  <Avatar
                    src={student.avatar ? student.avatar : generateAvatar(student.firstName, student.lastName)}
                    className="size-12"
                    square
                    alt={`${student.firstName} ${student.lastName}`}
                  />
                  <div className="flex-1">
                    <Text className="font-medium text-gray-900">
                      {student.firstName} {student.lastName}
                    </Text>
                    <Text className="text-sm text-gray-500">
                      {student.currentProgramName}
                    </Text>
                  </div>
                  <Badge
                    color={
                      student.attendanceStatus === "signed_in" ? "green" :
                      student.attendanceStatus === "signed_out" ? "red" :
                      "yellow"
                    }
                  >
                    {student.attendanceStatus === "signed_in" ? "Signed In" : "Signed Out"}
                  </Badge>
                </div>
              </Card.Body>
            </Card>
          ))}
        </div>
      ) : (
        <Card>
          <Card.Body>
            <div className="text-center py-12">
              <div className="mx-auto h-12 w-12 text-gray-400">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
              </div>
              <Text className="mt-2 font-medium text-gray-900">No students found</Text>
              <Text className="mt-1 text-sm text-gray-500">
                You don't have any students assigned to your account.
              </Text>
            </div>
          </Card.Body>
        </Card>
      )}
    </div>
  );
}
