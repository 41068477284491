'use client'

import React, { Fragment, forwardRef, useId } from 'react'

import * as Headless from '@headlessui/react'
import clsx from 'clsx'
import { LayoutGroup, motion } from 'framer-motion'

import { TouchTarget } from './button'
import { Link } from './link'

export function Sidebar({ className, ...props }) {
  return (
    <nav
      {...props}
      className={clsx(className, 'flex h-full min-h-0 flex-col')}
    />
  )
}

export function SidebarHeader({ className, ...props }) {
  return (
    <div
      {...props}
      className={clsx(
        className,
        'flex flex-col border-b border-zinc-950/5 p-4 dark:border-white/5 [&>[data-slot=section]+[data-slot=section]]:mt-2.5'
      )}
    />
  )
}

export function SidebarBody({ className, ...props }) {
  return (
    <div
      {...props}
      className={clsx(
        className,
        'flex flex-1 flex-col overflow-y-auto p-4 [&>[data-slot=section]+[data-slot=section]]:mt-8'
      )}
    />
  )
}

export function SidebarFooter({ className, ...props }) {
  return (
    <div
      {...props}
      className={clsx(
        className,
        'flex flex-col border-t border-zinc-950/5 p-4 dark:border-white/5 [&>[data-slot=section]+[data-slot=section]]:mt-2.5'
      )}
    />
  )
}

export function SidebarSection({ className, ...props }) {
  let id = useId()

  return (
    <LayoutGroup id={id}>
      <div
        {...props}
        data-slot="section"
        className={clsx(className, 'flex flex-col gap-1')}
      />
    </LayoutGroup>
  )
}

export function SidebarDivider({ className, ...props }) {
  return (
    <hr
      {...props}
      className={clsx(
        className,
        'my-4 border-t border-zinc-950/5 lg:-mx-4 dark:border-white/5'
      )}
    />
  )
}

export function SidebarSpacer({ className, ...props }) {
  return (
    <div
      aria-hidden="true"
      {...props}
      className={clsx(className, 'mt-8 flex-1')}
    />
  )
}

export function SidebarHeading({ className, ...props }) {
  return (
    <h3
      {...props}
      className={clsx(
        className,
        'mb-1 px-2 text-xs/6 font-medium text-zinc-500 dark:text-zinc-400'
      )}
    />
  )
}

export const SidebarItem = forwardRef(function SidebarItem(
  { current, className, children, ...props },
  ref
) {
  let classes = clsx(
    // Base
    'flex items-center justify-center rounded-lg text-base/6 font-medium text-zinc-950 sm:text-sm/5',
    // Icon
    'data-[slot=icon]:*:size-6 data-[slot=icon]:*:shrink-0 data-[slot=icon]:*:fill-zinc-500 sm:data-[slot=icon]:*:size-5',
    // Hover
    'hover:bg-zinc-950/5 data-[slot=icon]:*:hover:fill-sprout-600',
    // Current (now using bg color instead of indicator)
    current && 'bg-zinc-950/5 data-[slot=icon]:*:fill-sprout-600',
    // Dark mode
    'dark:text-white dark:data-[slot=icon]:*:fill-zinc-400',
    'dark:hover:bg-white/5 dark:data-[slot=icon]:*:hover:fill-white',
    current && 'dark:bg-white/5 dark:data-[slot=icon]:*:fill-white',
    // Animation
    'transition-transform duration-200 ease-in-out hover:scale-105',
    // Ensure square aspect ratio
    'aspect-square w-8 h-8' // Adjust width and height to ensure square shape
  )

  return (
    <span className={clsx(className, 'relative')}>
      {'href' in props ? (
        <Headless.CloseButton as={Fragment} ref={ref}>
          <Link
            className={classes}
            {...props}
            data-current={current ? 'true' : undefined}
          >
            <TouchTarget>{children}</TouchTarget>
          </Link>
        </Headless.CloseButton>
      ) : (
        <Headless.Button
          {...props}
          className={clsx('cursor-default', classes)}
          data-current={current ? 'true' : undefined}
          ref={ref}
        >
          <TouchTarget>{children}</TouchTarget>
        </Headless.Button>
      )}
    </span>
  )
})

export const SidebarItemWithLabel = forwardRef(function SidebarItemWithLabel(
  { current, className, children, external, ...props },
  ref
) {
  let classes = clsx(
    // Base
    'flex items-center gap-2 rounded-lg font-medium text-zinc-950',
    // Text sizing
    'text-sm/5',
    // Icon sizing
    'data-[slot=icon]:*:size-5 data-[slot=icon]:*:shrink-0 data-[slot=icon]:*:fill-zinc-500',
    // Hover
    'hover:bg-zinc-950/5 data-[slot=icon]:*:hover:fill-sprout-600',
    // Current
    current && 'bg-zinc-950/5 data-[slot=icon]:*:fill-sprout-600',
    // Dark mode
    'dark:text-white dark:data-[slot=icon]:*:fill-zinc-400',
    'dark:hover:bg-white/5 dark:data-[slot=icon]:*:hover:fill-white',
    current && 'dark:bg-white/5 dark:data-[slot=icon]:*:fill-white',
    // Animation
    'transition-transform duration-200 ease-in-out hover:scale-105',
    // Padding
    'px-3 py-2'
  )

  return (
    <span className={clsx(className, 'relative')}>
      {'href' in props ? (
        external ? (
          <a
            {...props}
            className={classes}
            data-current={current ? 'true' : undefined}
            ref={ref}
            target="_blank"
            rel="noopener noreferrer"
          >
            <TouchTarget>{children}</TouchTarget>
          </a>
        ) : (
          <Headless.CloseButton as={Fragment} ref={ref}>
            <Link
              className={classes}
              {...props}
              data-current={current ? 'true' : undefined}
            >
              <TouchTarget>{children}</TouchTarget>
            </Link>
          </Headless.CloseButton>
        )
      ) : (
        <Headless.Button
          {...props}
          className={clsx('cursor-default', classes)}
          data-current={current ? 'true' : undefined}
          ref={ref}
        >
          <TouchTarget>{children}</TouchTarget>
        </Headless.Button>
      )}
    </span>
  )
})

export function SidebarLabel({ className, ...props }) {
  return <span {...props} className={clsx(className, 'truncate')} />
}
