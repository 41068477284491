import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { useState } from "react"
import { Plus, HelpCircle } from "lucide-react"
import { useMutation, gql, useQuery } from "@apollo/client"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"
import { toast } from "@/hooks/use-toast"
import { cn } from "@/lib/utils"
import { DayPicker } from "@/components/DayPicker"

const CREATE_PROGRAM = gql`
  mutation CreateProgram($input: CreateProgramInput!) {
    createProgram(input: $input) {
      program {
        id
      }
    }
  }
`

const PAPERWORK_QUERY = gql`
  query PaperworkQuery {
    paperwork {
      id
      name
    }
  }
`

interface CreateProgramDialogProps {
  refetch: () => void
}

export function CreateProgramDialog({ refetch }: CreateProgramDialogProps) {
  const [open, setOpen] = useState(false)
  const [name, setName] = useState("")
  const [rate, setRate] = useState("")
  const [rateFrequency, setRateFrequency] = useState("")
  const [daysOfWeek, setDaysOfWeek] = useState<string[]>([])
  const [maxEnrollments, setMaxEnrollments] = useState("")
  const [requiredPaperwork, setRequiredPaperwork] = useState<string[]>([])
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [showErrors, setShowErrors] = useState(false)

  const [createProgram, { loading }] = useMutation(CREATE_PROGRAM, {
    onCompleted: () => {
      setOpen(false)
      refetch()
      toast({
        title: "Success",
        description: "Program created successfully",
      })
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      })
    },
  })

  const { data: paperworkData } = useQuery(PAPERWORK_QUERY)

  const validateForm = () => {
    const newErrors: Record<string, string> = {}
    if (!name) newErrors.name = "Name is required"
    if (!rate || Number(rate) <= 0) newErrors.rate = "Valid rate is required"
    if (!rateFrequency) newErrors.rateFrequency = "Billing frequency is required"
    return newErrors
  }

  const handleCreateProgram = (e: React.FormEvent) => {
    e.preventDefault()
    
    const newErrors = validateForm()
    setErrors(newErrors)
    setShowErrors(true)

    if (Object.keys(newErrors).length === 0) {
      createProgram({ 
        variables: { 
          input: { 
            name, 
            defaultRate: Number(rate), 
            defaultRateFrequency: rateFrequency,
            daysOfWeek,
            maxEnrollments: Number(maxEnrollments),
            requiredPaperwork
          } 
        } 
      })
    }
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>
          <Plus className="mr-2 h-4 w-4" />
          Add Program
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[600px]">
        <form onSubmit={handleCreateProgram}>
          <DialogHeader>
            <DialogTitle>Create Program</DialogTitle>
            <DialogDescription>
              Create a new program for your organization.
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="name">Name</Label>
                <Input
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className={cn(showErrors && errors.name && "border-destructive")}
                />
                {showErrors && errors.name && (
                  <p className="text-sm text-destructive">{errors.name}</p>
                )}
              </div>
              <div className="space-y-2">
                <Label htmlFor="maxEnrollments">Capacity</Label>
                <Input
                  id="maxEnrollments"
                  type="number"
                  value={maxEnrollments}
                  onChange={(e) => setMaxEnrollments(e.target.value)}
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="rate">Default Rate ($)</Label>
                <Input
                  id="rate"
                  type="number"
                  value={rate}
                  onChange={(e) => setRate(e.target.value)}
                  className={cn(showErrors && errors.rate && "border-destructive")}
                />
                {showErrors && errors.rate && (
                  <p className="text-sm text-destructive">{errors.rate}</p>
                )}
              </div>
              <div className="space-y-2">
                <Label htmlFor="rateFrequency">Default Billing Frequency</Label>
                <Select
                  value={rateFrequency}
                  onValueChange={setRateFrequency}
                >
                  <SelectTrigger className={cn(showErrors && errors.rateFrequency && "border-destructive")}>
                    <SelectValue placeholder="Select Frequency" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="Drop-In">Drop-In (Charged on sign-in)</SelectItem>
                    <SelectItem value="Daily">Daily</SelectItem>
                    <SelectItem value="Weekly">Weekly</SelectItem>
                    <SelectItem value="Monthly">Monthly</SelectItem>
                    <SelectItem value="Yearly">Yearly</SelectItem>
                  </SelectContent>
                </Select>
                {showErrors && errors.rateFrequency && (
                  <p className="text-sm text-destructive">{errors.rateFrequency}</p>
                )}
              </div>
            </div>

            <DayPicker 
              value={daysOfWeek}
              onChange={setDaysOfWeek}
            />

            <div className="space-y-2">
              <Label>Required Paperwork</Label>
              <p className="text-sm text-muted-foreground">
                Hold down the Ctrl (⌘) key to select multiple items.
              </p>
              <select
                multiple
                className="w-full rounded-md border border-input bg-background px-3 py-2"
                value={requiredPaperwork}
                onChange={(e) => {
                  const values = Array.from(e.target.selectedOptions, option => option.value)
                  setRequiredPaperwork(values)
                }}
              >
                {paperworkData?.paperwork?.map((p: any) => (
                  <option key={p.id} value={p.id}>{p.name}</option>
                ))}
              </select>
            </div>
          </div>
          <DialogFooter>
            <Button type="button" variant="outline" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button type="submit" disabled={loading}>
              Create Program
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
} 