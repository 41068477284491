import { useState, useEffect } from "react"
import { useLocation, useRoute } from "wouter"
import { gql, useMutation, useQuery } from "@apollo/client"
import { toast } from "@/hooks/use-toast"
import { DocusealBuilder } from "@docuseal/react"
import { usePostHog } from "posthog-js/react"
import { API_BASE_URL } from "@/config"

import { Button } from "@/components/ui/button"
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { ArrowLeft, CalendarDays } from "lucide-react"
import { Skeleton } from "@/components/ui/skeleton"

const GET_PAPERWORK = gql`
  query GetPaperwork($id: ID!) {
    paperworkById(id: $id) {
      id
      name
      documentUrl
      templateId
      expires
      createdAt
    }
  }
`

const UPDATE_PAPERWORK = gql`
  mutation UpdatePaperwork($input: UpdatePaperworkInput!) {
    updatePaperwork(input: $input) {
      success
    }
  }
`

const UPDATE_PAPERWORK_FREQUENCY = gql`
  mutation UpdatePaperworkExpires($input: UpdatePaperworkExpiresInput!) {
    updatePaperworkExpires(input: $input) {
      success
    }
  }
`

export function EditPaperwork() {
  const [, navigate] = useLocation()
  const [, params] = useRoute("/settings/paperwork/edit/:id")
  const [paperwork, setPaperwork] = useState<any>(null)
  const [jwt, setJWT] = useState<string | null>(null)
  const [isExpiresAlertOpen, setIsExpiresAlertOpen] = useState(false)
  const [updatePaperwork] = useMutation(UPDATE_PAPERWORK)
  const [updateExpires] = useMutation(UPDATE_PAPERWORK_FREQUENCY)
  const posthog = usePostHog()
  const { data: paperworkData, loading: queryLoading, refetch } = useQuery(
    GET_PAPERWORK,
    {
      variables: { id: params?.id },
      skip: !params?.id,
    }
  )
  const [tempExpires, setTempExpires] = useState<string>(paperworkData?.paperworkById?.expires || "NEVER")


  useEffect(() => {
    posthog.capture("edit_paperwork_viewed", {
      paperworkId: params?.id,
    })
  }, [posthog, params?.id])

  useEffect(() => {
    if (paperworkData?.paperworkById) {
      setPaperwork(paperworkData.paperworkById)
      setTempExpires(paperworkData.paperworkById.expires || "NEVER")
    }
  }, [paperworkData])

  useEffect(() => {
    getJWT()
  }, [])

  const getJWT = async () => {
    const accessToken = localStorage.getItem("accessToken")

    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/documents/get_jwt`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ id: params?.id }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })

      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`)
      }

      const data = await response.json()
      setJWT(data.jwt)
    } catch (error) {
      console.error("Error getting JWT:", error)
      toast({
        title: "Error",
        description: "Failed to load document editor",
        variant: "destructive",
      })
    }
  }

  const handleUpdate = async (data: any) => {
    try {
      await updatePaperwork({
        variables: {
          input: {
            id: params?.id,
            templateId: String(data.id),
            slug: data.slug,
            name: data.name,
          },
        },
      })
      toast({
        title: "Success",
        description: "Changes saved successfully",
      })
      refetch()
    } catch (error: any) {
      toast({
        title: "Error",
        description: `Failed to update document: ${error.message}`,
        variant: "destructive",
      })
    }
  }

  const handleSaveExpires = async () => {
    try {
      await updateExpires({
        variables: {
          input: {
            id: params?.id,
            expires: tempExpires,
          },
        },
      })
      toast({
        title: "Success",
        description: "Expiration settings updated",
      })
      setIsExpiresAlertOpen(false)
      refetch()
    } catch (error: any) {
      toast({
        title: "Error",
        description: `Failed to update expiration: ${error.message}`,
        variant: "destructive",
      })
    }
  }

  if (queryLoading) {
    return (
      <div className="space-y-6 p-6">
        <Button
          variant="outline"
          onClick={() => navigate("/settings/paperwork")}
          className="mb-6"
        >
          <ArrowLeft className="h-4 w-4 mr-2" />
          Settings
        </Button>

        <Card>
          <CardHeader>
            <Skeleton className="h-4 w-48" />
          </CardHeader>
          <CardContent className="space-y-4">
            <Skeleton className="h-10 w-full" />
            <Skeleton className="h-[500px] w-full" />
          </CardContent>
        </Card>
      </div>
    )
  }

  return (
    <div className="space-y-6 p-6">
      <Button
        variant="outline"
        onClick={() => navigate("/settings/paperwork")}
        className="mb-6"
      >
        <ArrowLeft className="h-4 w-4 mr-2" />
        Settings
      </Button>

      <Card>
        <CardHeader>
          <div className="flex items-center justify-between">
            <CardTitle>Make changes to {paperwork?.name}</CardTitle>
            <Button
              variant="outline"
              size="icon"
              onClick={() => setIsExpiresAlertOpen(true)}
            >
              <CalendarDays className="h-4 w-4" />
            </Button>
          </div>
        </CardHeader>
        <CardContent>
          {jwt ? (
            <DocusealBuilder
              onChange={(data) => handleUpdate(data)}
              token={jwt}
              withDownloadButton={false}
              withSendButton={false}
              withSignYourselfButton={false}
              withUploadButton={true}
              withAddPageButton={false}
              withRecipientsButton={false}
              customCss={`
                .docuseal-builder {
                  background: transparent;
                  font-family: var(--font-sans);
                }
                .docuseal-builder__content {
                  height: 100%;
                  max-height: none;
                }
                .docuseal-builder__preview {
                  height: 100%;
                }
                #add_document_button {
                  border-radius: 0.5rem;
                  font-weight: 500;
                  transition: all 150ms;
                  font-size: 0.875rem;
                  line-height: 1.25rem;
                  padding: 0.5rem 0.75rem;
                }
              `}
            />
          ) : (
            <div className="flex items-center justify-center h-[500px]">
              <div className="flex flex-col items-center gap-2">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
                <p className="text-sm text-muted-foreground">Loading editor...</p>
              </div>
            </div>
          )}
        </CardContent>
      </Card>

      <AlertDialog open={isExpiresAlertOpen} onOpenChange={setIsExpiresAlertOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Paperwork Expires</AlertDialogTitle>
            <AlertDialogDescription>
              Choose when this paperwork expires and needs to be renewed.
            </AlertDialogDescription>
          </AlertDialogHeader>

          <Select value={tempExpires} onValueChange={setTempExpires}>
            <SelectTrigger>
              <SelectValue placeholder="Select expiration frequency" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="Never">Never</SelectItem>
              <SelectItem value="Weekly">Weekly</SelectItem>
              <SelectItem value="Monthly">Monthly</SelectItem>
              <SelectItem value="Quarterly">Quarterly</SelectItem>
              <SelectItem value="Yearly">Yearly</SelectItem>
            </SelectContent>
          </Select>

          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleSaveExpires}>
              Save Changes
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  )
} 