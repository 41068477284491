import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { useState, useEffect } from "react"
import { HelpCircle } from "lucide-react"
import { useMutation, gql, useQuery } from "@apollo/client"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"
import { toast } from "@/hooks/use-toast"
import { cn } from "@/lib/utils"
import { DayPicker } from "@/components/DayPicker"

const EDIT_PROGRAM = gql`
  mutation UpdateProgram($input: UpdateProgramInput!) {
    updateProgram(input: $input) {
      program {
        id
      }
    }
  }
`

const GET_PAPERWORK = gql`
  query GetPaperwork {
    paperwork {
      id
      name
    }
  }
`

interface Program {
  id: string
  name: string
  defaultRate: number
  defaultRateFrequency: string
  daysOfWeek: string
  maxEnrollments: number
  requiredPaperwork: { id: string }[]
}

interface EditProgramDialogProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  program: Program | null
  refetch: () => void
}

export function EditProgramDialog({ 
  open, 
  onOpenChange, 
  program, 
  refetch 
}: EditProgramDialogProps) {
  const [name, setName] = useState("")
  const [rate, setRate] = useState("")
  const [rateFrequency, setRateFrequency] = useState("")
  const [daysOfWeek, setDaysOfWeek] = useState<string[]>([])
  const [maxEnrollments, setMaxEnrollments] = useState("")
  const [requiredPaperwork, setRequiredPaperwork] = useState<string[]>([])
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [showErrors, setShowErrors] = useState(false)

  const [editProgram, { loading }] = useMutation(EDIT_PROGRAM, {
    onCompleted: () => {
      onOpenChange(false)
      refetch()
      toast({
        title: "Success",
        description: "Program updated successfully",
      })
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      })
    },
  })

  const { data: paperworkData } = useQuery(GET_PAPERWORK)

  useEffect(() => {
    if (program) {
      setName(program.name || "")
      setRate(program.defaultRate?.toString() || "")
      setRateFrequency(program.defaultRateFrequency || "")
      setDaysOfWeek(program.daysOfWeek ? JSON.parse(program.daysOfWeek) : [])
      setMaxEnrollments(program.maxEnrollments?.toString() || "")
      setRequiredPaperwork(program.requiredPaperwork?.map(p => p.id) || [])
    }
  }, [program])

  const validateForm = () => {
    const newErrors: Record<string, string> = {}
    if (!name) newErrors.name = "Name is required"
    if (!rate || Number(rate) <= 0) newErrors.rate = "Valid rate is required"
    if (!rateFrequency) newErrors.rateFrequency = "Billing frequency is required"
    return newErrors
  }

  const handleEditProgram = (e: React.FormEvent) => {
    e.preventDefault()
    
    const newErrors = validateForm()
    setErrors(newErrors)
    setShowErrors(true)

    if (Object.keys(newErrors).length === 0 && program) {
      editProgram({ 
        variables: { 
          input: { 
            id: program.id,
            name, 
            defaultRate: Number(rate), 
            defaultRateFrequency: rateFrequency,
            daysOfWeek,
            maxEnrollments: Number(maxEnrollments),
            requiredPaperwork
          } 
        } 
      })
    }
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px]">
        <form onSubmit={handleEditProgram}>
          <DialogHeader>
            <DialogTitle>Edit Program</DialogTitle>
            <DialogDescription>
              Make changes to the program details.
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="name">Name</Label>
                <Input
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className={cn(showErrors && errors.name && "border-destructive")}
                />
                {showErrors && errors.name && (
                  <p className="text-sm text-destructive">{errors.name}</p>
                )}
              </div>
              <div className="space-y-2">
                <Label htmlFor="maxEnrollments">Capacity</Label>
                <Input
                  id="maxEnrollments"
                  type="number"
                  value={maxEnrollments}
                  onChange={(e) => setMaxEnrollments(e.target.value)}
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="rate">Default Rate ($)</Label>
                <Input
                  id="rate"
                  type="number"
                  value={rate}
                  onChange={(e) => setRate(e.target.value)}
                  className={cn(showErrors && errors.rate && "border-destructive")}
                />
                {showErrors && errors.rate && (
                  <p className="text-sm text-destructive">{errors.rate}</p>
                )}
              </div>
              <div className="space-y-2">
                <Label htmlFor="rateFrequency">Default Billing Frequency</Label>
                <Select
                  value={rateFrequency}
                  onValueChange={setRateFrequency}
                >
                  <SelectTrigger className={cn(showErrors && errors.rateFrequency && "border-destructive")}>
                    <SelectValue placeholder="Select Frequency" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="Drop-In">Drop-In (Charged on sign-in)</SelectItem>
                    <SelectItem value="Daily">Daily</SelectItem>
                    <SelectItem value="Weekly">Weekly</SelectItem>
                    <SelectItem value="Monthly">Monthly</SelectItem>
                    <SelectItem value="Yearly">Yearly</SelectItem>
                  </SelectContent>
                </Select>
                {showErrors && errors.rateFrequency && (
                  <p className="text-sm text-destructive">{errors.rateFrequency}</p>
                )}
              </div>
            </div>

            <div className="space-y-2">
              <DayPicker 
                value={daysOfWeek}
                onChange={setDaysOfWeek}
              />
            </div>

            <div className="space-y-2">
              <Label>Required Paperwork</Label>
              <p className="text-sm text-muted-foreground">
                Hold down the Ctrl (⌘) key to select multiple items.
              </p>
              <select
                multiple
                className="w-full rounded-md border border-input bg-background px-3 py-2"
                value={requiredPaperwork}
                onChange={(e) => {
                  const values = Array.from(e.target.selectedOptions, option => option.value)
                  setRequiredPaperwork(values)
                }}
              >
                {paperworkData?.paperwork?.map((p: any) => (
                  <option key={p.id} value={p.id}>{p.name}</option>
                ))}
              </select>
            </div>
          </div>
          <DialogFooter>
            <Button type="button" variant="outline" onClick={() => onOpenChange(false)}>
              Cancel
            </Button>
            <Button type="submit" disabled={loading}>
              Save Changes
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
} 