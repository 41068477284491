import { Alert, AlertActions, AlertDescription, AlertTitle } from '../../components/catalyst/alert'
import { Button } from '../../components/catalyst/button'
import { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { DropdownItem } from '../../components/catalyst/dropdown'

const VOID_INVOICE = gql`
  mutation VoidInvoice($input: VoidInvoiceInput!) {
    voidInvoice(input: $input) {
      success
    }
  }
`

export default function VoidInvoice({ invoice, refetch, isOpen, setIsOpen, ...props }) {
  const [voidInvoice] = useMutation(VOID_INVOICE)

  const handleVoidInvoice = async () => {
    const { data } = await voidInvoice({ 
      variables: { 
        input: { 
          id: invoice.uuid 
        } 
      } 
    })
    
    if (data?.voidInvoice?.success) {
      setIsOpen(false)
      refetch()
    }
  }

  return (
    <>
      <Alert open={isOpen} onClose={setIsOpen}>
        <AlertTitle>Are you sure you want to void invoice {invoice.number}?</AlertTitle>
        <AlertDescription>
          This action cannot be undone.
        </AlertDescription>
        <AlertActions>
          <Button plain onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button color="red" onClick={handleVoidInvoice}>Void Invoices</Button>
        </AlertActions>
      </Alert>
    </>
  )
}