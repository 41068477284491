import * as React from "react"
import { MessageCircle, type LucideIcon } from "lucide-react"
import { Link } from "wouter"
import {
  SidebarGroup,
  SidebarGroupContent,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@/components/ui/sidebar"
import { Send, LifeBuoy } from "lucide-react"

export function NavSecondary({...props}) {

  const openFeedbackWidget = () => {
    window.postMessage({
      target: 'FeaturebaseWidget',
      data: { 
        action: 'openFeedbackWidget',
      }
  })
  }

  const openHelpCenter = () => {
    window.open('https://support.sproutcare.io/help', '_blank');
  }

  return (
    <SidebarGroup {...props}>
      <SidebarGroupContent>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton onClick={openFeedbackWidget}>
              <Send />
              <span>Feedback</span>
            </SidebarMenuButton>
          </SidebarMenuItem>
          <SidebarMenuItem>
            <SidebarMenuButton onClick={openHelpCenter}>
              <LifeBuoy />
              <span>Help Center</span>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarGroupContent>
    </SidebarGroup>
  )
}
