import { useState, useEffect } from "react"
import { useLocation } from "wouter"
import { gql, useMutation, useLazyQuery } from "@apollo/client"
import { format, parse } from "date-fns"
import { Calendar as CalendarIcon, Plus } from "lucide-react"
import { useToast } from "@/hooks/use-toast"
import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { Calendar } from "@/components/ui/calendar"
import { cn } from "@/lib/utils"
import GoogleSearch from "@/components/address-input"

const CREATE_STUDENT = gql`
  mutation CreateStudent(
    $firstName: String!
    $lastName: String!
    $dateOfBirth: Date!
    $gender: String!
    $address: JSONString!
    $family: ID!
  ) {
    createStudent(
      input: {
        firstName: $firstName
        lastName: $lastName
        dateOfBirth: $dateOfBirth
        gender: $gender
        address: $address
        family: $family
      }
    ) {
      student {
        uuid
      }
    }
  }
`

const SEARCH_FAMILIES = gql`
  query SearchFamilies($name: String!) {
    searchFamilies(name: $name) {
      uuid
      name
      studentSet {
        edges {
          node {
            firstName
            lastName
          }
        }
      }
    }
  }
`

interface CreateStudentDialogProps {
  refetch?: () => void
}

export function CreateStudentDialog({ refetch }: CreateStudentDialogProps) {
  const [open, setOpen] = useState(false)
  const [location, navigate] = useLocation()
  const { toast } = useToast()
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [dateOfBirth, setDateOfBirth] = useState<Date | undefined>()
  const [dateInput, setDateInput] = useState("")
  const [gender, setGender] = useState("")
  const [address, setAddress] = useState<any>(null)
  const [family, setFamily] = useState("")

  const [searchFamilies, { data: familiesData }] = useLazyQuery(SEARCH_FAMILIES)
  const [recommendedFamilies, setRecommendedFamilies] = useState([])

  const [createStudent, { loading }] = useMutation(CREATE_STUDENT, {
    onCompleted: (data) => {
      if (refetch) refetch()
      handleClose()
      toast({
        title: "Success",
        description: "Student created successfully",
      })
      navigate(`/students/${data.createStudent.student.uuid}`)
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      })
    },
  })

  useEffect(() => {
    if (lastName && lastName.length > 2) {
      searchFamilies({ variables: { name: lastName } })
    }
  }, [lastName, searchFamilies])

  useEffect(() => {
    if (familiesData?.searchFamilies) {
      setRecommendedFamilies(familiesData.searchFamilies)
    }
  }, [familiesData])

  const handleClose = () => {
    setOpen(false)
    setFirstName("")
    setLastName("")
    setDateOfBirth(undefined)
    setDateInput("")
    setGender("")
    setAddress(null)
    setFamily("")
  }

  const formatDateInput = (input: string) => {
    // Remove all non-digits
    const cleaned = input.replace(/\D/g, "")
    
    // Add separators as user types
    let formatted = ""
    if (cleaned.length > 0) {
      // Handle month
      formatted += cleaned.substring(0, 2)
      if (cleaned.length > 2) {
        formatted += "-" + cleaned.substring(2, 4)
        if (cleaned.length > 4) {
          formatted += "-" + cleaned.substring(4, 8)
        }
      }
    }
    return formatted
  }

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const formatted = formatDateInput(value)
    setDateInput(formatted)
    
    // Only try to parse if we have a complete date
    if (formatted.length === 10) {
      const parsedDate = parse(formatted, "MM-dd-yyyy", new Date())
      if (!isNaN(parsedDate.getTime())) {
        setDateOfBirth(parsedDate)
      } else {
        setDateOfBirth(undefined)
      }
    } else {
      setDateOfBirth(undefined)
    }
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!firstName || !lastName || !dateOfBirth || !gender || !address) {
      toast({
        title: "Error",
        description: "Please fill in all required fields",
        variant: "destructive",
      })
      return
    }

    const formattedAddress =
      typeof address === "string" ? address : JSON.stringify(address)

    try {
      await createStudent({
        variables: {
          firstName,
          lastName,
          // Format as YYYY-MM-DD for the server
          dateOfBirth: format(dateOfBirth, "yyyy-MM-dd"),
          gender,
          address: formattedAddress,
          family: family || "",
        },
      })
    } catch (error) {
      // Error is handled by onError callback
    }
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>
          <Plus className="mr-2 h-4 w-4" />
          Create Student
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[525px]">
        <DialogHeader>
          <DialogTitle>Create student</DialogTitle>
          <DialogDescription>
            Create a new student to begin the enrollment process.
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label htmlFor="firstName">First name</Label>
              <Input
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="Jon"
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="lastName">Last name</Label>
              <Input
                id="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Doe"
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label>Date of birth</Label>
              <div className="relative">
                <Input
                  value={dateInput}
                  onChange={handleDateChange}
                  placeholder="MM-DD-YYYY"
                  maxLength={10}
                  className={cn(
                    "pl-3 pr-10",
                    dateInput && dateInput.length === 10 && !dateOfBirth && "border-red-500"
                  )}
                />
                <CalendarIcon className="absolute right-3 top-1/2 h-4 w-4 -translate-y-1/2 text-gray-500 pointer-events-none" />
              </div>
              <p className="text-sm text-muted-foreground">
                Enter date in MM-DD-YYYY format
              </p>
              {dateInput && dateInput.length === 10 && !dateOfBirth && (
                <p className="text-sm text-red-500">
                  Please enter a valid date
                </p>
              )}
            </div>

            <div className="space-y-2">
              <Label>Gender</Label>
              <Select value={gender} onValueChange={setGender}>
                <SelectTrigger>
                  <SelectValue placeholder="Select gender" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="Male">Male</SelectItem>
                  <SelectItem value="Female">Female</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>

          <div className="space-y-2">
            <Label>Address</Label>
            <GoogleSearch name="address" onChange={setAddress} />
          </div>

          {recommendedFamilies.length > 0 && (
            <div className="space-y-2">
              <Label>Recommended Families</Label>
              <Select value={family} onValueChange={setFamily}>
                <SelectTrigger>
                  <SelectValue placeholder="Select a family" />
                </SelectTrigger>
                <SelectContent>
                  {recommendedFamilies.map((family: any) => (
                    <SelectItem key={family.uuid} value={family.uuid}>
                      {family.name} (
                      {family.studentSet.edges
                        .map(
                          (edge: any) =>
                            `${edge.node.firstName} ${edge.node.lastName}`
                        )
                        .join(", ")}
                      )
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          )}

          <DialogFooter>
            <Button type="button" variant="outline" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit" disabled={loading}>
              {loading ? "Creating..." : "Create"}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}
