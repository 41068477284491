import { useState } from 'react'
import { Input } from '../../components/catalyst/input'
import { Button } from '../../components/catalyst/button'
import { Field, FieldGroup, Fieldset, Label } from '../../components/catalyst/fieldset'
import { Text } from '../../components/catalyst/text'
import { Heading } from '../../components/catalyst/heading'
import logoIcon from '../../assets/logo-icon.svg'
import { XCircleIcon } from '@heroicons/react/24/outline'
import { API_BASE_URL } from '../../config'
import { useLocation } from 'wouter'
import { navigate } from 'wouter/use-browser-location'

function PasswordResetPage({ token }) {
  const [, setLocation] = useLocation()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [passwordError, setPasswordError] = useState('')
  const [confirmPasswordError, setConfirmPasswordError] = useState('')

  const validatePassword = (pass) => {
    if (pass.length < 8) return 'Password must be at least 8 characters long'
    if (!/[A-Z]/.test(pass)) return 'Password must contain at least one capital letter'
    if (!/[0-9]/.test(pass)) return 'Password must contain at least one number'
    if (!/[!@#$%^&*]/.test(pass)) return 'Password must contain at least one symbol (!@#$%^&*)'
    return ''
  }

  const validatePasswords = (pass, confirmPass) => {
    const passError = validatePassword(pass)
    if (passError) return { passwordError: passError }
    if (pass !== confirmPass) {
      return { confirmPasswordError: 'Passwords do not match' }
    }
    return {}
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError(null)
    setSuccess(null)
    setPasswordError('')
    setConfirmPasswordError('')

    const { passwordError, confirmPasswordError } = validatePasswords(password, confirmPassword)
    if (passwordError || confirmPasswordError) {
      setPasswordError(passwordError)
      setConfirmPasswordError(confirmPasswordError)
      return
    }

    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/password/reset/${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          password 
        }),
      })
      
      if (response.ok) {
        setSuccess('Your password has been successfully reset.')
        setTimeout(() => {
          navigate('/login')
        }, 2000)
      } else {
        const data = await response.json()
        setError(data.message || 'Failed to reset password')
      }
    } catch (err) {
      console.error('Password reset error:', err)
      setError('An unexpected error occurred')
    }
  }

  return (
    <div className="flex min-h-screen bg-gradient-to-br from-green-50 to-gray-100 dark:from-zinc-900 dark:to-zinc-800">
      <div className="m-auto w-full max-w-lg p-8">
        <div className="transform transition-all duration-200 ease-in-out">
          <div className="mb-8 text-center">
            <img src={logoIcon} alt="Sprout Logo" className="mx-auto h-12 w-auto" />
            <Heading level={1} className="mt-6 text-4xl font-bold tracking-tight">
              Set new password
            </Heading>
            <Text className="mt-2 text-lg text-zinc-600 dark:text-zinc-400">
              Please enter your new password
            </Text>
          </div>

          <div className="rounded-xl bg-white/80 backdrop-blur-sm dark:bg-zinc-900/80 p-8 shadow-xl ring-1 ring-zinc-950/5 dark:ring-white/10">
            <form onSubmit={handleSubmit} className="space-y-6">
              {error && (
                <div className="animate-fadeIn rounded-lg bg-red-50 dark:bg-red-900/30 p-4 mb-6">
                  <div className="flex items-center">
                    <XCircleIcon className="h-5 w-5 text-red-400" />
                    <p className="ml-3 text-sm text-red-800 dark:text-red-200">{error}</p>
                  </div>
                </div>
              )}
              
              {success && (
                <div className="animate-fadeIn rounded-lg bg-green-50 dark:bg-green-900/30 p-4 mb-6">
                  <p className="text-sm text-green-800 dark:text-green-200">{success}</p>
                </div>
              )}

              <Fieldset>
                <FieldGroup>
                  <Field>
                    <Label>New Password</Label>
                    <Input
                      type="password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value)
                        const { passwordError, confirmPasswordError } = validatePasswords(
                          e.target.value,
                          confirmPassword
                        )
                        setPasswordError(passwordError || '')
                        setConfirmPasswordError(confirmPasswordError || '')
                      }}
                      required
                      className="w-full"
                      placeholder="••••••••"
                    />
                    {passwordError && (
                      <Text className="mt-2 text-sm text-red-500">
                        {passwordError}
                      </Text>
                    )}
                    <Text className="mt-2 text-xs text-zinc-500">
                      Password must be at least 8 characters long, contain a capital letter, a number, and a symbol
                    </Text>
                  </Field>
                  
                  <Field>
                    <Label>Confirm Password</Label>
                    <Input
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => {
                        setConfirmPassword(e.target.value)
                        const { confirmPasswordError } = validatePasswords(
                          password,
                          e.target.value
                        )
                        setConfirmPasswordError(confirmPasswordError || '')
                      }}
                      required
                      className="w-full"
                      placeholder="••••••••"
                    />
                    {confirmPasswordError && (
                      <Text className="mt-2 text-sm text-red-500">
                        {confirmPasswordError}
                      </Text>
                    )}
                  </Field>
                </FieldGroup>
              </Fieldset>

              <Button 
                type="submit" 
                color="sprout" 
                className="w-full py-2.5 text-base font-medium transition-all duration-200 hover:opacity-90"
              >
                Reset Password
              </Button>
            </form>
          </div>

          <div className="mt-6 text-center">
            <Button
              plain
              onClick={() => navigate('/')}
              className="text-sm text-zinc-600 hover:text-zinc-900 dark:text-zinc-400 dark:hover:text-white transition-colors"
            >
              Back to sign in
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PasswordResetPage
