import React, { useState, useEffect, useRef, useCallback } from "react";
import { useQuery, gql } from "@apollo/client";
import Card from "../catalyst/card";
import { Text } from "../catalyst/text";
import { 
  PlusIcon,
  PhotoIcon,
  ChatBubbleLeftRightIcon,
} from "@heroicons/react/24/outline";
import ModifyActivity from "../../modals/ModifyActivity/ModifyActivity";
import CreateActivity from "../../modals/CreateActivity/CreateActivity";
import { useUser } from "../../hooks/useUser";
import {
  Pagination,
  PaginationList,
  PaginationNext,
  PaginationPage,
  PaginationPrevious,
} from "../catalyst/pagination";
import { Button } from "../catalyst/button";

const ACTIVITIES_PER_PAGE = 10;

const GET_ACTIVITIES = gql`
  query GetActivities($studentId: ID!, $first: Int!, $after: String) {
    activitiesByStudent(student_Id: $studentId, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          uuid
          date
          content
          image
          type
          user {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const ACTIVITY_EMOJIS = {
  SC: '☀️', // Sunscreen
  PO: '📣', // Post
  SI: '✅', // Signed In
  SO: '⛔', // Signed Out
  IC: '🚨', // Incident
  AB: '🚫', // Absence
  IN: '🤕', // Injury
  DC: '🧷', // Diaper
  PT: '🚽', // Potty
  SN: '🍎', // Snack
  DK: '🧃', // Drink
  ML: '🥪', // Meal
  NT: '📝', // Note
  NP: '😴', // Nap
};

const ACTIVITY_LABELS = {
  SC: "Sunscreen",
  PO: "Post",
  SI: "Signed In",
  SO: "Signed Out",
  IC: "Incident",
  AB: "Absence",
  IN: "Injury",
  DC: "Diaper Change",
  PT: "Potty",
  SN: "Snack",
  DK: "Drink",
  ML: "Meal",
  NT: "Note",
  NP: "Nap",
};

const ActivityCardSkeleton = () => {
  return (
    <Card className="mb-4 max-w-2xl mx-auto animate-pulse">
      <Card.Header>
        <div className="flex items-center gap-3">
          <div className="h-12 w-12 rounded-full bg-gray-200" />
          <div className="flex-grow">
            <div className="flex items-center justify-between">
              <div>
                <div className="flex items-center gap-2">
                  <div className="h-6 w-32 bg-gray-200 rounded" />
                  <div className="h-4 w-20 bg-gray-200 rounded" />
                </div>
                <div className="h-4 w-24 bg-gray-200 rounded mt-1" />
              </div>
            </div>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="h-16 bg-gray-200 rounded" />
      </Card.Body>
    </Card>
  );
};

function StudentActivities({ student }) {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(false);
  const activitiesPerPage = 15;
  const { user } = useUser();
  const { data, fetchMore, refetch } = useQuery(GET_ACTIVITIES, {
    variables: { 
      studentId: student.uuid,
      first: activitiesPerPage,
    },
  });

  useEffect(() => {
    if (data) {
      setActivities(data.activitiesByStudent.edges.map(edge => edge.node));
    }
  }, [data]);

  const loadMore = () => {
    if (!data?.activitiesByStudent?.pageInfo?.hasNextPage) return;
    
    setLoading(true);
    fetchMore({
      variables: { 
        after: data.activitiesByStudent.pageInfo.endCursor,
        first: activitiesPerPage,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          activitiesByStudent: {
            ...fetchMoreResult.activitiesByStudent,
            edges: [
              ...prev.activitiesByStudent.edges,
              ...fetchMoreResult.activitiesByStudent.edges,
            ],
          },
        };
      },
    }).finally(() => setLoading(false));
  };

  const formatTime = (dateString) => {
    return new Date(dateString).toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
      return "Today";
    } else if (date.toDateString() === yesterday.toDateString()) {
      return "Yesterday";
    } else {
      return date.toLocaleDateString('en-US', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
      });
    }
  };

  const ActivityCard = React.forwardRef(({ activity }, ref) => {
    return (
      <Card className="mb-4 max-w-2xl mx-auto" ref={ref}>
        <Card.Header className={!activity.content && !activity.image ? 'border-b-0' : ''}>
          <div className="flex items-center gap-3">
            <div className="h-12 w-12 rounded-full bg-primary-50 border border-primary-200 flex items-center justify-center">
              <span className="text-2xl" role="img" aria-label={ACTIVITY_LABELS[activity.type]}>
                {ACTIVITY_EMOJIS[activity.type]}
              </span>
            </div>
            <div className="flex-grow">
              <div className="flex items-center justify-between">
                <div>
                  <div className="flex items-center gap-2">
                    <Text className="font-semibold text-lg text-gray-900">
                      {ACTIVITY_LABELS[activity.type]}
                    </Text>
                    <Text className="text-sm text-gray-500">
                      • {formatTime(activity.date)}
                    </Text>
                  </div>
                  <Text className="text-sm text-gray-600">
                    {activity.user.firstName} {activity.user.lastName}
                  </Text>
                </div>
                {activity.type != 'SO' && activity.type != 'SI' && (
                  <ModifyActivity activity={activity} refetch={refetch} />
                )}
              </div>
            </div>
          </div>
        </Card.Header>

        {(activity.content || activity.image) && (
          <Card.Body>
            <div className="flex flex-col gap-4">
              {activity.image && (
                <div className="w-full">
                  <div className="aspect-video w-full rounded-lg overflow-hidden relative">
                    <div 
                      className="absolute inset-0 bg-cover bg-center blur-xl scale-110 opacity-50"
                      style={{ backgroundImage: `url(${activity.image})` }}
                    />
                    <div className="relative h-full flex items-center justify-center">
                      <img
                        src={activity.image}
                        alt="Activity"
                        className="max-h-full max-w-full object-contain"
                      />
                    </div>
                  </div>
                </div>
              )}
              {activity.content && (
                <div>
                  <Text className="text-gray-700 leading-relaxed whitespace-pre-wrap">
                    {activity.content}
                  </Text>
                </div>
              )}
            </div>
          </Card.Body>
        )}
      </Card>
    );
  });

  ActivityCard.displayName = 'ActivityCard';

  const groupActivitiesByDate = (activities) => {
    return activities.reduce((groups, activity) => {
      const date = formatDate(activity.date);
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(activity);
      return groups;
    }, {});
  };

  if (loading && activities.length === 0) {
    return (
      <div className="space-y-6">
        <div className="flex justify-end">
          <div className="h-10 w-32 bg-gray-200 rounded animate-pulse" />
        </div>
        {[...Array(3)].map((_, index) => (
          <ActivityCardSkeleton key={index} />
        ))}
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-end">
        {user.role != 'GU' && (
          <CreateActivity student={student} refetch={refetch} />
        )}
      </div>
      
      <div className="h-[600px] overflow-y-auto">
        {activities.length > 0 ? (
          <>
            {Object.entries(groupActivitiesByDate(activities)).map(([date, dateActivities]) => (
              <div key={date} className="mb-8 last:mb-0">
                <div className="flex items-center gap-2 mb-4">
                  <div className="h-px flex-1 bg-gray-200"></div>
                  <Text className="font-medium text-gray-500">{date}</Text>
                  <div className="h-px flex-1 bg-gray-200"></div>
                </div>
                {dateActivities.map((activity) => (
                  <ActivityCard key={activity.uuid} activity={activity} />
                ))}
              </div>
            ))}
            
            {data?.activitiesByStudent?.pageInfo?.hasNextPage && (
              <div className="flex justify-center py-4">
                <Button
                  outline
                  onClick={loadMore}
                  disabled={loading}
                  className="w-full max-w-xs"
                >
                  {loading ? (
                    <div className="flex items-center justify-center">
                      <div className="animate-spin h-5 w-5 mr-2 border-2 border-gray-200 border-t-primary-600 rounded-full" />
                      Loading...
                    </div>
                  ) : (
                    'Load More Activities'
                  )}
                </Button>
              </div>
            )}
          </>
        ) : (
          <div className="text-center py-12">
            <div className="mx-auto h-12 w-12 text-gray-400">
              <ChatBubbleLeftRightIcon className="h-12 w-12" />
            </div>
            <h3 className="mt-2 text-sm font-medium text-gray-900">
              No activities
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              No activities have been recorded for this student yet.
            </p>
            <div className="mt-6">
              {user.role != 'GU' && (
                <CreateActivity student={student} refetch={refetch} />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default StudentActivities; 