// Reports.tsx
import { useEffect, useState } from "react"
import { useUser } from "@/hooks/useUser"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Separator } from "@/components/ui/separator"
import { gql, useMutation } from "@apollo/client"
import { usePostHog } from "posthog-js/react"
import { toast } from "@/hooks/use-toast"
import {
  Clock,
  Users,
  Banknote,
  Download,
  FileBarChart,
  Info,
} from "lucide-react"
import { format } from "date-fns"
import { Calendar } from "@/components/ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { cn } from "@/lib/utils"

const GENERATE_REPORT = gql`
  mutation GenerateReport($input: GenerateReportInput!) {
    generateReport(input: $input) {
      base64Report
    }
  }
`

const REPORT_TYPES = [
  {
    id: "attendance",
    name: "Attendance Report",
    description: "Download detailed attendance records for all students",
    icon: Clock,
    color: "blue",
  },
  {
    id: "payments",
    name: "Payments Report",
    description: "Financial summary of all payments",
    icon: Banknote,
    color: "green",
  },
  {
    id: "enrollment",
    name: "Enrollment Report",
    description: "Current and historical enrollment statistics",
    icon: Users,
    color: "purple",
  },
]

export default function Reports() {
  const { user } = useUser()
  const posthog = usePostHog()

  const getDefaultDateRange = () => {
    const now = new Date()
    const userTimeZone = user?.organization?.timezone || "UTC"
    const today = new Date(
      now.toLocaleString("en-US", { timeZone: userTimeZone })
    )
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1)

    const formatToISODate = (date: Date) => {
      return date.toLocaleDateString("en-CA", {
        timeZone: userTimeZone,
      })
    }

    return {
      from: formatToISODate(firstDay),
      to: formatToISODate(today),
    }
  }

  const [dateRange, setDateRange] = useState<{
    from: Date
    to: Date
  }>(() => {
    const { from, to } = getDefaultDateRange()
    return {
      from: new Date(from),
      to: new Date(to)
    }
  })
  const [selectedReport, setSelectedReport] = useState("attendance")
  const [selectedStudents, setSelectedStudents] = useState("all")
  const [generateReport, { loading: generateReportLoading }] = useMutation(GENERATE_REPORT)

  useEffect(() => {
    posthog.capture("reports_page_viewed")
  }, [posthog])

  useEffect(() => {
    document.title = `Reports - Sprout`
  }, [location])

  const handleGenerateReport = async () => {
    posthog.capture("report_generated", {
      reportType: selectedReport,
      startDate: format(dateRange.from, "yyyy-MM-dd"),
      endDate: format(dateRange.to, "yyyy-MM-dd"),
      include: selectedStudents,
    })

    try {
      const { data } = await generateReport({
        variables: {
          input: {
            reportType: selectedReport,
            startDate: format(dateRange.from, "yyyy-MM-dd"),
            endDate: format(dateRange.to, "yyyy-MM-dd"),
            include: selectedStudents,
          },
        },
      })

      const base64Report = data.generateReport.base64Report
      const blob = new Blob(
        [Uint8Array.from(atob(base64Report), (c) => c.charCodeAt(0))],
        { type: "text/csv" }
      )
      const url = URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", `${selectedReport}_report.csv`)
      document.body.appendChild(link)
      link.click()
      link.parentNode?.removeChild(link)

      toast({
        title: "Success",
        description: "Report generated successfully",
      })
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      })
    }
  }

  // Add state for managing popover open/close
  const [startDateOpen, setStartDateOpen] = useState(false)
  const [endDateOpen, setEndDateOpen] = useState(false)

  return (
    <div className="hidden space-y-6 p-10 pb-16 md:block">
      <div className="space-y-0.5">
        <h2 className="text-2xl font-bold tracking-tight">Reports</h2>
        <p className="text-muted-foreground">
          Generate detailed reports to track attendance, revenue, and enrollment metrics
        </p>
      </div>
      <Separator className="my-6" />

      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {REPORT_TYPES.map((report) => (
          <Card
            key={report.id}
            className={`cursor-pointer transition-all hover:shadow-md ${
              selectedReport === report.id
                ? "ring-2 ring-primary"
                : "hover:border-primary/20"
            }`}
            onClick={() => setSelectedReport(report.id)}
          >
            <CardContent className="pt-6">
              <div className="flex items-center">
                <div className={`rounded-lg p-2 ${
                  report.color === "blue" ? "bg-blue-100 text-blue-600" :
                  report.color === "green" ? "bg-green-100 text-green-600" :
                  report.color === "purple" ? "bg-purple-100 text-purple-600" :
                  "bg-primary/10 text-primary"
                }`}>
                  <report.icon className="h-6 w-6" />
                </div>
                <div className="ml-4">
                  <h3 className="font-medium">{report.name}</h3>
                  <p className="text-sm text-muted-foreground">
                    {report.description}
                  </p>
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>

      <Card>
        <CardHeader>
          <div className="space-y-1">
            <h3 className="text-lg font-medium">Generate Report</h3>
            <p className="text-sm text-muted-foreground">
              Generate a detailed report based on the selected report type, date range, and student selection.
            </p>
        </div>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div className="space-y-2">
              <label className="text-sm font-medium">Date Range</label>
              <div className="grid grid-cols-2 gap-4">
                <Popover open={startDateOpen} onOpenChange={setStartDateOpen}>
                  <PopoverTrigger asChild>
                    <Button
                      variant="outline"
                      className={cn(
                        "w-full justify-start text-left font-normal",
                        !dateRange.from && "text-muted-foreground"
                      )}
                    >
                      {dateRange.from ? format(dateRange.from, "PPP") : "Pick a date"}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0 bg-popover" align="start">
                    <Calendar
                      mode="single"
                      selected={dateRange.from}
                      onSelect={(date) => {
                        if (date) {
                          const newDate = new Date(date)
                          newDate.setHours(0, 0, 0, 0)
                          setDateRange({ ...dateRange, from: newDate })
                          setStartDateOpen(false) // Close popover after selection
                        }
                      }}
                      initialFocus
                      disabled={(date) => date > dateRange.to}
                    />
                  </PopoverContent>
                </Popover>
                <Popover open={endDateOpen} onOpenChange={setEndDateOpen}>
                  <PopoverTrigger asChild>
                    <Button
                      variant="outline"
                      className={cn(
                        "w-full justify-start text-left font-normal",
                        !dateRange.to && "text-muted-foreground"
                      )}
                    >
                      {dateRange.to ? format(dateRange.to, "PPP") : "Pick a date"}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0 bg-popover" align="start">
                    <Calendar
                      mode="single"
                      selected={dateRange.to}
                      onSelect={(date) => {
                        if (date) {
                          const newDate = new Date(date)
                          newDate.setHours(23, 59, 59, 999)
                          setDateRange({ ...dateRange, to: newDate })
                          setEndDateOpen(false) // Close popover after selection
                        }
                      }}
                      initialFocus
                      disabled={(date) => date < dateRange.from}
                    />
                  </PopoverContent>
                </Popover>
              </div>
            </div>
            <div className="space-y-2">
              <label className="text-sm font-medium">Students</label>
              <Select
                value={selectedStudents}
                onValueChange={(value) => setSelectedStudents(value)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select students to include" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="all">All Students</SelectItem>
                  <SelectItem value="active">Active Students Only</SelectItem>
                  <SelectItem value="inactive">Inactive Students Only</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>

          <div className="flex justify-end">
            <Button
              onClick={handleGenerateReport}
              disabled={generateReportLoading || !dateRange.from || !dateRange.to}
            >
              <Download className="mr-2 h-4 w-4" />
              {generateReportLoading ? "Generating..." : "Generate Report"}
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}