import { Button } from '../catalyst/button'
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../catalyst/dialog'
import { Field, FieldGroup, Label, Description, ErrorMessage } from '../catalyst/fieldset'
import { Input } from '../catalyst/input'
import { useState, useEffect } from 'react'
import { Select } from '../catalyst/select'
import { useMutation, gql } from '@apollo/client'
import { InformationCircleIcon } from '@heroicons/react/16/solid'
import { Tooltip } from 'react-tooltip'
import { Alert, AlertActions, AlertDescription, AlertTitle } from '../catalyst/alert'
const UPDATE_ENROLLMENT = gql`
  mutation ModifyEnrollment($input: ModifyEnrollmentInput!) {
    modifyEnrollment(input: $input) {
      enrollment {
        id
      }
    }
  }
`;

const MARK_ENROLLMENT_INACTIVE = gql`
  mutation MarkEnrollmentInactive($input: MarkEnrollmentInactiveInput!) {
    markEnrollmentInactive(input: $input) {
      enrollment {
        id
      }
    }
  }
`

export default function ModifyEnrollment({ programs, student, refetch, enrollment, ...props }) {
  let [isOpen, setIsOpen] = useState(false)
  let [inactiveDialogOpen, setInactiveDialogOpen] = useState(false)
  const [enrollmentStartDate, setEnrollmentStartDate] = useState(new Date().toISOString().split('T')[0])
  const [firstPaymentDate, setFirstPaymentDate] = useState(enrollment.enrollmentDate)
  const [rate, setRate] = useState(enrollment.rate)
  const [rateFrequency, setRateFrequency] = useState(enrollment.rateFrequency)
  const [program, setProgram] = useState(enrollment.program.id)
  const [errors, setErrors] = useState({})
  const [showErrors, setShowErrors] = useState(false)
  const [updateEnrollment, { loading }] = useMutation(UPDATE_ENROLLMENT, {
    onCompleted: () => {
      setIsOpen(false)
      refetch()
    }
  })
  const [markEnrollmentInactive, { loading: markEnrollmentInactiveLoading }] = useMutation(MARK_ENROLLMENT_INACTIVE, {
    onCompleted: () => {
      setIsOpen(false)
      refetch()
    }
  })

  useEffect(() => {
    console.log('enrollment.program.id', enrollment.program.id)
    console.log('program.id', program)
    console.log('enrollment.program.id !== program.id', enrollment.program.id !== program.id)
  }, [program, enrollment]);

  useEffect(() => {
    if (program && program !== enrollment.program.id ) {
      const selectedProgram = programs.find(p => p.id === program)
      if (selectedProgram) {
        setRate(selectedProgram.defaultRate || '')
        setRateFrequency(selectedProgram.defaultRateFrequency || '')
      }
    }
    if (program && program === enrollment.program.id) {
      setRate(enrollment.rate)
      setRateFrequency(enrollment.rateFrequency)
    }
  }, [program, programs])

  const handleClose = () => {
    setIsOpen(false)
    setErrors({})
    setShowErrors(false)
  }

  const validateForm = () => {
    const newErrors = {}
    if (!program) newErrors.program = 'Program is required'
    if (!enrollmentStartDate) newErrors.enrollmentStartDate = 'Start date is required'
    if (!firstPaymentDate) newErrors.firstPaymentDate = 'First payment date is required'
    if (!rate || rate <= 0) newErrors.rate = 'Valid rate is required'
    if (!rateFrequency) newErrors.rateFrequency = 'Billing frequency is required'
    return newErrors
  }

  const handleSave = (e) => {
    e.preventDefault()
    
    const newErrors = validateForm()
    setErrors(newErrors)
    setShowErrors(true)

    if (Object.keys(newErrors).length === 0) {
      updateEnrollment({
        variables: {
          input: {
            id: enrollment.id,
            programId: program,
            enrollmentDate: enrollmentStartDate,
            rate: parseFloat(rate),
            rateFrequency: rateFrequency
            
          }
        }
      })
    }
  }

  const handleMarkInactive = () => {
    markEnrollmentInactive({
      variables: {
        input: {
          id: enrollment.id,
        }
      }
    })
    setInactiveDialogOpen(false)
  }

  return (
    <>
      <Button outline type="button" onClick={() => setIsOpen(true)}>
        Edit
      </Button>
      <Alert open={inactiveDialogOpen} onClose={() => setInactiveDialogOpen(false)}>
        <AlertTitle>Are you sure you want to mark this enrollment as inactive?</AlertTitle>
        <AlertDescription>
          The student will no longer be enrolled in the program.
        </AlertDescription>
        <AlertActions>
          <Button plain onClick={() => setInactiveDialogOpen(false)}>
            Cancel
          </Button>
          <Button color="red" onClick={handleMarkInactive}>Mark Inactive</Button>
        </AlertActions>
      </Alert>

      <Dialog open={isOpen} onClose={setIsOpen}>
        <DialogTitle>Edit Enrollment</DialogTitle>
        <DialogDescription>
          Modify this enrollment.
        </DialogDescription>
        <form onSubmit={handleSave}>
          <DialogBody>
            <FieldGroup>
              <Field>
                <Label>Program</Label>
                <Select 
                  value={program} 
                  onChange={(e) => setProgram(e.target.value)}
                  invalid={showErrors && !!errors.program}
                >
                  <option value="" disabled>Select a Program</option>
                  {programs.map(program => (
                    <option key={program.id} value={program.id}>{program.name}</option>
                  ))}
                </Select>
                {showErrors && errors.program && <ErrorMessage>{errors.program}</ErrorMessage>}
              </Field>
              <Field>
              <div className="flex items-center gap-1">
                  <Label>
                    Start Date
                  </Label>
                  <InformationCircleIcon 
                    className="h-3 w-3 text-gray-400 cursor-help"
                    data-tooltip-id="enrollment-start-date-tooltip"
                    data-tooltip-content="The date the student will start the program."
                  />
                  <Tooltip id="enrollment-start-date-tooltip" />
                </div>
                <Input 
                  type="date" 
                  value={enrollmentStartDate} 
                  onChange={(e) => setEnrollmentStartDate(e.target.value)}
                  invalid={showErrors && !!errors.enrollmentStartDate}
                />
                {showErrors && errors.enrollmentStartDate && <ErrorMessage>{errors.enrollmentStartDate}</ErrorMessage>}
              </Field>
              <div className="grid grid-cols-2 gap-4">
                <Field>
                  <Label className="flex items-center gap-1">
                    Rate ($)
                  </Label>
                  <Input 
                    type="number" 
                    value={rate} 
                    onChange={(e) => setRate(e.target.value)}
                    invalid={showErrors && !!errors.rate}
                  />
                  {showErrors && errors.rate && <ErrorMessage>{errors.rate}</ErrorMessage>}
                </Field>
                <Field>
                  <Label>Billing Frequency</Label>
                  <Select 
                    value={rateFrequency} 
                    onChange={(e) => setRateFrequency(e.target.value)}
                    invalid={showErrors && !!errors.rateFrequency}
                  >
                    <option value="" disabled>Select Frequency</option>
                    <option value="One Time">One Time</option>
                    <option value="Daily">Daily</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Yearly">Yearly</option>
                  </Select>
                  {showErrors && errors.rateFrequency && <ErrorMessage>{errors.rateFrequency}</ErrorMessage>}
                </Field>
              </div>
            </FieldGroup>
          </DialogBody>
          <DialogActions className="flex justify-between">
            <div>
              {enrollment.active && (
                <Button color="red" type="button" onClick={() => setInactiveDialogOpen(true)}>
                  Mark Inactive
                </Button>
              )}
            </div>
            <div className="flex gap-2">
              <Button type="button" plain onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" color="sprout" disabled={loading}>
                Save
              </Button>
            </div>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
