import { Alert, AlertActions, AlertDescription, AlertTitle } from '../../components/catalyst/alert'
import { Button } from '../../components/catalyst/button'
import { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
const DELETE_ATTENDANCE_RECORD = gql`
  mutation DeleteAttendanceRecord($input: DeleteAttendanceRecordInput!) {
    deleteAttendanceRecord(input: $input) {
      success
    }
  }
`

export default function DeleteAttendanceRecord({ record, refetch, ...props }) {
  let [isOpen, setIsOpen] = useState(false)
  const [deleteAttendanceRecord] = useMutation(DELETE_ATTENDANCE_RECORD)

  const handleDeleteAttendanceRecord = async () => {
    const { data } = await deleteAttendanceRecord({ 
      variables: { 
        input: { 
          id: record.uuid 
        } 
      } 
    })
    
    if (data?.deleteAttendanceRecord?.success) {
      setIsOpen(false)
      refetch()
    }
  }

  return (
    <>
      <Button outline type="button" onClick={() => setIsOpen(true)}>
        {props.children}
      </Button>
      <Alert open={isOpen} onClose={setIsOpen}>
        <AlertTitle>Are you sure you want to delete attendance record?</AlertTitle>
        <AlertDescription>
          This action cannot be undone.
        </AlertDescription>
        <AlertActions>
          <Button plain onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button color="red" onClick={handleDeleteAttendanceRecord}>Delete</Button>
        </AlertActions>
      </Alert>
    </>
  )
}