import { forwardRef } from 'react'
import { useState, useRef, useEffect } from 'react'
import logoIcon from '../../assets/logo-icon.svg'
import { useAuth } from '../../hooks/useAuth'
import { useUser } from '../../hooks/useUser'
import {
  Cog6ToothIcon,
  LightBulbIcon,
  ArrowRightStartOnRectangleIcon,
  ArrowTopRightOnSquareIcon,
  AcademicCapIcon,
  LifebuoyIcon,
} from '@heroicons/react/20/solid'
import { clsx } from 'clsx'

import { Avatar } from '../../components/catalyst/avatar'
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
  DropdownDivider,
} from '../../components/catalyst/dropdown'
import {
  StackedLayout,
} from '../../components/catalyst/stacked-layout'
import {
  Navbar,
  NavbarSpacer,
  NavbarSection,
  NavbarItem,
  NavbarDivider,
} from '../../components/catalyst/navbar'
import {
  Sidebar,
  SidebarHeader,
  SidebarBody,
  SidebarSection,
  SidebarItem,
  SidebarLabel,
  SidebarSpacer,
  SidebarFooter,
  SidebarItemWithLabel,
} from '../../components/catalyst/sidebar'
import { Button } from '../../components/catalyst/button'
import { Tooltip } from '../../components/catalyst/tooltip'
import { SidebarLayout } from '../../components/catalyst/sidebar-layout'
import { generateAvatar } from '../../utils/generateAvatar'
import { useLocation } from 'wouter'
import { DocumentCheckIcon } from '@heroicons/react/24/outline'


const SidebarDropdownButton = forwardRef(function SidebarDropdownButton(
  { className, ...props },
  ref
) {
  let containerClasses = clsx(
    // Base
    'flex items-center justify-center rounded-lg text-base/6 font-medium text-zinc-950 sm:text-sm/5',
    // Hover
    'hover:bg-zinc-950/5',
    // Dark mode
    'dark:text-white dark:hover:bg-white/5',
    // Animation
    'transition-transform duration-200 ease-in-out hover:scale-105',
    // Ensure square aspect ratio
    'aspect-square w-8 h-8' // Adjust width and height to ensure square shape
  )

  let iconClasses = clsx(
    // Icon
    '[&>*]:fill-zinc-500 hover:[&>*]:fill-zinc-950',
    // Dark mode icon color
    'dark:[&>*]:fill-zinc-400 dark:hover:[&>*]:fill-white'
  )

  return (
    <DropdownButton
      as="div"
      className={clsx(containerClasses, className)}
      ref={ref}
      {...props}
    >
      <span className={iconClasses}>
        {props.children}
      </span>
    </DropdownButton>
  )
})

const SidebarItemWithTooltip = ({ href, onClick, current, icon: Icon, tooltip, showNotification }) => {
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 })
  const itemRef = useRef(null)

  useEffect(() => {
    const updatePosition = () => {
      if (itemRef.current) {
        const rect = itemRef.current.getBoundingClientRect()
        setTooltipPosition({
          top: rect.top + rect.height / 2,
          left: rect.right + 2, // 2px offset from the sidebar item
        })
      }
    }

    updatePosition()
    window.addEventListener('resize', updatePosition)
    return () => window.removeEventListener('resize', updatePosition)
  }, [])

  return (
    <Tooltip content={tooltip} style={tooltipPosition}>
      <div ref={itemRef} className="relative">
        <SidebarItem onClick={onClick} href={href} current={current}>
          <Icon />
        </SidebarItem>
        {showNotification && (
          <span className="absolute top-0 right-0 block h-2.5 w-2.5 rounded-full bg-yellow-400 ring-2 ring-white dark:ring-zinc-900" />
        )}
      </div>
    </Tooltip>
  )
}

const getPageTitle = (path) => {
  const base = 'Sprout'
  const section = path.split('/')[1]
  
  switch (section) {
    case 'students':
      return `Students | ${base}`
    case 'billing':
      return `Billing | ${base}`
    case 'settings':
      return `Settings | ${base}`
    default:
      return base
  }
}

const AuthenticatedGuardianLayout = ({ children }) => {
  const { logout } = useAuth()
  const { user } = useUser()
  const [location] = useLocation()

  useEffect(() => {
    document.title = getPageTitle(location)
  }, [location])

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://do.featurebase.app/js/sdk.js';
    script.id = 'featurebase-sdk';
    document.head.appendChild(script);

    const win = window;

    if (typeof win.Featurebase !== 'function') {
      win.Featurebase = function () {
        (win.Featurebase.q = win.Featurebase.q || []).push(arguments);
      };
    }

    win.Featurebase('initialize_feedback_widget', {
      organization: 'sprout', // Replace with your organization name
      theme: 'light',
      placement: 'right', // Optional - remove to hide the floating button
      email: user?.email, // Use user email if available
      locale: 'en', // Set language
    });

    return () => {
      const existingScript = document.getElementById('featurebase-sdk');
      if (existingScript) {
        document.head.removeChild(existingScript);
      }
    };
  }, [user]);
  return (
    <div className="relative flex min-h-screen flex-col w-min-fit">
      <StackedLayout
        navbar={
          <Navbar>
            <NavbarSection>
              <NavbarItem>
                <img
                  src={user?.organization?.logoUrl || logoIcon}
                  alt="Company Logo"
                  className="h-8 w-auto px-1"
                />
              </NavbarItem>
            </NavbarSection>
            <NavbarDivider className="max-lg:hidden" />
            <NavbarSection className="max-lg:hidden">
              <NavbarItem current={location.split('/')[1] === 'students'} href="/students">
                <AcademicCapIcon /> My Students
              </NavbarItem>
              <NavbarItem href="https://support.sproutcare.io/help" external>
                Help
                <ArrowTopRightOnSquareIcon />
              </NavbarItem>
            </NavbarSection>
            <NavbarSpacer />
            <NavbarSection>
              <Dropdown>
                <DropdownButton as={NavbarItem}>
                  <Avatar src={user ? user?.avatar ? user.avatar : generateAvatar(user.firstName, user.lastName) : null} square />
                </DropdownButton>
                <DropdownMenu className="min-w-64" anchor="bottom end">
                  <DropdownItem onClick={() => logout()}>
                    <ArrowRightStartOnRectangleIcon />
                    <DropdownLabel>Sign out</DropdownLabel>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </NavbarSection>
          </Navbar>
        }
        sidebar={
          <Sidebar>
            <SidebarHeader className="flex items-center justify-center">
              <img
                src={logoIcon}
                alt="Company Logo"
                className="h-8 w-auto"
              />
            </SidebarHeader>
            <SidebarBody>
              <SidebarSection>
                <SidebarItemWithLabel current={location.split('/')[1] === 'students'} className="w-full" href="/students">
                  <AcademicCapIcon />
                  <SidebarLabel>My Students</SidebarLabel>
                </SidebarItemWithLabel>
                <SidebarItemWithLabel>
                  <DocumentCheckIcon/>
                  <SidebarLabel>Paperwork</SidebarLabel>
                </SidebarItemWithLabel>
                <SidebarItemWithLabel className="w-full" href="https://support.sproutcare.io/help" external>
                  <SidebarLabel>Help</SidebarLabel>
                  <ArrowTopRightOnSquareIcon />
                </SidebarItemWithLabel>
              </SidebarSection>
            </SidebarBody>
            <SidebarFooter className="max-lg:hidden">
              <Dropdown>
                <SidebarDropdownButton>
                  <Avatar
                    src={user ? user?.avatar ? user.avatar : generateAvatar(user.firstName, user.lastName) : null}
                    className="size-6"
                    square
                    alt="Student Avatar"
                  />
                </SidebarDropdownButton>
                <DropdownMenu className="min-w-64" anchor="bottom end">
                  <DropdownItem onClick={null}>
                    <Cog6ToothIcon />
                    <DropdownLabel>Manage account</DropdownLabel>
                  </DropdownItem>
                  <DropdownDivider />
                  <DropdownItem href="/share-feedback">
                    <LightBulbIcon />
                    <DropdownLabel>Share feedback</DropdownLabel>
                  </DropdownItem>
                  <DropdownDivider />
                  <DropdownItem onClick={() => logout()}>
                    <ArrowRightStartOnRectangleIcon />
                    <DropdownLabel>Sign out</DropdownLabel>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </SidebarFooter>
          </Sidebar>
        }
      >
        {children}
      </StackedLayout>
    </div>
  )
}

export default AuthenticatedGuardianLayout
