import { useState } from "react"
import { useMutation, gql } from "@apollo/client"
import { useToast } from "@/hooks/use-toast"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Plus } from "lucide-react"

const CREATE_PAPERWORK = gql`
  mutation CreatePaperwork($input: CreatePaperworkInput!) {
    createPaperwork(input: $input) {
      paperwork {
        id
      }
    }
  }
`

interface CreatePaperworkProps {
  refetch: () => void
}

export function CreatePaperwork({ refetch }: CreatePaperworkProps) {
  const [open, setOpen] = useState(false)
  const [name, setName] = useState("")
  const [file, setFile] = useState<File | null>(null)
  const [expires, setExpires] = useState("YEARLY")
  const { toast } = useToast()

  const [createPaperwork, { loading }] = useMutation(CREATE_PAPERWORK, {
    onCompleted: () => {
      refetch()
      handleClose()
      toast({
        title: "Success",
        description: "Paperwork created successfully",
      })
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      })
    },
  })

  const handleClose = () => {
    setOpen(false)
    setName("")
    setFile(null)
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    
    if (!name || !file) {
      toast({
        title: "Error",
        description: "Please fill in all required fields",
        variant: "destructive",
      })
      return
    }

    try {
      await createPaperwork({
        variables: {
          input: {
            name,
            expires,
            file,
          },
        },
      })
    } catch (error) {
      // Error is handled by onError callback
    }
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>
          <Plus className="mr-2 h-4 w-4" />
          Add Paperwork
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Add Paperwork</DialogTitle>
          <DialogDescription>
            Add paperwork to use with your programs.
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="name">Document Name</Label>
              <Input
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter document name..."
              />
              <p className="text-sm text-muted-foreground">
                This name will be shown to guardians as they are signing.
              </p>
            </div>

            <div className="space-y-2">
              <Label htmlFor="file">Document</Label>
              <div className="flex flex-col space-y-2">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => document.getElementById("file-upload")?.click()}
                >
                  {file ? "Change Document" : "Upload Document"}
                </Button>
                <input
                  id="file-upload"
                  type="file"
                  className="hidden"
                  onChange={(e) => setFile(e.target.files?.[0] || null)}
                  accept=".pdf,.doc,.docx"
                />
                {file && (
                  <p className="text-sm text-muted-foreground">
                    Selected file: {file.name}
                  </p>
                )}
              </div>
              <p className="text-sm text-muted-foreground">
                Upload a PDF, DOC, or DOCX file. You can always add additional documents later.
              </p>
            </div>

            <div className="space-y-2">
              <Label htmlFor="expires">Expires</Label>
              <Select value={expires} onValueChange={setExpires}>
                <SelectTrigger>
                  <SelectValue placeholder="Select expiration" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="NEVER">Never</SelectItem>
                  <SelectItem value="WEEKLY">Weekly</SelectItem>
                  <SelectItem value="MONTHLY">Monthly</SelectItem>
                  <SelectItem value="QUARTERLY">Quarterly</SelectItem>
                  <SelectItem value="YEARLY">Yearly</SelectItem>
                </SelectContent>
              </Select>
              <p className="text-sm text-muted-foreground">
                When should this paperwork expire and require renewal?
              </p>
            </div>
          </div>
          
          <DialogFooter>
            <Button type="button" variant="outline" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit" disabled={loading}>
              Save
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
} 