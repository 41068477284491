import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { useUser } from '@/hooks/useUser';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Separator } from '@/components/ui/separator';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationNext,
  PaginationPrevious,
} from '@/components/ui/pagination';
import { useQuery } from '@apollo/client';
import { useState, useEffect } from 'react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { TriangleAlert, CheckCircleIcon, UsersIcon, CalendarIcon, BanknoteIcon, BarChartIcon } from 'lucide-react';
import { usePostHog } from 'posthog-js/react';
import { gql } from '@apollo/client';
import { Skeleton } from "@/components/ui/skeleton"
import { useLocation } from "wouter"

const HOME_METRICS_QUERY = gql`
  query GetHomeMetrics {
    homePageMetrics
  }
`;

const STUDENTS_QUERY = gql`
  query GetStudents($after: String, $first: Int!, $archived: Boolean) {
    students(after: $after, first: $first, archived: $archived) {
      edges {
        node {
          uuid
          firstName
          lastName
          dateOfBirth
          gender
          enrolled
          attendanceStatus
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

function formatCurrency(amount: number) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount);
}

const HomePageSkeleton = () => {
  return (
    <div className="space-y-6 p-6">
      <div className="h-8 w-48 bg-muted rounded animate-pulse mb-6" />
      
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
        {[...Array(4)].map((_, i) => (
          <Card key={i} className="animate-pulse">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <div className="h-4 w-24 bg-muted rounded" />
              <div className="h-8 w-8 rounded-md bg-muted" />
            </CardHeader>
            <CardContent>
              <div className="h-7 w-16 bg-muted rounded mb-2" />
              <div className="h-4 w-20 bg-muted rounded" />
            </CardContent>
          </Card>
        ))}
      </div>

      <Card className="animate-pulse">
        <CardHeader className="flex flex-row items-center justify-between">
          <div className="h-5 w-24 bg-muted rounded" />
          <div className="h-10 w-[180px] bg-muted rounded" />
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <div className="h-10 bg-muted rounded" />
            {[...Array(5)].map((_, i) => (
              <div key={i} className="h-12 bg-muted rounded" />
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default function Home() {
  const { user } = useUser();
  const [location, navigate] = useLocation()
  const [filter, setFilter] = useState('active');
  const [currentPage, setCurrentPage] = useState(1);
  const studentsPerPage = 10;
  const posthog = usePostHog();
  const { data, loading, refetch } = useQuery(HOME_METRICS_QUERY);
  const { 
    data: studentsData, 
    loading: studentsLoading, 
    fetchMore, 
    refetch: studentsRefetch 
  } = useQuery(STUDENTS_QUERY, {
    variables: {
      after: null,
      first: studentsPerPage,
      archived: filter === 'archived'
    },
  });
  const [metrics, setMetrics] = useState({});

  useEffect(() => {
    document.title = `Overview - Sprout`
  }, [location])

  useEffect(() => {
    refetch();
    studentsRefetch();
  }, []);

  useEffect(() => {
    posthog.capture('home_page_viewed');
  }, [posthog]);

  useEffect(() => {
    if (data) {
      setMetrics(JSON.parse(data?.homePageMetrics) || {});
    }
  }, [data]);

  const handlePageChange = (newPage: number) => {
    if (newPage > currentPage && pageInfo?.hasNextPage) {
      fetchMore({
        variables: { after: pageInfo.endCursor, first: studentsPerPage },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return {
            students: {
              ...fetchMoreResult.students,
              edges: [
                ...prev.students.edges,
                ...fetchMoreResult.students.edges,
              ],
            },
          };
        },
      });
    }
    setCurrentPage(newPage);
  };

  const students = studentsData?.students?.edges || [];
  const pageInfo = studentsData?.students?.pageInfo;
  const displayedStudents = students.slice(
    (currentPage - 1) * studentsPerPage,
    currentPage * studentsPerPage
  );
  const totalPages = Math.max(
    Math.ceil(students.length / studentsPerPage),
    currentPage + (pageInfo?.hasNextPage ? 1 : 0)
  );

  if (loading) {
    return <HomePageSkeleton />;
  }

  return (
    <div className="space-y-6 p-6">
      <div className="space-y-0.5">
        <h2 className="text-2xl font-bold tracking-tight">Overview</h2>
      </div>
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Students Today</CardTitle>
            <div className="rounded-md bg-blue-50 p-2">
              <UsersIcon className="h-4 w-4 text-blue-600" />
            </div>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{metrics.studentsToday}</div>
            <div className="flex items-center justify-end">
              {user.organization.maxStudents && metrics.studentsToday > user.organization.maxStudents ? (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <TriangleAlert className="h-4 w-4 text-destructive" />
                    </TooltipTrigger>
                    <TooltipContent>
                      Student count exceeds organization capacity
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              ) : (
                <div className="flex items-center">
                  <CheckCircleIcon className="h-4 w-4 text-green-600" />
                  <span className="text-xs text-green-600 ml-1">All good</span>
                </div>
              )}
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Students Tomorrow</CardTitle>
            <div className="rounded-md bg-green-50 p-2">
              <CalendarIcon className="h-4 w-4 text-green-600" />
            </div>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{metrics.studentsTomorrow}</div>
            <div className="flex items-center justify-end">
              {user.organization.maxStudents && metrics.studentsTomorrow > user.organization.maxStudents ? (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <TriangleAlert className="h-4 w-4 text-destructive" />
                    </TooltipTrigger>
                    <TooltipContent>
                      Student count exceeds organization capacity
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              ) : (
                <div className="flex items-center">
                  <CheckCircleIcon className="h-4 w-4 text-green-600" />
                  <span className="text-xs text-green-600 ml-1">All good</span>
                </div>
              )}
            </div>
          </CardContent>
        </Card>

        {user?.role === "AD" && (
          <>
            <Card>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">MTD Revenue</CardTitle>
                <div className="rounded-md bg-yellow-50 p-2">
                  <BanknoteIcon className="h-4 w-4 text-yellow-600" />
                </div>
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">
                  {formatCurrency(metrics.mtdRevenue)}
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">YTD Revenue</CardTitle>
                <div className="rounded-md bg-purple-50 p-2">
                  <BarChartIcon className="h-4 w-4 text-purple-600" />
                </div>
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">
                  {formatCurrency(metrics.ytdRevenue)}
                </div>
              </CardContent>
            </Card>
          </>
        )}
      </div>
      <div className="flex justify-between items-center">
        <div className="space-y-1">
          <h3 className="text-lg font-medium">Recent Students</h3>
        </div>
      </div>
          <div className="rounded-md border">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead>Date of Birth</TableHead>
                  <TableHead>Gender</TableHead>
                  <TableHead>Enrolled</TableHead>
                  <TableHead>Status</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {studentsLoading ? (
                  Array.from({ length: 5 }).map((_, index) => (
                    <TableRow key={index}>
                      <TableCell><Skeleton className="h-4 w-[200px]" /></TableCell>
                      <TableCell><Skeleton className="h-4 w-[100px]" /></TableCell>
                      <TableCell><Skeleton className="h-4 w-[80px]" /></TableCell>
                    </TableRow>
                  ))
                ) : displayedStudents.length > 0 ? (
                  displayedStudents.map((student) => (
                    <TableRow 
                      key={student.node.uuid}
                      className="cursor-pointer hover:bg-muted/50"
                      onClick={() => navigate(`/students/${student.node.uuid}`)}
                    >
                      <TableCell className="font-medium">
                        {`${student.node.firstName} ${student.node.lastName}`}
                      </TableCell>
                      <TableCell>{student.node.dateOfBirth}</TableCell>
                      <TableCell>{student.node.gender}</TableCell>
                      <TableCell>
                        {student.node.enrolled ? 'Yes' : 'No'}
                      </TableCell>
                      <TableCell>
                        <span className={`inline-flex items-center px-2 py-1 rounded-full text-xs font-medium ${
                          student.node.attendanceStatus === 'signed_out' ? 'bg-green-100 text-green-700' : 'bg-gray-100 text-gray-700'
                        }`}>
                          {student.node.attendanceStatus === 'signed_out' ? 'Signed Out' : 'Signed In'}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} className="text-center py-12">
                      <div className="space-y-2">
                        <UsersIcon className="h-12 w-12 text-muted-foreground mx-auto" />
                        <p className="text-md font-semibold text-muted-foreground">No students found</p>
                        <p className="text-sm text-muted-foreground">
                          Add your first student to get started.
                        </p>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          { pageInfo?.hasNextPage && (
            <div className="mt-4 flex justify-between">
              <Pagination>
                <PaginationContent>
                <PaginationItem>
                  <PaginationPrevious 
                    onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
                    className={currentPage === 1 ? "pointer-events-none opacity-50" : ""}
                  />
                </PaginationItem>
                {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                  <PaginationItem key={page}>
                    <button
                      className={`px-3 py-1 rounded-md ${
                        currentPage === page
                          ? "bg-primary text-primary-foreground"
                          : "hover:bg-muted"
                      }`}
                      onClick={() => handlePageChange(page)}
                    >
                      {page}
                    </button>
                  </PaginationItem>
                ))}
                <PaginationItem>
                  <PaginationNext 
                    onClick={() => pageInfo?.hasNextPage && handlePageChange(currentPage + 1)}
                    className={(!pageInfo?.hasNextPage && currentPage === totalPages) ? "pointer-events-none opacity-50" : ""}
                  />
                </PaginationItem>
              </PaginationContent>
            </Pagination>
          </div>)}
    </div>
  );
}
