import { useState } from 'react'
import { useAuth } from '../../hooks/useAuth'
import { Input } from '../../components/catalyst/input'
import { Button } from '../../components/catalyst/button'
import { Field, FieldGroup, Fieldset, Label } from '../../components/catalyst/fieldset'
import { Text } from '../../components/catalyst/text'
import { Heading } from '../../components/catalyst/heading'
import logoIcon from '../../assets/logo-icon.svg'
import { XCircleIcon } from '@heroicons/react/24/outline'
import { API_BASE_URL } from '../../config'

function AuthenticationPage() {
  const { login, signup } = useAuth()
  const [isLogin, setIsLogin] = useState(true)
  const [isPasswordReset, setIsPasswordReset] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError(null)
    try {
      if (isLogin) {
        const loginError = await login({ email: email, password: password })
        if (loginError) {
          setError(loginError.message)
        }
      } else {
        const signupError = await signup({ "first_name": firstName, "last_name": lastName, "email": email, "password": password })
        if (signupError) {
          setError(signupError.message)
        }
      }
    } catch (err) {
      console.error('Authentication error:', err)
      setError('An unexpected error occurred during authentication')
    }
  }

  const handlePasswordReset = async (e) => {
    e.preventDefault()
    setError(null)
    setSuccess(null)
    
    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/password/reset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      })
      
      if (response.ok) {
        setSuccess('Password reset instructions have been sent to your email.')
        setEmail('')
      } else {
        const data = await response.json()
        setError(data.message || 'Failed to send reset instructions')
      }
    } catch (err) {
      console.error('Password reset error:', err)
      setError('An unexpected error occurred')
    }
  }

  if (isPasswordReset) {
    return (
      <div className="flex min-h-screen bg-gradient-to-br from-green-50 to-gray-100 dark:from-zinc-900 dark:to-zinc-800">
        <div className="m-auto w-full max-w-lg p-8">
          <div className="transform transition-all duration-200 ease-in-out">
            <div className="mb-8 text-center">
              <img src={logoIcon} alt="Sprout Logo" className="mx-auto h-12 w-auto" />
              <Heading level={1} className="mt-6 text-4xl font-bold tracking-tight">
                Reset your password
              </Heading>
              <Text className="mt-2 text-lg text-zinc-600 dark:text-zinc-400">
                Enter your email address and we'll send you instructions
              </Text>
            </div>

            <div className="rounded-xl bg-white/80 backdrop-blur-sm dark:bg-zinc-900/80 p-8 shadow-xl ring-1 ring-zinc-950/5 dark:ring-white/10">
              <form onSubmit={handlePasswordReset} className="space-y-6">
                {error && (
                  <div className="animate-fadeIn rounded-lg bg-red-50 dark:bg-red-900/30 p-4 mb-6">
                    <div className="flex items-center">
                      <XCircleIcon className="h-5 w-5 text-red-400" />
                      <p className="ml-3 text-sm text-red-800 dark:text-red-200">{error}</p>
                    </div>
                  </div>
                )}
                
                {success && (
                  <div className="animate-fadeIn rounded-lg bg-green-50 dark:bg-green-900/30 p-4 mb-6">
                    <p className="text-sm text-green-800 dark:text-green-200">{success}</p>
                  </div>
                )}

                <Fieldset>
                  <FieldGroup>
                    <Field>
                      <Label>Email address</Label>
                      <Input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="w-full"
                        placeholder="jane@company.com"
                      />
                    </Field>
                  </FieldGroup>
                </Fieldset>

                <Button 
                  type="submit" 
                  color="sprout" 
                  className="w-full py-2.5 text-base font-medium transition-all duration-200 hover:opacity-90"
                >
                  Send reset instructions
                </Button>
              </form>
            </div>

            <div className="mt-6 text-center">
              <Button
                plain
                onClick={() => setIsPasswordReset(false)}
                className="text-sm text-zinc-600 hover:text-zinc-900 dark:text-zinc-400 dark:hover:text-white transition-colors"
              >
                Back to sign in
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="flex min-h-screen bg-gradient-to-br from-green-50 to-gray-100 dark:from-zinc-900 dark:to-zinc-800">
      <div className="m-auto w-full max-w-lg p-8">
        <div className="transform transition-all duration-200 ease-in-out">
          <div className="mb-8 text-center">
            <img src={logoIcon} alt="Sprout Logo" className="mx-auto h-12 w-auto" />
            <Heading level={1} className="mt-6 text-4xl font-bold tracking-tight">
              {isLogin ? 'Welcome back' : 'Create your account'}
            </Heading>
            <Text className="mt-2 text-lg text-zinc-600 dark:text-zinc-400">
              {isLogin 
                ? 'Enter your details to access your account' 
                : 'Start your 30-day free trial, no credit card required'}
            </Text>
          </div>

          <div className="rounded-xl bg-white/80 backdrop-blur-sm dark:bg-zinc-900/80 p-8 shadow-xl ring-1 ring-zinc-950/5 dark:ring-white/10">
            <form onSubmit={handleSubmit} className="space-y-6">
              {error && (
                <div className="animate-fadeIn rounded-lg bg-red-50 dark:bg-red-900/30 p-4 mb-6">
                  <div className="flex items-center">
                    <XCircleIcon className="h-5 w-5 text-red-400" />
                    <p className="ml-3 text-sm text-red-800 dark:text-red-200">{error}</p>
                  </div>
                </div>
              )}

              <Fieldset>
                <FieldGroup>
                  {!isLogin && (
                    <div className="grid grid-cols-2 gap-4">
                      <Field>
                        <Label>First Name</Label>
                        <Input
                          type="text"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          required
                          className="w-full"
                          placeholder="Jane"
                        />
                      </Field>
                      <Field>
                        <Label>Last Name</Label>
                        <Input
                          type="text"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          required
                          className="w-full"
                          placeholder="Doe"
                        />
                      </Field>
                    </div>
                  )}
                  
                  <Field>
                    <Label>Email address</Label>
                    <Input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      className="w-full"
                      placeholder="jane@company.com"
                    />
                  </Field>
                  
                  <Field>
                    <Label>Password</Label>
                    <Input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      className="w-full"
                      placeholder="••••••••"
                    />
                    {!isLogin && (
                      <Text className="mt-2 text-xs text-zinc-500">
                        Must be at least 8 characters long
                      </Text>
                    )}
                  </Field>
                </FieldGroup>
              </Fieldset>

              <Button 
                type="submit" 
                color="sprout" 
                className="w-full py-2.5 text-base font-medium transition-all duration-200 hover:opacity-90"
              >
                {isLogin ? 'Sign in to your account' : 'Create account'}
              </Button>
              <Button 
                plain
                type="button"
                onClick={() => setIsPasswordReset(true)}
                className="mt-2 text-sm text-zinc-600 hover:text-zinc-900 dark:text-zinc-400 dark:hover:text-white transition-colors"
              >
                Forgot your password?
              </Button>
            </form>
          </div>

          <div className="mt-6 text-center">
            <Button
              plain
              onClick={() => setIsLogin(!isLogin)}
              className="text-sm text-zinc-600 hover:text-zinc-900 dark:text-zinc-400 dark:hover:text-white transition-colors"
            >
              {isLogin 
                ? 'New to Sprout? Create an account' 
                : 'Already have an account? Sign in'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuthenticationPage
