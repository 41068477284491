import { useEffect, useCallback, useState } from "react"
import { useUser } from "@/hooks/useUser"
import { Button } from "@/components/ui/button"
import { toast } from "@/hooks/use-toast"
import { gql, useMutation } from "@apollo/client"
import { usePostHog } from "posthog-js/react"
import { Building2, Mail, Folders, FileText, DollarSign, CreditCard } from "lucide-react"
import debounce from "lodash/debounce"
import { Separator } from "@/components/ui/separator"
import { SettingsPaperwork } from "./SettingsPaperwork"
import { SettingsGeneral } from "./SettingsGeneral"
import { SettingsEmails } from "./SettingsEmails"
import {cn} from "@/lib/utils"
import { SettingsPrograms } from "./SettingsPrograms"
import { useLocation, useNavigate } from "wouter"
import { SettingsFees } from "./SettingsFees"
import { SettingsStripe } from "./SettingsStripe"

const UPDATE_ORGANIZATION_SETTINGS = gql`
  mutation ModifyOrganization($input: ModifyOrganizationInput!) {
    modifyOrganization(input: $input) {
      organization {
        uuid
      }
    }
  }
`

const generateTimeOptions = () => {
  const times = []
  for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 60; j += 30) {
      const hour = i % 12 || 12
      const period = i < 12 ? "AM" : "PM"
      const minute = j.toString().padStart(2, "0")
      const value = `${i.toString().padStart(2, "0")}:${minute}`
      const label = `${hour}:${minute} ${period}`
      times.push({ value, label })
    }
  }
  return times
}

const formatPhoneNumber = (value: string) => {
  // Remove all non-numeric characters
  const phoneNumber = value.replace(/\D/g, '')
  
  // Format the number
  if (phoneNumber.length <= 3) {
    return phoneNumber
  } else if (phoneNumber.length <= 6) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
  } else {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
  }
}

export default function Settings() {
  const { user, refetch } = useUser()
  const [location, navigate] = useLocation()
  const [businessAddress, setBusinessAddress] = useState(
    user?.organization?.address ? JSON.parse(user?.organization?.address) : null
  )
  const [orgForm, setOrgForm] = useState({
    id: user?.organization?.uuid,
    timezone: user?.organization?.timezone || "America/New_York",
    name: user?.organization?.name || "",
    email: user?.organization?.email || "",
    phoneNumber: user?.organization?.phoneNumber || "",
    address: user?.organization?.address || null,
    logo: user?.organization?.logo || null,
    sendInvoiceDueSoonNotifications:
      user?.organization?.sendInvoiceDueSoonNotifications ?? true,
    sendInvoiceOverdueNotifications:
      user?.organization?.sendInvoiceOverdueNotifications ?? true,
    sendPaymentReceivedNotifications:
      user?.organization?.sendPaymentReceivedNotifications ?? true,
    sendPaymentFailedNotifications:
      user?.organization?.sendPaymentFailedNotifications ?? true,
    openingTime: user?.organization?.openingTime || "09:00",
    closingTime: user?.organization?.closingTime || "17:00",
    state: user?.organization?.state || "",
    customInvoiceFooter: user?.organization?.customInvoiceFooter || "",
    customReceiptFooter: user?.organization?.customReceiptFooter || "",
    maxStudents: user?.organization?.maxStudents || null,
    lateSignOutFeeAmount: user?.organization?.lateSignOutFeeAmount || null,
    lateSignOutFeeIntervalMinutes:
      user?.organization?.lateSignOutFeeIntervalMinutes || null,
    lateSignOutFeeMinutesGracePeriod:
      user?.organization?.lateSignOutFeeMinutesGracePeriod || null,
    autoAddLateSignOutFee: user?.organization?.autoAddLateSignOutFee || false,
  })
  const [logoImage, setLogoImage] = useState()
  const [updateOrganizationSettings] = useMutation(UPDATE_ORGANIZATION_SETTINGS)
  const posthog = usePostHog()

  useEffect(() => {
    posthog.capture("settings_page_viewed")
  }, [posthog])

  useEffect(() => {
    if (location === "/settings") {
      navigate("/settings/general")
    }
  }, [location, navigate])

  const SETTINGS_TABS = [
    { name: "General", href: "/settings/general", icon: Building2 },
    { name: "Programs", href: "/settings/programs", icon: Folders },
    { name: "Paperwork", href: "/settings/paperwork", icon: FileText },
    { name: "Payments", href: "/settings/stripe", icon: CreditCard },
    { name: "Fees", href: "/settings/fees", icon: DollarSign },
    { name: "Emails", href: "/settings/emails", icon: Mail },
  ]

  const getSettingsTitle = (path: string) => {
    const tab = SETTINGS_TABS.find(tab => tab.href === path)
    return tab ? `${tab.name}` : 'Settings'
  }

  useEffect(() => {
    const title = getSettingsTitle(location)
    document.title = `${title} - Sprout`
  }, [location])

  const debouncedSave = useCallback(
    debounce(async (formData) => {
      try {
        const mutationInput = {
          ...formData,
          address: typeof formData.address === 'object' ? 
            JSON.stringify(formData.address) : 
            formData.address,
          logo: formData.logo instanceof File ? formData.logo : null,
        }

        await updateOrganizationSettings({
          variables: {
            input: mutationInput,
          },
        })
        toast({
          title: "Changes saved",
          description: "Your settings have been updated successfully.",
        })
        refetch()
      } catch (error) {
        toast({
          title: "Error",
          description: `Failed to save changes: ${error.message}`,
          variant: "destructive",
        })
      }
    }, 1000),
    [updateOrganizationSettings, refetch]
  )

  const handleOrgChange = (e) => {
    const { name, value } = e.target
    let formattedValue = value

    if (name === 'phoneNumber') {
      formattedValue = formatPhoneNumber(value)
    }

    const newFormData = {
      ...orgForm,
      [name]: name === "maxStudents" ||
        name === "lateSignOutFeeAmount" ||
        name === "lateSignOutFeeIntervalMinutes" ||
        name === "lateSignOutFeeMinutesGracePeriod"
        ? parseInt(value)
        : formattedValue,
    }
    setOrgForm(newFormData)
    debouncedSave(newFormData)
  }

  const handleSwitchChange = (name, checked) => {
    const newFormData = {
      ...orgForm,
      [name]: checked,
    }
    setOrgForm(newFormData)
    debouncedSave(newFormData)
  }

  const handleBusinessAddressChange = (address) => {
    setBusinessAddress(address)
    const newFormData = {
      ...orgForm,
      address // This will be the new address object
    }
    setOrgForm(newFormData)
    debouncedSave(newFormData)
  }

  const handleLogoImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setLogoImage(file);
      const newFormData = {
        ...orgForm,
        logo: file
      };
      setOrgForm(newFormData);
      debouncedSave(newFormData);
    }
  };

  const handleStripeSetup = () => {
    window.open("https://dashboard.stripe.com/login", "_blank")
  }

  return (
    <div className="hidden space-y-6 p-10 pb-16 md:block">
      <div className="space-y-0.5">
        <h2 className="text-2xl font-bold tracking-tight">Settings</h2>
        <p className="text-muted-foreground">
          Manage your organization settings and preferences.
        </p>
      </div>
      <Separator className="my-6" />

      <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
        <aside className="-mx-4 lg:w-1/5">
          <nav className="flex space-x-2 lg:flex-col lg:space-x-0 lg:space-y-1">
            {SETTINGS_TABS.map((tab) => {
              const Icon = tab.icon
              return (
                <Button
                  key={tab.href}
                  variant={location === tab.href ? "secondary" : "ghost"}
                  className={cn(
                    "justify-start w-full",
                    location === tab.href && "bg-secondary"
                  )}
                  onClick={() => navigate(tab.href)}
                >
                  <Icon className="mr-2 h-4 w-4" />
                  {tab.name}
                </Button>
              )
            })}
          </nav>
        </aside>
        <div className="flex-1">
          {location === "/settings/general" && (
            <SettingsGeneral
              orgForm={orgForm}
              handleOrgChange={handleOrgChange}
              handleBusinessAddressChange={handleBusinessAddressChange}
              handleLogoImageChange={handleLogoImageChange}
              businessAddress={businessAddress}
              logoImage={logoImage}
              user={user}
              generateTimeOptions={generateTimeOptions}
            />
          )}

          {location === "/settings/emails" && (
            <SettingsEmails
              orgForm={orgForm}
              handleSwitchChange={handleSwitchChange}
            />
          )}

          {location === "/settings/programs" && <SettingsPrograms />}

          {location === "/settings/paperwork" && <SettingsPaperwork />}

          {location === "/settings/fees" && (
            <SettingsFees
              orgForm={orgForm}
              handleOrgChange={handleOrgChange}
              handleSwitchChange={handleSwitchChange}
            />
          )}

          {location === "/settings/stripe" && (
            <SettingsStripe
              user={user}
              handleStripeSetup={handleStripeSetup}
            />
          )}
        </div>
      </div>
    </div>
  )
}
