import { cn } from "@/lib/utils"
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'

export default function GoogleSearch({ onChange, value, className, disabled }) {

  const selectStyle = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '40px', // h-10
      width: '100%',
      backgroundColor: 'hsl(var(--background))',
      borderRadius: 'calc(var(--radius) - 2px)',
      borderColor: state.isFocused 
        ? 'hsl(var(--ring))' 
        : 'hsl(var(--input))',
      borderWidth: '1px',
      boxShadow: state.isFocused 
        ? '0 0 0 2px hsl(var(--background)), 0 0 0 4px hsl(var(--ring))' 
        : 'none',
      '&:hover': {
        borderColor: 'hsl(var(--ring))'
      },
      padding: '0',
      fontSize: '0.875rem', // text-sm
    }),
    input: (provided) => ({
      ...provided,
      color: 'hsl(var(--foreground))',
      margin: '0',
      padding: '0',
      fontSize: '0.875rem', // text-sm
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'hsl(var(--muted-foreground))',
      fontSize: '0.875rem', // text-sm
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'hsl(var(--foreground))',
      fontSize: '0.875rem', // text-sm
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: 'hsl(var(--background))',
      border: '1px solid hsl(var(--input))',
      borderRadius: 'var(--radius)',
      boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
      fontSize: '0.875rem', // text-sm
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused 
        ? 'hsl(var(--accent))' 
        : 'transparent',
      color: state.isFocused 
        ? 'hsl(var(--accent-foreground))' 
        : 'hsl(var(--foreground))',
      cursor: 'pointer',
      fontSize: '0.875rem', // text-sm
      '&:active': {
        backgroundColor: 'hsl(var(--accent))',
      },
    }),
  }

  return (
    <div className={cn(
      "relative w-full",
      className
    )}>
      <GooglePlacesAutocomplete
        apiKey="AIzaSyDvna8dCqvZyuf6Sbre9G5-esB8SuJlqBk"
        selectProps={{
          styles: selectStyle,
          placeholder: 'Search for an address...',
          onChange: onChange,
          value: value,
          isDisabled: disabled,
          defaultInputValue: value?.label,
          defaultValue: value,
          className: "w-full",
          classNamePrefix: "gp",
        }}
      />
    </div>
  )
}