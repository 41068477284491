import { useState, useEffect, useMemo } from "react";
import { useStripe, useElements, PaymentElement, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { stripeSecretKey, API_BASE_URL } from "../../config";
import { useUser } from "../../hooks/useUser";
import { Dialog, DialogTitle, DialogBody, DialogActions } from "../../components/catalyst/dialog";
import { Button } from "../../components/catalyst/button";
import { PlusIcon } from "@heroicons/react/24/outline";
export default function CreatePaymentMethod({ familyId, buttonText }) {
  const [clientSecret, setClientSecret] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useUser();

  const stripePromise = useMemo(() => loadStripe(stripeSecretKey, {
    stripeAccount: user.organization.stripeAccountId,
  }), [user.organization.stripeAccountId]);

  useEffect(() => {
    if (!isOpen) return;
    const accessToken = localStorage.getItem('accessToken');
    if (!familyId) return;
    if (!accessToken) return;
    fetch(`${API_BASE_URL}/api/v1/stripe/create_setup_intent`, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify({ family: familyId })
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.token) {
          setClientSecret(data.token);
        }
      })
      .catch((error) => console.error('Error creating setup intent:', error));
  }, [familyId, isOpen]);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setClientSecret(null); // Reset clientSecret when closing the modal
  };

  const PaymentMethodForm = () => {
    const [submitting, setSubmitting] = useState(false);
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async () => {
      if (!stripe || !elements) return;

      setSubmitting(true);
      try {
        const { error } = await stripe.confirmSetup({
          elements,
          confirmParams: {
            return_url: window.location.href,
          },
        });

        if (error) {
          console.error(error.message);
        } else {
          handleClose(); // Use handleClose to close the modal
        }
      } catch (error) {
        console.error('Payment setup failed:', error);
      } finally {
        setSubmitting(false);
      }
    };

    return (
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>Create a new payment method</DialogTitle>
        <DialogBody>
          <PaymentElement />
        </DialogBody>
        <DialogActions>
          <Button outline onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={!stripe || !elements || submitting}
            color="sprout"
          >
            {submitting ? "Loading..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Button outline onClick={handleOpen}><PlusIcon className="h-4 w-4" />{buttonText}</Button>
      {clientSecret && isOpen && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <PaymentMethodForm />
        </Elements>
      )}
    </>
  );
}
