import { useState, useEffect } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import Card from "../catalyst/card";
import { Text } from "../catalyst/text";
import {
  DescriptionList,
  DescriptionTerm,
  DescriptionDetails,
} from "../catalyst/description-list";
import { Button } from "../catalyst/button";
import { useUser } from "../../hooks/useUser";
import {
  Dialog,
  DialogTitle,
  DialogDescription,
  DialogBody,
  DialogActions,
} from "../catalyst/dialog";
import { DocusealForm } from "@docuseal/react";
import toast from "react-hot-toast";
import { DocumentArrowDownIcon } from "@heroicons/react/16/solid";

const GET_STUDENT_PAPERWORK = gql`
  query StudentPaperwork($student: ID!) {
    enrollmentByStudent(student: $student) {
      programpaperworkSet {
        uuid
        completed
        dueDate
        paperwork {
          uuid
          name
          slug
          templateId
        }
      }
    }
  }
`;

const UPDATE_PAPERWORK = gql`
  mutation UpdatePaperwork($input: UpdateProgramPaperworkInput!) {
    updateProgramPaperwork(input: $input) {
      success
    }
  }
`;


const DOWNLOAD_PAPERWORK = gql`
  mutation DownloadPaperwork($input: DownloadPaperworkInput!) {
    downloadPaperwork(input: $input) {
      url
    }
  }
`;

const formatDate = (dateString) => {
  if (!dateString) return "";
  const [year, month, day] = dateString.split("-").map(Number);
  return new Date(year, month - 1, day).toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
};

const PaperworkCardSkeleton = () => (
  <Card className="mb-4">
    <Card.Header>
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-3">
          <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-gray-100 animate-pulse" />
          <div>
            <div className="h-6 w-48 bg-gray-200 rounded animate-pulse" />
            <div className="h-4 w-32 bg-gray-200 rounded mt-1 animate-pulse" />
          </div>
        </div>
        <div className="h-6 w-24 bg-gray-200 rounded-full animate-pulse" />
      </div>
      <div className="mt-4 w-full bg-gray-100 rounded-full h-2 animate-pulse" />
    </Card.Header>
    <Card.Body>
      <div className="space-y-4">
        {[...Array(3)].map((_, i) => (
          <div key={i} className="border rounded-lg p-4">
            <div className="flex items-center justify-between animate-pulse">
              <div className="flex items-center space-x-3">
                <div className="h-6 w-6 bg-gray-200 rounded-full" />
                <div>
                  <div className="h-5 w-48 bg-gray-200 rounded" />
                  <div className="h-4 w-32 bg-gray-200 rounded mt-1" />
                </div>
              </div>
              <div className="flex items-center space-x-4">
                <div className="h-8 w-28 bg-gray-200 rounded" />
                <div className="h-6 w-24 bg-gray-200 rounded-full" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </Card.Body>
  </Card>
);

const SignDocumentDialog = ({ isOpen, setIsOpen, document, refetch }) => {
    const { user } = useUser();
    const [updatePaperwork] = useMutation(UPDATE_PAPERWORK);

  const handleSubmit = async (data) => {
    toast.loading("Saving changes...", { id: "sign-document" });
    console.log(document);
    console.log(data);
    if (data.status === "completed") {
        try {
            await updatePaperwork({
                variables: {
                    input: {
                        id: document.uuid,
                        completed: true,
                        submissionId: String(data.submission_id)
                    }
                }
            });
            toast.dismiss("sign-document");
            toast.success("Signature submitted successfully");
            setIsOpen(false);
            refetch();
        } catch (error) {
            setIsOpen(false);
            toast.dismiss("sign-document");
            toast.error(`Error submitting signature: ${error.message}`);

        }
    } else {
      toast.dismiss("sign-document");
      toast.error("Signature submission failed");
      setIsOpen(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)} size="4xl">
      <div className="flex justify-between items-start">
        <div>
          <DialogTitle>Sign Document: {document?.name}</DialogTitle>
          <DialogDescription>
            Please review and sign the document below
          </DialogDescription>
        </div>
        <Button color="white" onClick={() => setIsOpen(false)}>
          <svg
            className="h-5 w-5 text-gray-500"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </Button>
      </div>

      <DialogBody>
        <div className="bg-gray-50 rounded-lg p-4 min-h-[60vh]">
          {document?.slug ? (
            <DocusealForm
              src={`https://docuseal.com/d/${document.slug}`}
              email={user.email}
              externalId={document.uuid}
              onComplete={(data) => handleSubmit(data)}
              onError={(error) => {
                toast.error(error.message);
              }}
              onLoading={(loading) => {
                toast.loading(loading ? "Loading document..." : "Document loaded");
              }}
              goToLast={true}
              sendCopyEmail={false}
              allowDownload={false}
              allowToResubmit={false}
              withTitle={false}
            />
          ) : (
            <div className="flex items-center justify-center h-full">
              <Text className="text-gray-500">
                Unable to load document. Template ID is missing.
              </Text>
            </div>
          )}
        </div>
      </DialogBody>

      <DialogActions>
        <Button color="white" onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function StudentPaperwork({ student }) {
  const { data, loading, refetch } = useQuery(GET_STUDENT_PAPERWORK, {
    variables: { student: student.uuid },
  });
  const { user } = useUser();
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isSignDialogOpen, setIsSignDialogOpen] = useState(false);
  const [downloadPaperwork] = useMutation(DOWNLOAD_PAPERWORK);
  const [enrollments, setEnrollments] = useState([]);

  const handleDownload = async (document) => {
    try {
        toast.loading("Downloading document...", { id: "download-document" });
      const response = await downloadPaperwork({ variables: { input: { id: document.uuid } } });
      if (response) {
        toast.dismiss("download-document");
        window.open(response.data.downloadPaperwork.url, '_blank');
      }
    } catch (error) {
      toast.dismiss("download-document");
      toast.error(`Error downloading document: ${error.message}`);
    }
  };

  useEffect(() => {
    refetch();
  }, [refetch]);


  useEffect(() => {
    console.log(enrollments);
  }, [enrollments]);

  useEffect(() => {
    if (data?.enrollmentByStudent) {
      setEnrollments(data.enrollmentByStudent);
    }
  }, [data]);

  if (loading) {
    return (
      <div className="space-y-6">
        <PaperworkCardSkeleton />
      </div>
    );
  }
  return (
    <div className="space-y-6">
      {enrollments.map((enrollment, index) => {
        const paperworkSet = enrollment?.programpaperworkSet || [];
        
        const completedCount = paperworkSet.filter((doc) => doc.completed).length;
        const totalCount = paperworkSet.length;
        const completionPercentage = totalCount === 0 
          ? 0 
          : Math.round((completedCount / totalCount) * 100);

        return (
          <Card key={index}>
            <Card.Header>
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-3">
                  <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-gray-50">
                    <svg
                      className="h-6 w-6 text-gray-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                      />
                    </svg>
                  </div>
                  <div>
                    <Text className="font-medium text-xl">
                      Required Signatures
                    </Text>
                    <Text className="text-sm text-gray-500">
                      {completedCount} of {totalCount} completed
                    </Text>
                  </div>
                </div>
                <span
                  className={`inline-flex items-center rounded-full px-3 py-1 text-sm font-medium ${
                    completionPercentage === 100
                      ? "bg-green-100 text-green-700"
                      : completionPercentage > 50
                      ? "bg-blue-100 text-blue-700"
                      : "bg-yellow-100 text-yellow-700"
                  }`}
                >
                  {completionPercentage}% Complete
                </span>
              </div>
              <div className="mt-4 w-full bg-gray-200 rounded-full h-2">
                <div
                  className={`h-2 rounded-full ${
                    completionPercentage === 100
                      ? "bg-green-500"
                      : completionPercentage > 50
                      ? "bg-blue-500"
                      : "bg-yellow-500"
                  }`}
                  style={{ width: `${completionPercentage}%` }}
                />
              </div>
            </Card.Header>
            <Card.Body>
              <div className="space-y-4">
                {paperworkSet.length > 0 ? (
                  paperworkSet.map((doc) => {
                    const isPastDue = new Date(doc.dueDate) < new Date();
                    const isExpired = new Date(doc.expires) < new Date();
                    return (
                      <div
                        key={doc.uuid}
                        className={`border rounded-lg transition-colors ${
                          doc.completed
                            ? "bg-green-50/50 border-green-200"
                            : isPastDue
                            ? "bg-rose-50/50 border-rose-200"
                            : "hover:bg-gray-50 border-gray-200"
                        }`}
                      >
                        <div className="p-4">
                          <div className="flex items-center justify-between">
                            <div className="flex items-center space-x-3">
                              {doc.completed ? (
                                <svg
                                  className="h-6 w-6 text-green-500"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                              ) : isPastDue ? (
                                <svg
                                  className="h-6 w-6 text-rose-500"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  className="h-6 w-6 text-yellow-500"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                                  />
                                </svg>
                              )}
                              <div>
                                <Text className="font-medium">{doc.paperwork.name}</Text>
                                <Text className="text-sm text-gray-500">
                                  {doc.completed
                                    ? "Signature has been submitted"
                                    : "Signature needs to be submitted"}
                                </Text>
                                <Text className={`text-sm ${(!doc.completed && isPastDue) ? "text-rose-600" : "text-gray-500"}`}>
                                  Due Date: {formatDate(doc.dueDate)}
                                </Text>
                              </div>
                            </div>
                            <div className="flex items-center space-x-4">
                              {user.role === "GU" && !doc.completed && (
                                <Button
                                  color="sprout"
                                  size="sm"
                                  onClick={() => {
                                    setSelectedDocument({
                                      uuid: doc.uuid,
                                      name: doc.paperwork.name,
                                      slug: doc.paperwork.slug,
                                      templateId: doc.paperwork.templateId
                                    });
                                    setIsSignDialogOpen(true);
                                  }}
                                >
                                  Sign Document
                                </Button>
                              )}
                              <span
                                className={`inline-flex items-center rounded-full px-3 py-1 text-xs font-medium ${
                                  doc.completed
                                    ? "bg-green-100 text-green-700"
                                    : isPastDue
                                    ? "bg-rose-100 text-rose-700"
                                    : "bg-yellow-100 text-yellow-700"
                                }`}
                              >
                                {doc.completed ? "Signed" : "Signature Required"}
                              </span>
                              {doc.completed && (
                                <Button
                                  outline
                                  onClick={() => handleDownload(doc)}
                                >
                                  <DocumentArrowDownIcon className="h-4 w-4" />
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <Text className="text-gray-500 text-center py-4">
                    No paperwork found
                  </Text>
                )}
              </div>
            </Card.Body>
          </Card>
        );
      })}

      {enrollments.length === 0 && (
        <Card>
          <Card.Body>
            <div className="text-center py-12">
              <div className="mx-auto h-12 w-12 text-gray-400">
                <svg
                  className="h-12 w-12"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                  />
                </svg>
              </div>
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                No required signatures
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                There are no signatures required at this time.
              </p>
            </div>
          </Card.Body>
        </Card>
      )}

      <SignDocumentDialog
        isOpen={isSignDialogOpen}
        setIsOpen={setIsSignDialogOpen}
        document={selectedDocument}
        refetch={refetch}
      />
    </div>
  );
}

export default StudentPaperwork;
