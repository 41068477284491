import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Separator } from "@/components/ui/separator"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Camera } from "lucide-react"
import { cn } from "@/lib/utils"
import GoogleSearch from "@/components/address-input"
import { Switch } from "@/components/ui/switch"

interface SettingsGeneralProps {
  orgForm: {
    name: string;
    email: string;
    phoneNumber: string;
    timezone: string;
    openingTime: string;
    closingTime: string;
    maxStudents: number | null;
    state: string;
    customInvoiceFooter: string;
    customReceiptFooter: string;
  };
  handleOrgChange: (e: any) => void;
  handleBusinessAddressChange: (address: any) => void;
  handleLogoImageChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSwitchChange: (name: string, checked: boolean) => void;
  businessAddress: any;
  logoImage: any;
  user: any;
  generateTimeOptions: () => { value: string; label: string }[];
}

const US_STATES = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
];

const US_TIMEZONES = [
  { value: "America/New_York", label: "Eastern Time (ET)" },
  { value: "America/Chicago", label: "Central Time (CT)" },
  { value: "America/Denver", label: "Mountain Time (MT)" },
  { value: "America/Phoenix", label: "Mountain Time - Arizona (MT)" },
  { value: "America/Los_Angeles", label: "Pacific Time (PT)" },
  { value: "America/Anchorage", label: "Alaska Time (AKT)" },
  { value: "Pacific/Honolulu", label: "Hawaii-Aleutian Time (HST)" },
] as const;

export function SettingsGeneral({
  orgForm,
  handleOrgChange,
  handleBusinessAddressChange,
  handleLogoImageChange,
  handleSwitchChange,
  businessAddress,
  logoImage,
  user,
  generateTimeOptions,
}: SettingsGeneralProps) {
  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">General Information</h3>
        <p className="text-sm text-muted-foreground">
          Update your organization's basic information.
        </p>
      </div>
      <Separator />
      <div className="space-y-6">
        <div className="grid gap-5">
          <div className="space-y-2">
            <Label htmlFor="name">Organization Name</Label>
            <Input
              id="name"
              name="name"
              value={orgForm.name}
              onChange={handleOrgChange}
              placeholder="Enter organization name"
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="email">Email Address</Label>
            <Input
              id="email"
              name="email"
              type="email"
              value={orgForm.email}
              onChange={handleOrgChange}
              placeholder="Enter email address"
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="phoneNumber">Phone Number</Label>
            <Input
              id="phoneNumber"
              name="phoneNumber"
              value={orgForm.phoneNumber}
              onChange={handleOrgChange}
              placeholder="(555) 555-5555"
              maxLength={14}
            />
          </div>

          <div className="space-y-2">
            <Label>Business Address</Label>
            <GoogleSearch
              name="address"
              onChange={handleBusinessAddressChange}
              value={businessAddress}
            />
          </div>

          <div className="space-y-2">
            <Label>Business Hours</Label>
            <div className="flex items-center gap-3">
              <Select
                name="openingTime"
                value={orgForm.openingTime}
                onValueChange={(value) =>
                  handleOrgChange({ target: { name: "openingTime", value } })
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder="Opening Time" />
                </SelectTrigger>
                <SelectContent>
                  {generateTimeOptions().map((time) => (
                    <SelectItem key={time.value} value={time.value}>
                      {time.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <span className="text-muted-foreground">to</span>
              <Select
                name="closingTime"
                value={orgForm.closingTime}
                onValueChange={(value) =>
                  handleOrgChange({ target: { name: "closingTime", value } })
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder="Closing Time" />
                </SelectTrigger>
                <SelectContent>
                  {generateTimeOptions().map((time) => (
                    <SelectItem key={time.value} value={time.value}>
                      {time.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>
          <div className="space-y-2">
            <Label htmlFor="maxStudents">Maximum Students</Label>
            <Input
              id="maxStudents"
              name="maxStudents"
              type="number"
              value={orgForm.maxStudents || ''}
              onChange={handleOrgChange}
              placeholder="Enter maximum number of students"
            />
            <p className="text-sm text-muted-foreground">
              Set the maximum number of students your organization can accommodate
            </p>
          </div>

          <div className="space-y-2">
            <Label htmlFor="state">State</Label>
            <Select
              name="state"
              value={orgForm.state}
              onValueChange={(value) =>
                handleOrgChange({ target: { name: "state", value } })
              }
            >
              <SelectTrigger>
                <SelectValue placeholder="Select a state" />
              </SelectTrigger>
              <SelectContent>
                {US_STATES.map((state) => (
                  <SelectItem key={state.value} value={state.value}>
                    {state.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="space-y-2">
            <Label htmlFor="customInvoiceFooter">Invoice Footer</Label>
            <Input
              id="customInvoiceFooter"
              name="customInvoiceFooter"
              value={orgForm.customInvoiceFooter}
              onChange={handleOrgChange}
              placeholder="Enter custom text to appear at the bottom of invoices..."
              multiline
              rows={3}
            />
            <p className="text-sm text-muted-foreground">
              This text will appear at the bottom of all invoices sent to customers
            </p>
          </div>

          <div className="space-y-2">
            <Label htmlFor="customReceiptFooter">Receipt Footer</Label>
            <Input
              id="customReceiptFooter"
              name="customReceiptFooter"
              value={orgForm.customReceiptFooter}
              onChange={handleOrgChange}
              placeholder="Enter custom text to appear at the bottom of receipts..."
              multiline
              rows={3}
            />
            <p className="text-sm text-muted-foreground">
              This text will appear at the bottom of all receipts sent to customers
            </p>
          </div>

          <div className="space-y-2">
            <Label htmlFor="timezone">Time Zone</Label>
            <Select
              name="timezone"
              value={orgForm.timezone}
              onValueChange={(value) =>
                handleOrgChange({ target: { name: "timezone", value } })
              }
            >
              <SelectTrigger>
                <SelectValue placeholder="Select a time zone" />
              </SelectTrigger>
              <SelectContent>
                {US_TIMEZONES.map((timezone) => (
                  <SelectItem key={timezone.value} value={timezone.value}>
                    {timezone.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <p className="text-sm text-muted-foreground">
              Current time in selected zone:{" "}
              <span className="font-medium">
                {new Date().toLocaleTimeString("en-US", {
                  timeZone: orgForm.timezone,
                })}
              </span>
            </p>
          </div>
          <div className="space-y-2">
            <Label>Logo</Label>
            <div className="flex items-center space-x-6 p-4 bg-muted rounded-lg border border-input">
              <div className="flex-shrink-0">
                {logoImage || user?.organization?.logoUrl ? (
                  <div className="relative w-32 h-20">
                    <img
                      src={
                        logoImage
                          ? URL.createObjectURL(logoImage)
                          : user?.organization?.logoUrl
                      }
                      alt="Organization logo"
                      className={cn(
                        "w-32 h-20 rounded-md object-contain",
                        "bg-white p-2",
                        "border border-input shadow-sm",
                        "transition-all duration-200"
                      )}
                    />
                  </div>
                ) : (
                  <div
                    className={cn(
                      "w-32 h-20 rounded-md",
                      "bg-white border border-input",
                      "flex items-center justify-center",
                      "transition-all duration-200"
                    )}
                  >
                    <Camera className="h-8 w-8 text-muted-foreground/50" />
                  </div>
                )}
              </div>
              <div className="space-y-1">
                <Button variant="outline" asChild className="relative">
                  <label htmlFor="logo-upload" className="cursor-pointer">
                    {logoImage || user?.organization?.logoUrl
                      ? "Change Logo"
                      : "Upload Logo"}
                  </label>
                </Button>
                <input
                  id="logo-upload"
                  name="logo"
                  type="file"
                  className="hidden"
                  onChange={handleLogoImageChange}
                  accept="image/*"
                />
                <p className="text-sm text-muted-foreground">
                  Recommended: 320x200px or larger, PNG or JPG
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
} 