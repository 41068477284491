import { Button } from '../../components/catalyst/button'
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../../components/catalyst/dialog'
import { Field, FieldGroup, Label, Description, ErrorMessage } from '../../components/catalyst/fieldset'
import { Input } from '../../components/catalyst/input'
import { useState, useEffect } from 'react'
import { useMutation, gql } from '@apollo/client'
import { TrashIcon, PlusIcon } from '@heroicons/react/24/outline'
import { Text } from '../../components/catalyst/text'
import { Alert, AlertActions, AlertDescription, AlertTitle } from '../../components/catalyst/alert'
import toast, { Toaster } from "react-hot-toast";

const CREATE_INVOICE = gql`
  mutation CreateInvoice($input: CreateInvoiceInput!) {
    createInvoice(input: $input) {
      invoice {
        id
        dueDate
        date
        lineItems {
          id
          description
          price
          quantity
        }
      }
    }
  }
`;

const emptyLineItem = {
  id: crypto.randomUUID(),
  description: '',
  quantity: 1,
  price: 0
};

export default function CreateInvoice({ student, refetch, ...props }) {
  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    return new Date(dateString).toISOString().split('T')[0];
  };

  const [isOpen, setIsOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [errors, setErrors] = useState({})
  const [showErrors, setShowErrors] = useState(false)
  const [dueDate, setDueDate] = useState(formatDateForInput(new Date()))
  const [date, setInvoiceDate] = useState(formatDateForInput(new Date()))
  const [lineItems, setLineItems] = useState([{ ...emptyLineItem }]);

  const [createInvoice, { loading }] = useMutation(CREATE_INVOICE)

  useEffect(() => {
    setLineItems([{ ...emptyLineItem }])
    setDueDate(formatDateForInput(new Date()))
    setInvoiceDate(formatDateForInput(new Date()))
  }, [student])

  const handleClose = () => {
    setIsOpen(false)
    setLineItems([{ ...emptyLineItem }])
    setDueDate(formatDateForInput(new Date()))
    setInvoiceDate(formatDateForInput(new Date()))
    setErrors({})
    setShowErrors(false)
  }

  const validateForm = () => {
    const newErrors = {}
    if (!dueDate) newErrors.dueDate = 'Due date is required'
    if (!date) newErrors.date = 'Invoice date is required'
    if (lineItems.length === 0) newErrors.lineItems = 'At least one line item is required'
    
    const lineItemErrors = []
    lineItems.forEach((item, index) => {
      const itemErrors = {}
      if (!item.description) itemErrors.description = 'Description is required'
      if (!item.quantity || item.quantity <= 0) itemErrors.quantity = 'Valid quantity is required'
      if (!item.price || item.price <= 0) itemErrors.price = 'Valid price is required'
      if (Object.keys(itemErrors).length > 0) lineItemErrors[index] = itemErrors
    })
    if (lineItemErrors.length > 0) newErrors.lineItems = lineItemErrors

    return newErrors
  }

  const handleSave = async (e) => {
    e.preventDefault()
    
    const newErrors = validateForm()
    setErrors(newErrors)
    setShowErrors(true)

    if (Object.keys(newErrors).length === 0) {
      try {
        await createInvoice({
          variables: {
          input: {
            student: student.uuid,
            dueDate,
            date,
            lineItems: lineItems.map(item => ({
              id: item.id,
              description: item.description,
              quantity: parseFloat(item.quantity),
              price: parseFloat(item.price)
            }))
          }
        }
        })
        toast.success('Invoice created')
        refetch()
        handleClose()
      } catch (error) {
        toast.error('Failed to create invoice: ' + error.message)
      }
    }
  }

  const addLineItem = () => {
    setLineItems([...lineItems, { ...emptyLineItem, id: crypto.randomUUID() }]);
  };

  const removeLineItem = (id) => {
    const updatedItems = lineItems.filter(item => item.id !== id);
    if (updatedItems.length === 0) {
      toast.error("Cannot remove all line items. At least one is required.");
      return;
    }
    setLineItems(updatedItems);
  };

  const updateLineItem = (id, field, value) => {
    setLineItems(lineItems.map(item => 
      item.id === id ? { ...item, [field]: value } : item
    ));
  };

  const calculateTotal = () => {
    return lineItems.reduce((sum, item) => 
      sum + (parseFloat(item.quantity || 0) * parseFloat(item.price || 0)), 
    0)
  }

  return (
    <>
      <Button outline type="button" onClick={() => setIsOpen(true)}>
        {props.children}
      </Button>
      <Dialog size='2xl' open={isOpen} onClose={handleClose}>
        <DialogTitle>Create Invoice</DialogTitle>
        <DialogDescription>
          Create a new invoice for this student.
        </DialogDescription>
        <form onSubmit={handleSave}>
          <DialogBody>
            <FieldGroup>
              <div className="grid grid-cols-2 gap-4">
                <Field>
                  <Label>Invoice Date</Label>
                  <Input 
                    type="date" 
                    value={date} 
                    onChange={(e) => setInvoiceDate(e.target.value)}
                    invalid={showErrors && !!errors.date}
                  />
                  {showErrors && errors.date && <ErrorMessage>{errors.date}</ErrorMessage>}
                </Field>
                <Field>
                  <Label>Due Date</Label>
                  <Input 
                    type="date" 
                    value={dueDate} 
                    onChange={(e) => setDueDate(e.target.value)}
                    invalid={showErrors && !!errors.dueDate}
                  />
                  {showErrors && errors.dueDate && <ErrorMessage>{errors.dueDate}</ErrorMessage>}
                </Field>
              </div>

              <div className="space-y-4">
                <Field>
                  <Label>Line Items</Label>
                </Field>

                <div className="grid grid-cols-[1fr,80px,100px,40px] gap-4 items-start px-1">
                  <Text className="text-sm font-medium text-gray-500">Item Description</Text>
                  <Text className="text-sm font-medium text-gray-500">Qty</Text>
                  <Text className="text-sm font-medium text-gray-500">Price</Text>
                  <div className="w-8" />
                </div>

                <div className="space-y-3">
                  {lineItems.map((item) => (
                    <div key={item.id || crypto.randomUUID()} className="grid grid-cols-[1fr,80px,100px,40px] gap-4 items-start">
                      <Field>
                        <Input
                          placeholder="Description"
                          value={item.description}
                          onChange={(e) => updateLineItem(item.id, 'description', e.target.value)}
                          invalid={showErrors && errors.lineItems?.[item.id]?.description}
                        />
                        {showErrors && errors.lineItems?.[item.id]?.description && 
                          <ErrorMessage>{errors.lineItems[item.id].description}</ErrorMessage>
                        }
                      </Field>
                      <Field>
                        <Input
                          type="number"
                          placeholder="Qty"
                          value={item.quantity}
                          onChange={(e) => updateLineItem(item.id, 'quantity', e.target.value)}
                          invalid={showErrors && errors.lineItems?.[item.id]?.quantity}
                          className="w-20 text-center"
                        />
                      </Field>
                      <Field>
                        <Input
                          type="number"
                          step="0.01"
                          placeholder="Price"
                          value={item.price}
                          onChange={(e) => updateLineItem(item.id, 'price', e.target.value)}
                          invalid={showErrors && errors.lineItems?.[item.id]?.price}
                          className="w-24 text-center"
                        />
                      </Field>
                      <Button
                        type="button"
                        onClick={() => removeLineItem(item.id)}
                        color="red"
                        outline
                        size="sm"
                        className="mt-1"
                      >
                        <TrashIcon className="h-4 w-4" />
                      </Button>
                    </div>
                  ))}

                  <div className="pt-2">
                    <Button
                      type="button"
                      onClick={addLineItem}
                      outline
                      size="sm"
                      className="w-full"
                    >
                      <PlusIcon className="h-4 w-4 mr-1.5" />
                      Add Line Item
                    </Button>
                  </div>
                </div>
              </div>

              <div className="flex justify-end border-t pt-4">
                <div className="text-right">
                  <Field>
                    <Label>Total</Label>
                    <div className="text-xl font-semibold">
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD'
                      }).format(calculateTotal())}
                    </div>
                  </Field>
                </div>
              </div>
            </FieldGroup>
          </DialogBody>
          <DialogActions className="flex justify-end">
            <div className="flex gap-2">
              <Button plain onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" color="sprout" disabled={loading}>
                {loading ? 'Creating...' : 'Create Invoice'}
              </Button>
            </div>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}
