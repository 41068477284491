import { useState, useEffect } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";
import Card from "../catalyst/card";
import { Text } from "../catalyst/text";
import { Button } from "../catalyst/button";
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from "../catalyst/dropdown";
import {
  EllipsisVerticalIcon,
  PencilIcon,
  PlusIcon,
  ReceiptRefundIcon,
  TrashIcon,
  DocumentArrowDownIcon,
} from "@heroicons/react/24/outline";
import { Switch } from "../catalyst/switch";
import ModifyInvoice from "../../modals/ModifyInvoice/ModifyInvoice";
import CreateInvoice from "../../modals/CreateInvoice/CreateInvoice";
import CreatePaymentMethod from "../../modals/CreatePaymentMethodModal/CreatePaymentMethodModal";
import CreateRefund from "../../modals/CreateRefund/CreateRefundModal";
import VoidInvoice from "../../modals/VoidInvoice/VoidInvoice";
import DeleteInvoice from "../../modals/DeleteInvoice/DeleteInvoice";
import {
  DescriptionList,
  DescriptionTerm,
  DescriptionDetails,
} from "../catalyst/description-list";
import {
  CreditCardIcon,
  XCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import CreatePayment from "../../modals/CreatePayment/CreatePayment";
import {
  Pagination,
  PaginationList,
  PaginationNext,
  PaginationPage,
  PaginationPrevious,
} from "../catalyst/pagination";
import { useUser } from "../../hooks/useUser";
import { toast } from "react-hot-toast";
import { Alert, AlertTitle, AlertDescription, AlertActions } from "../catalyst/alert";

const GET_INVOICES = gql`
  query GetInvoices($studentId: ID!, $first: Int!, $after: String) {
    invoicesByStudent(student_Id: $studentId, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          uuid
          number
          dueDate
          date
          status
          paid
          total
          amountPaid
          voided
          lineItems {
            id
            description
            quantity
            price
          }
          payments {
            id
            date
            amount
            paymentMethodType
            paymentMethodName
            stripePaymentMethodId
            refunds {
              amount
            }
          }
          refunds {
            amount
            id
            date
            reason
          }
        }
      }
    }
  }
`;

const GET_FAMILY = gql`
  query GetFamily($studentId: ID!) {
    familyByStudent(student: $studentId) {
      id
      autoPayEnabled
      name
      stripePaymentMethodName
      stripePaymentMethodId
      stripeCustomerId
      uuid
    }
  }
`;

const UPDATE_FAMILY = gql`
  mutation UpdateFamily($familyId: ID!, $autoPayEnabled: Boolean!) {
    updateFamily(input: { id: $familyId, autoPayEnabled: $autoPayEnabled }) {
      family {
        id
        autoPayEnabled
      }
    }
  }
`;

const GENERATE_INVOICE_PDF = gql`
  mutation GenerateInvoicePdf($invoiceId: ID!) {
    generateInvoicePdf(invoiceId: $invoiceId) {
      pdfBase64
    }
  }
`;

const GENERATE_PAYMENT_RECEIPT_PDF = gql`
  mutation GeneratePaymentReceiptPdf($paymentId: ID!) {
    generatePaymentReceiptPdf(paymentId: $paymentId) {
      pdfBase64
    }
  }
`;

const PaymentMethodsSkeleton = () => {
  return (
    <Card>
      <Card.Header>
        <div className="h-6 w-40 bg-gray-200 rounded animate-pulse" />
      </Card.Header>
      <Card.Body>
        <div className="flex items-center justify-between">
          <div className="flex items-start gap-x-6">
            <div className="flex gap-x-4">
              <div className="h-12 w-12 bg-gray-200 rounded-lg" />
              <div className="space-y-2">
                <div className="h-5 w-48 bg-gray-200 rounded" />
                <div className="h-4 w-32 bg-gray-200 rounded" />
              </div>
            </div>
          </div>
          <div className="flex items-center gap-x-4">
            <div className="h-9 w-24 bg-gray-200 rounded" />
            <div className="h-6 w-20 bg-gray-200 rounded" />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

const InvoiceCardSkeleton = () => {
  return (
    <Card className="mb-4 animate-pulse">
      <Card.Header>
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-3">
            <div className="h-8 w-8 bg-gray-200 rounded" />
            <div>
              <div className="flex items-center gap-2">
                <div className="h-6 w-48 bg-gray-200 rounded" />
                <div className="h-5 w-20 bg-gray-200 rounded-full" />
              </div>
              <div className="h-4 w-32 bg-gray-200 rounded mt-1" />
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="text-right">
              <div className="h-4 w-16 bg-gray-200 rounded mb-1" />
              <div className="h-5 w-24 bg-gray-200 rounded" />
            </div>
            <div className="flex gap-2">
              <div className="h-8 w-8 bg-gray-200 rounded" />
              <div className="h-8 w-8 bg-gray-200 rounded" />
              <div className="h-8 w-8 bg-gray-200 rounded" />
            </div>
          </div>
        </div>
      </Card.Header>
    </Card>
  );
};

const DownloadAlert = ({ isOpen, setIsOpen, type, invoice, payment, onDownload }) => {
  return (
    <Alert open={isOpen} onClose={() => setIsOpen(false)}>
      <AlertTitle>Download {type === 'invoice' ? 'Invoice' : 'Receipt'}</AlertTitle>
      <AlertDescription>
        Are you sure you want to download this {type === 'invoice' ? 'invoice' : 'receipt'}?
      </AlertDescription>
      <AlertActions>
        <Button outline onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        <Button
          color="sprout"
          onClick={() => {
            onDownload();
            setIsOpen(false);
          }}
        >
          Download
        </Button>
      </AlertActions>
    </Alert>
  );
};

function StudentBilling({ student }) {
  const [invoices, setInvoices] = useState([]);
  const [family, setFamily] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [cursors, setCursors] = useState(['']);
  const invoicesPerPage = 5;
  const { user } = useUser();
  useEffect(() => {
    invoicesRefetch;
  }, []);

  const {
    data: invoicesData,
    loading: invoicesLoading,
    refetch: invoicesRefetch,
    fetchMore,
  } = useQuery(GET_INVOICES, {
    variables: { 
      studentId: student.uuid,
      first: invoicesPerPage,
      after: cursors[currentPage - 1]
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first'
  });

  const {
    data: familyData,
    loading: familyLoading,
    refetch: familyRefetch,
  } = useQuery(GET_FAMILY, {
    variables: { studentId: student.uuid },
  });

  const [updateFamily] = useMutation(UPDATE_FAMILY);
  const [generateInvoicePDF] = useMutation(GENERATE_INVOICE_PDF);
  const [generatePaymentReceiptPDF] = useMutation(GENERATE_PAYMENT_RECEIPT_PDF);

  useEffect(() => {
    if (invoicesData && !invoicesLoading) {
      setInvoices(
        invoicesData.invoicesByStudent.edges.map((edge) => edge.node)
      );
    }
  }, [invoicesData, invoicesLoading]);

  useEffect(() => {
    if (familyData && !familyLoading) {
      setFamily(familyData.familyByStudent);
    }
  }, [familyData, familyLoading]);

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString();
  };

  const handleAutoPayToggle = async (autoPayEnabled) => {
    if (family) {
      await updateFamily({
        variables: { familyId: family.uuid, autoPayEnabled },
      });
      familyRefetch();
    }
  };

  const handlePageChange = async (newPage) => {
    if (newPage > currentPage) {
      const result = await fetchMore({
        variables: {
          after: invoicesData.invoicesByStudent.pageInfo.endCursor,
        },
      });
      
      setCursors(prev => {
        const newCursors = [...prev];
        newCursors[newPage - 1] = invoicesData.invoicesByStudent.pageInfo.endCursor;
        return newCursors;
      });
    } else {
      await invoicesRefetch({
        studentId: student.uuid,
        first: invoicesPerPage,
        after: cursors[newPage - 1]
      });
    }
    setCurrentPage(newPage);
  };

  const downloadPDF = (base64PDF, filename) => {
    const link = document.createElement('a');
    link.href = `data:application/pdf;base64,${base64PDF}`;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadInvoice = async (invoice) => {
    toast.loading('Generating invoice...', { id: 'invoice-loading' });
    try {
      const { data } = await generateInvoicePDF({
        variables: { invoiceId: invoice.uuid }
      });
      downloadPDF(data.generateInvoicePdf.pdfBase64, `invoice-${invoice.number}.pdf`);
      toast.dismiss('invoice-loading');
    } catch (error) {
      toast.dismiss('invoice-loading');
      toast.error('Failed to generate invoice PDF' + error);
    }
  };

  const handleDownloadReceipt = async (paymentId) => {
    toast.loading('Generating receipt...', { id: 'receipt-loading' });
    try {
      const { data } = await generatePaymentReceiptPDF({
        variables: { paymentId }
      });
      downloadPDF(data.generatePaymentReceiptPdf.pdfBase64, `receipt-${paymentId}.pdf`);
      toast.dismiss('receipt-loading');
    } catch (error) {
      toast.dismiss('receipt-loading');
      toast.error('Failed to generate receipt PDF' + error);
    }
  };

  const InvoiceCard = ({ invoice }) => {
    const [isCreatePaymentOpen, setIsCreatePaymentOpen] = useState(false);
    const [isModifyInvoiceOpen, setIsModifyInvoiceOpen] = useState(false);
    const [isVoidInvoiceOpen, setIsVoidInvoiceOpen] = useState(false);
    const [isDeleteInvoiceOpen, setIsDeleteInvoiceOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isDownloadInvoiceOpen, setIsDownloadInvoiceOpen] = useState(false);
    const [isDownloadReceiptOpen, setIsDownloadReceiptOpen] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const totalRefunds =
      invoice.refunds?.reduce(
        (sum, refund) => Number(sum) + Number(refund.amount || 0),
        0
      ) || 0;
    
    const totalPayments = invoice.payments?.reduce(
      (sum, payment) => {
        const paymentRefunds = payment.refunds?.reduce(
          (refundSum, refund) => Number(refundSum) + Number(refund.amount || 0),
          0
        ) || 0;
        return Number(sum) + (Number(payment.amount) - paymentRefunds);
      },
      0
    ) || 0;
    
    const remainingBalance = Number(invoice.total) - totalPayments;
    const isPaid = remainingBalance <= 0;

    console.log(totalRefunds)

    const getStatusLabel = () => {
      if (invoice.voided) return null;
      if (invoice.status === "PAID") {
        return (
          <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700">
            Paid
          </span>
        );
      }
      if (invoice.status === "OVERDUE") {
        return (
          <span className="inline-flex items-center rounded-full bg-red-50 px-2 py-1 text-xs font-medium text-red-700">
            Overdue
          </span>
        );
      }
      return (
        <span className="inline-flex items-center rounded-full bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700">
          Open
        </span>
      );
    };

    return (
      <Card className="mb-4">
        <Card.Header className={`${!isExpanded && "border-b-0"}`}>
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-3">
              <Button outline onClick={() => setIsExpanded(!isExpanded)}>
                {isExpanded ? (
                  <ChevronUpIcon className="h-5 w-5 text-gray-400" />
                ) : (
                  <ChevronDownIcon className="h-5 w-5 text-gray-400" />
                )}
              </Button>
              <div>
                <div className="flex items-center gap-2">
                  <Text className="font-medium text-xl">
                    Invoice #{invoice.number}
                  </Text>
                  {getStatusLabel()}
                </div>
                <Text className="text-sm text-gray-500">
                  Due {formatDate(invoice.dueDate)}
                </Text>
              </div>
            </div>
            <div className="flex items-center gap-4">
              <div className="text-left">
                <Text className="text-sm text-gray-500">Amount</Text>
                <Text
                  className={`text-lg font-medium ${
                    invoice.voided
                      ? "text-gray-600"
                      : isPaid
                      ? "text-green-600"
                      : ""
                  }`}
                >
                  {invoice.voided ? "VOID" : formatCurrency(remainingBalance)}
                </Text>
              </div>
              
              <Button 
                outline 
                onClick={() => setIsDownloadInvoiceOpen(true)}
                title="Download Invoice PDF"
              >
                <DocumentArrowDownIcon className="h-5 w-5" />
              </Button>

              {user.role === 'GU' && !invoice.voided && family?.stripePaymentMethodId && (
                <Button outline disabled={invoice.paid} onClick={() => setIsCreatePaymentOpen(true)}>
                  <CreditCardIcon />
                </Button>
              )}
              {!invoice.voided && user.role !== 'GU' && (
                <Dropdown>
                  <DropdownButton outline disabled={invoice.paid}>
                    <EllipsisVerticalIcon />
                  </DropdownButton>
                  <DropdownMenu>
                    <DropdownItem 
                      onClick={() => setIsCreatePaymentOpen(true)} 
                      disabled={invoice.paid}
                    >
                      <CreditCardIcon/>Create Payment
                    </DropdownItem>
                    <DropdownItem 
                      onClick={() => setIsModifyInvoiceOpen(true)} 
                      disabled={invoice.paid}
                    >
                      <PencilIcon/>Edit
                    </DropdownItem>
                    <DropdownItem 
                      onClick={() => setIsVoidInvoiceOpen(true)} 
                      disabled={invoice.paid}
                    >
                      <XCircleIcon/>Void
                    </DropdownItem>
                    <DropdownItem 
                      onClick={() => setIsDeleteInvoiceOpen(true)} 
                      disabled={invoice.paid}
                    >
                      <TrashIcon/>Delete
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              )}
            </div>
          </div>
        </Card.Header>

        {isExpanded && (
          <Card.Body>
            <DescriptionList>
              <DescriptionTerm>Invoice Date</DescriptionTerm>
              <DescriptionDetails>
                <Text>{formatDate(invoice.date)}</Text>
              </DescriptionDetails>
              <DescriptionTerm>Due Date</DescriptionTerm>
              <DescriptionDetails>
                <Text>{formatDate(invoice.dueDate)}</Text>
              </DescriptionDetails>
            </DescriptionList>

            <div className="mt-4">
              <Text className="font-medium mb-2">Line Items</Text>
              <div className="border rounded-lg divide-y">
                {invoice.lineItems.map((item) => (
                  <div
                    key={item.id}
                    className="p-3 flex justify-between items-center"
                  >
                    <div>
                      <Text className="font-medium">{item.description}</Text>
                      <Text className="text-sm text-gray-500">
                        Quantity: {item.quantity} ×{" "}
                        {formatCurrency(item.price)}
                      </Text>
                    </div>
                    <Text className="font-medium">
                      {formatCurrency(item.quantity * item.price)}
                    </Text>
                  </div>
                ))}
              </div>
            </div>

            <div className="mt-4">
              <Text className="font-medium mb-2">Payments</Text>
              {invoice.payments && invoice.payments.length > 0 ? (
                <div className="border rounded-lg divide-y">
                  {invoice.payments.map((payment) => {
                    const totalRefundedForPayment =
                      payment.refunds?.reduce(
                        (sum, refund) => Number(sum) + Number(refund.amount || 0),
                        0
                      ) || 0;
                    console.log(totalRefundedForPayment)
                    const remainingAmount = Number(payment.amount) - totalRefundedForPayment;
                    console.log(remainingAmount)
                    const canBeRefunded = remainingAmount > 0;
                    const refundStatus =
                      totalRefundedForPayment > 0
                        ? Math.abs(totalRefundedForPayment - payment.amount) < 0.01
                          ? "Full Refund"
                          : "Partial Refund"
                        : null;

                    return (
                      <div
                        key={payment.id}
                        className="p-3 flex justify-between items-center"
                      >
                        <div>
                          <div className="flex items-center gap-2">
                            <Text className="font-medium">
                              {payment.paymentMethodType === 'card' ? payment.paymentMethodName : 'Cash/Check/Other'}
                            </Text>
                            {refundStatus && (
                              <span
                                className={`inline-flex items-center rounded-full px-2 py-0.5 text-xs font-medium ${refundStatus === "Full Refund"
                                    ? "bg-red-50 text-red-600"
                                    : "bg-yellow-50 text-yellow-600"
                                  }`}
                              >
                                {refundStatus}
                              </span>
                            )}
                          </div>
                          <div className="flex flex-col">
                            <Text className="text-sm text-gray-500">
                              {formatDate(payment.date)}
                            </Text>
                            {totalRefundedForPayment > 0 && (
                              <Text className="text-xs text-red-600">
                                Refunded:{" "}
                                {formatCurrency(totalRefundedForPayment)}
                              </Text>
                            )}
                          </div>
                        </div>
                        <div className="flex items-center gap-4">
                          <div className="text-right">
                            <Text className="font-medium text-green-600">
                              -{formatCurrency(payment.amount)}
                            </Text>
                            {totalRefundedForPayment > 0 && (
                              <Text className="text-xs text-red-600">
                                Net: {formatCurrency(remainingAmount)}
                              </Text>
                            )}
                          </div>
                          <Button
                            outline
                            size="sm"
                            onClick={() => {
                              setSelectedPayment(payment);
                              setIsDownloadReceiptOpen(true);
                            }}
                            title="Download Receipt PDF"
                          >
                            <DocumentArrowDownIcon className="h-5 w-5" />
                          </Button>
                          {canBeRefunded && user.role !== 'GU' && (
                            <CreateRefund
                              invoice={invoice}
                              payment={payment}
                              family={family}
                              refetch={invoicesRefetch}
                              remainingAmount={remainingAmount}
                            >
                              <ReceiptRefundIcon className="h-5 w-5 text-red-600" />
                            </CreateRefund>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="text-center py-4 border rounded-lg text-gray-500">
                  No payments recorded
                </div>
              )}
            </div>

            <div className="mt-4">
              <Text className="font-medium mb-2">Refunds</Text>
              {invoice.refunds && invoice.refunds.length > 0 ? (
                <div className="border rounded-lg divide-y">
                  {invoice.refunds.map((refund) => (
                    <div
                      key={refund.id}
                      className="p-3 flex justify-between items-center"
                    >
                      <div>
                        <Text className="font-medium">
                          Refund - {refund.reason}
                        </Text>
                        <Text className="text-sm text-gray-500">
                          {formatDate(refund.date)}
                        </Text>
                      </div>
                      <Text className="font-medium text-red-600">
                        +{formatCurrency(refund.amount)}
                      </Text>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center py-4 border rounded-lg text-gray-500">
                  No refunds issued
                </div>
              )}
            </div>

            <div className="mt-6 border-t pt-4">
              <div className="space-y-2">
                <div className="flex justify-between">
                  <Text className="font-medium">Invoice Total</Text>
                  <Text>{formatCurrency(invoice.total)}</Text>
                </div>
                <div className="flex justify-between text-green-600">
                  <Text className="font-medium">Total Payments</Text>
                  <Text>-{formatCurrency(invoice.amountPaid)}</Text>
                </div>
                {totalRefunds > 0 && (
                  <div className="flex justify-between text-red-600">
                    <Text className="font-medium">Total Refunds</Text>
                    <Text>+{formatCurrency(totalRefunds)}</Text>
                  </div>
                )}
                <div className="flex justify-between pt-2 border-t">
                  <Text className="font-medium">Remaining Balance</Text>
                  <Text
                    className={`font-bold ${remainingBalance === 0 ? "text-green-600" : "text-red-600"
                      }`}
                  >
                    {formatCurrency(remainingBalance)}
                  </Text>
                </div>
              </div>
            </div>
          </Card.Body>
        )}
        <CreatePayment 
          isOpen={isCreatePaymentOpen} 
          setIsOpen={setIsCreatePaymentOpen} 
          invoice={invoice} 
          family={family} 
          refetch={invoicesRefetch}
        />
        <ModifyInvoice 
          isOpen={isModifyInvoiceOpen} 
          setIsOpen={setIsModifyInvoiceOpen} 
          invoice={invoice} 
          refetch={invoicesRefetch}
        />
        <VoidInvoice 
          isOpen={isVoidInvoiceOpen} 
          setIsOpen={setIsVoidInvoiceOpen} 
          invoice={invoice} 
          refetch={invoicesRefetch}
        />
        <DeleteInvoice 
          isOpen={isDeleteInvoiceOpen} 
          setIsOpen={setIsDeleteInvoiceOpen} 
          invoice={invoice} 
          refetch={invoicesRefetch}
        />
        <DownloadAlert
          isOpen={isDownloadInvoiceOpen}
          setIsOpen={setIsDownloadInvoiceOpen}
          type="invoice"
          invoice={invoice}
          onDownload={() => handleDownloadInvoice(invoice)}
        />
        <DownloadAlert
          isOpen={isDownloadReceiptOpen}
          setIsOpen={setIsDownloadReceiptOpen}
          type="receipt"
          payment={selectedPayment}
          onDownload={() => selectedPayment && handleDownloadReceipt(selectedPayment.id)}
        />
      </Card>
    );
  };

  if (invoicesLoading || familyLoading) {
    return (
      <div className="space-y-6">
        <PaymentMethodsSkeleton />

        <Card>
          <Card.Header className="flex justify-between items-center">
            <div className="h-6 w-32 bg-gray-200 rounded animate-pulse" />
            <div className="h-9 w-36 bg-gray-200 rounded animate-pulse" />
          </Card.Header>
          <Card.Body>
            {[...Array(3)].map((_, index) => (
              <InvoiceCardSkeleton key={index} />
            ))}
          </Card.Body>
        </Card>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* New Payment Methods Card */}
      <Card>
        <Card.Header>
          <Card.Title>Payment Methods</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="flex items-center justify-between">
            <div className="flex items-start gap-x-6">
              {family?.stripePaymentMethodId ? (
                <div className="flex gap-x-4">
                  <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-gray-50">
                    <CreditCardIcon
                      className="h-6 w-6 text-gray-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div>
                    <Text className="font-medium">
                      {family.stripePaymentMethodName}
                    </Text>
                    <Text className="text-sm text-gray-500">
                      Default Payment Method
                    </Text>
                  </div>
                </div>
              ) : (
                <div className="flex gap-x-4">
                  <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-gray-50">
                    <CreditCardIcon
                      className="h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div>
                    <Text className="font-medium text-gray-500">
                      No payment method
                    </Text>
                    <Text className="text-sm text-gray-500">
                      Add a payment method to enable auto-pay
                    </Text>
                  </div>
                </div>
              )}
            </div>

            <div className="flex items-center gap-x-4">
              <CreatePaymentMethod
                familyId={family?.uuid}
                buttonText={family?.stripePaymentMethodId ? "Change" : "Add"}
              />
              {family?.stripePaymentMethodId && (
                <div className="flex items-center gap-x-2">
                  <Text className="text-sm">Auto-Pay</Text>
                  <Switch
                    checked={family?.autoPayEnabled}
                    onChange={() => {
                      handleAutoPayToggle(!family?.autoPayEnabled);
                    }}
                    color="sprout"
                  />
                </div>
              )}
            </div>
          </div>
        </Card.Body>
      </Card>

      {/* Existing Billing History Card */}
      <Card>
        <Card.Header className="flex justify-between items-center">
          <Card.Title>Invoices</Card.Title>
          {user.role !== 'GU' && (
          <CreateInvoice student={student} refetch={invoicesRefetch}>
            <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" /> Create
            Invoice
            </CreateInvoice>
          )}
        </Card.Header>
        <Card.Body>
          {invoicesData?.invoicesByStudent.edges.length > 0 ? (
            <>
              {invoicesData.invoicesByStudent.edges.map(({ node: invoice }) => (
                <InvoiceCard key={invoice.uuid} invoice={invoice} />
              ))}
              
              <Pagination className="mt-6">
                <PaginationPrevious
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                />
                <PaginationList>
                  {[...Array(currentPage + (invoicesData.invoicesByStudent.pageInfo.hasNextPage ? 1 : 0))].map((_, index) => (
                    <PaginationPage
                      key={index + 1}
                      onClick={() => handlePageChange(index + 1)}
                      current={currentPage === index + 1}
                    >
                      {index + 1}
                    </PaginationPage>
                  ))}
                </PaginationList>
                <PaginationNext
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={!invoicesData.invoicesByStudent.pageInfo.hasNextPage}
                />
              </Pagination>
            </>
          ) : (
            <div className="text-center py-12">
              <div className="mx-auto h-12 w-12 text-gray-400">
                <CreditCardIcon className="h-12 w-12" />
              </div>
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                No invoices
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                No billing history available for this student.
              </p>
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

export default StudentBilling;
