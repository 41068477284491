import { Label } from "@/components/ui/label"
import { Switch } from "@/components/ui/switch"
import { Separator } from "@/components/ui/separator"

interface SettingsNotificationsProps {
  orgForm: {
    sendPaymentReceivedNotifications: boolean
    sendPaymentFailedNotifications: boolean
    sendInvoiceDueSoonNotifications: boolean
    sendInvoiceOverdueNotifications: boolean
  }
  handleSwitchChange: (name: string, checked: boolean) => void
}

export function SettingsEmails({
  orgForm,
  handleSwitchChange,
}: SettingsNotificationsProps) {
  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">Email Preferences</h3>
        <p className="text-sm text-muted-foreground">
          Configure which emails you want Sprout to send to your families.
        </p>
      </div>
      <Separator />
      <div className="space-y-6">
        <div className="space-y-4">
          <div className="flex items-center justify-between space-x-4">
            <div className="flex-1 space-y-1">
              <Label>Payment Received</Label>
              <p className="text-[13px] text-muted-foreground">
                Send an email to families when payments are successfully processed.
              </p>
            </div>
            <Switch
              checked={orgForm.sendPaymentReceivedNotifications}
              onCheckedChange={(checked) =>
                handleSwitchChange("sendPaymentReceivedNotifications", checked)
              }
            />
          </div>

          <div className="flex items-center justify-between space-x-4">
            <div className="flex-1 space-y-1">
              <Label>Payment Failed</Label>
              <p className="text-[13px] text-muted-foreground">
                Send an email to families when payments fail to process.
              </p>
            </div>
            <Switch
              checked={orgForm.sendPaymentFailedNotifications}
              onCheckedChange={(checked) =>
                handleSwitchChange("sendPaymentFailedNotifications", checked)
              }
            />
          </div>

          <div className="flex items-center justify-between space-x-4">
            <div className="flex-1 space-y-1">
              <Label>Invoice Due Soon</Label>
              <p className="text-[13px] text-muted-foreground">
                Send an email to families when invoices are approaching their due date.
              </p>
            </div>
            <Switch
              checked={orgForm.sendInvoiceDueSoonNotifications}
              onCheckedChange={(checked) =>
                handleSwitchChange("sendInvoiceDueSoonNotifications", checked)
              }
            />
          </div>

          <div className="flex items-center justify-between space-x-4">
            <div className="flex-1 space-y-1">
              <Label>Invoice Overdue</Label>
              <p className="text-[13px] text-muted-foreground">
                Send an email to families when invoices become overdue.
              </p>
            </div>
            <Switch
              checked={orgForm.sendInvoiceOverdueNotifications}
              onCheckedChange={(checked) =>
                handleSwitchChange("sendInvoiceOverdueNotifications", checked)
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
} 