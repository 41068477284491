import { Button } from "@/components/ui/button"
import { Separator } from "@/components/ui/separator"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Folders, MoreHorizontal, Pencil, Trash, Plus } from "lucide-react"
import { useQuery, gql, useMutation } from "@apollo/client"
import { CreateProgramDialog } from "@/components/dialogs/CreateProgramDialog"
import { EditProgramDialog } from "@/components/dialogs/EditProgramDialog"
import { useState } from "react"
import { Switch } from "@/components/ui/switch"
import { Label } from "@/components/ui/label"
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog"
import { toast } from "@/hooks/use-toast"
import { Skeleton } from "@/components/ui/skeleton"

const GET_PROGRAMS = gql`
  query Programs($showArchived: Boolean!) {
    programs(showArchived: $showArchived) {
      id
      name
      description
      maxEnrollments
      defaultRate
      defaultRateFrequency
      daysOfWeek
      requiredPaperwork {
        id
      }
      createdAt
      lastUpdated
      active
    }
  }
`

const DELETE_PROGRAM = gql`
  mutation DeleteProgram($input: DeleteProgramInput!) {
    deleteProgram(input: $input) {
      success
    }
  }
`

export function SettingsPrograms() {
  const [showArchived, setShowArchived] = useState(false)
  const { data: programsData, loading, refetch: refetchPrograms } = useQuery(GET_PROGRAMS, {
    variables: { showArchived: showArchived }
  })
  const [editingProgram, setEditingProgram] = useState(null)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [programToDelete, setProgramToDelete] = useState<any>(null)

  const [deleteProgram] = useMutation(DELETE_PROGRAM, {
    onCompleted: () => {
      toast({
        title: "Success",
        description: "Program deleted successfully",
      })
      refetchPrograms()
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      })
    },
  })

  const handleOpenEditProgram = (program) => {
    setEditingProgram(program)
    setEditDialogOpen(true)
  }

  const handleOpenDeleteProgramAlert = (program: any) => {
    setProgramToDelete(program)
    setDeleteDialogOpen(true)
  }

  const handleDeleteProgram = async () => {
    if (programToDelete) {
      await deleteProgram({
        variables: { 
          input: {
            id: programToDelete.id 
          }
        }
      })
    }
    setDeleteDialogOpen(false)
    setProgramToDelete(null)
  }

  const TableSkeleton = () => (
    <TableRow>
      <TableCell><Skeleton className="h-4 w-[250px]" /></TableCell>
      <TableCell><Skeleton className="h-4 w-[150px]" /></TableCell>
      <TableCell className="text-center"><Skeleton className="h-4 w-[80px] mx-auto" /></TableCell>
      <TableCell><Skeleton className="h-4 w-[100px]" /></TableCell>
      <TableCell><Skeleton className="h-8 w-8" /></TableCell>
    </TableRow>
  )

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="space-y-1">
          <h3 className="text-lg font-medium">Programs</h3>
          <p className="text-sm text-muted-foreground">
            Manage your organization's programs and their settings.
          </p>
        </div>
        <div className="flex justify-end">
          <CreateProgramDialog refetch={refetchPrograms} />
        </div>
      </div>
      <Separator />
      <div className="space-y-6">
        <div className="rounded-md border">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Name</TableHead>
                <TableHead>Days</TableHead>
                <TableHead className="text-center">Capacity</TableHead>
                <TableHead>Last Updated</TableHead>
                <TableHead className="w-[50px]"></TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {loading ? (
                <>
                  <TableSkeleton />
                  <TableSkeleton />
                  <TableSkeleton />
                </>
              ) : programsData?.programs?.length ? (
                programsData.programs.map((program) => (
                  <TableRow key={program.id}>
                    <TableCell className="font-medium">{program.name}</TableCell>
                    <TableCell>{program.daysOfWeek
                                  ? JSON.parse(program.daysOfWeek).join(", ")
                                  : ""}</TableCell>
                    <TableCell className="text-center">{program.maxEnrollments}</TableCell>
                    <TableCell>{new Date(program.lastUpdated).toLocaleDateString()}</TableCell>
                    <TableCell>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button variant="ghost" className="h-8 w-8 p-0">
                            <span className="sr-only">Open menu</span>
                            <MoreHorizontal className="h-4 w-4" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                          <DropdownMenuItem onClick={() => handleOpenEditProgram(program)}>
                            <Pencil className="mr-2 h-4 w-4" />
                            Edit
                          </DropdownMenuItem>
                          <DropdownMenuItem 
                            onClick={() => handleOpenDeleteProgramAlert(program)}
                            className="text-destructive"
                          >
                            <Trash className="mr-2 h-4 w-4" />
                            Delete
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} className="text-center py-12">
                    <div className="space-y-2">
                      <Folders className="h-12 w-12 text-muted-foreground mx-auto" />
                      <p className="text-xl font-semibold text-muted-foreground">No programs found</p>
                      <p className="text-sm text-muted-foreground">
                        Add your first program by clicking the "Add Program" button above.
                      </p>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
      <EditProgramDialog
        open={editDialogOpen}
        onOpenChange={setEditDialogOpen}
        program={editingProgram}
        refetch={refetchPrograms}
      />
      <AlertDialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This will permanently delete the program "{programToDelete?.name}". 
              This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDeleteProgram}
              className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  )
} 