import { Alert, AlertActions, AlertDescription, AlertTitle } from '../../components/catalyst/alert'
import { Button } from '../../components/catalyst/button'
import { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { DropdownItem } from '../../components/catalyst/dropdown'
import toast, { Toaster } from "react-hot-toast";

const DELETE_INVOICE = gql`
  mutation DeleteInvoice($input: DeleteInvoiceInput!) {
    deleteInvoice(input: $input) {
      success
    }
  }
`;

export default function DeleteInvoice({ invoice, refetch, isOpen, setIsOpen, ...props }) {

  const [deleteInvoice] = useMutation(DELETE_INVOICE);

  const handleDeleteInvoice = async () => {
    try {
      await deleteInvoice({
        variables: { input: { id: invoice.uuid } },
    });
    toast.success('Invoice deleted')
      setIsOpen(false)
      refetch()
    } catch (error) {
      toast.error('Error deleting invoice: ' + error.message)
    }
  }

  return (
    <>
      <Alert open={isOpen} onClose={setIsOpen}>
        <AlertTitle>Are you sure you want to delete invoice {invoice.number}?</AlertTitle>
        <AlertDescription>
          This action cannot be undone.
        </AlertDescription>
        <AlertActions>
          <Button plain onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button color="red" onClick={handleDeleteInvoice}>Delete</Button>
        </AlertActions>
      </Alert>
    </>
  )
}