import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import { ENVIRONMENT } from "./config.js";
import { PHAPIKEY } from "./config.js";
import { API_BASE_URL } from "./config.js";
import { AuthProvider } from "./contexts/AuthContext.jsx";
import { PostHogProvider } from "posthog-js/react";
import { UserProvider } from "./contexts/UserContext.jsx";
import { ApolloClient, InMemoryCache, ApolloProvider, ApolloLink, HttpLink, Observable } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import * as Sentry from "@sentry/react";
import { GetCSRFToken } from "./utils/getCSRF.js";
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";

Sentry.init({
  dsn: "https://b8005b5c3abcf13a542848b194adb8b4@o4507642884784128.ingest.us.sentry.io/4507977515008000",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  environment: ENVIRONMENT,
});

const options = {
  api_host: "https://us.i.posthog.com",
};

loadErrorMessages()

const requestAccessToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  if (refreshToken) {
    const response = await fetch(`${API_BASE_URL}/api/v1/token/refresh`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refresh: refreshToken }),
    });

    if (response.ok) {
      const data = await response.json();
      localStorage.setItem('accessToken', data.access);
      return data.access;
    } else {
      throw new Error('Failed to refresh token');
    }
  }
};

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(err => {
      console.log(`[GraphQL error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`);
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    if (networkError.statusCode === 401) {
      console.log('Detected 401 Unauthorized error, attempting to refresh token...');
      return new Observable(observer => {
        requestAccessToken()
          .then(newAccessToken => {
            operation.setContext(({ headers = {} }) => ({
              headers: {
                ...headers,
                authorization: `Bearer ${newAccessToken}`,
              },
            }));
            forward(operation).subscribe(observer);
          })
          .catch(error => {
            console.error('Error forwarding operation after token refresh', error);
            observer.error(error);
          });
      });
    }
  }
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('accessToken');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  };
});

const httpLink = createUploadLink({
  uri: API_BASE_URL + "/api/v1/graphql",
  credentials: "include",
});

const client = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
});

createRoot(document.getElementById("root")).render(
  <PostHogProvider apiKey={PHAPIKEY} options={options}>
    <AuthProvider>
      <ApolloProvider client={client}>
        <UserProvider>
            <App />
        </UserProvider>
      </ApolloProvider>
    </AuthProvider>
  </PostHogProvider>
);
