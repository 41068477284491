import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Dialog, DialogTitle, DialogDescription, DialogActions, DialogBody } from '../../components/catalyst/dialog';
import { Button } from '../../components/catalyst/button';
import { gql } from 'graphql-tag';
import { Field, FieldGroup, Label, ErrorMessage } from '../../components/catalyst/fieldset';
import { Input } from '../../components/catalyst/input';
import { Select } from '../../components/catalyst/select';
import { Switch } from '../../components/catalyst/switch';

export const UPDATE_ATTENDANCE_RECORD = gql`
  mutation UpdateAttendanceRecord($input: UpdateAttendanceRecordInput!) {
    updateAttendanceRecord(input: $input) {
      attendanceRecord {
        id
        date
        signedInAt
        signedOutAt
      }
    }
  }
`;

const GET_GUARDIANS_BY_STUDENT = gql`
  query GuardiansByStudent($student: ID!) {
    guardiansByStudent(student: $student) {
      firstName
      lastName
      id
    }
  }
`;

const formatDateTimeForInput = (isoString) => {
  if (!isoString) return '';
  const date = new Date(isoString);
  return date.toLocaleString('sv-SE', { 
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: undefined
  }).replace(' ', 'T');
};

export default function UpdateAttendanceRecord({ student, record, refetch, ...props }) {
  const [isOpen, setIsOpen] = useState(false);
  const [signedInAt, setSignedInAt] = useState(formatDateTimeForInput(record.signedInAt));
  const [signedOutAt, setSignedOutAt] = useState(formatDateTimeForInput(record.signedOutAt));
  const [signedBy, setSignedBy] = useState(record.signedInBy?.id || '');
  const [signedOutBy, setSignedOutBy] = useState(record.signedOutBy?.id || '');
  const [errors, setErrors] = useState({});
  const [showErrors, setShowErrors] = useState(false);
  const [graphqlError, setGraphqlError] = useState(null);
  const [guardians, setGuardians] = useState([]);
  const [includeSignOut, setIncludeSignOut] = useState(!!record.signedOutAt);
  const { data } = useQuery(GET_GUARDIANS_BY_STUDENT, {
    variables: { student: student.uuid },
  });

  useEffect(() => {
    if (data) {
      setGuardians(data.guardiansByStudent);
    }
  }, [data]);

  const [updateAttendanceRecord, { loading }] = useMutation(UPDATE_ATTENDANCE_RECORD, {
    onCompleted: async () => {
      await refetch();
      handleClose();
    },
    onError: (error) => {
      setGraphqlError(error.message);
      setShowErrors(true);
    },
  });

  const handleClose = () => {
    setIsOpen(false);
    setSignedInAt(formatDateTimeForInput(record.signedInAt));
    setSignedOutAt(formatDateTimeForInput(record.signedOutAt));
    setSignedBy(record.signedInBy?.id || '');
    setSignedOutBy(record.signedOutBy?.id || '');
    setIncludeSignOut(!!record.signedOutAt);
    setErrors({});
    setShowErrors(false);
    setGraphqlError(null);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!signedInAt) newErrors.signedInAt = 'Signed in time is required';
    if (!signedBy) newErrors.signedBy = 'Signed in by is required';
    if (includeSignOut) {
      if (!signedOutAt) newErrors.signedOutAt = 'Signed out time is required when sign-out is enabled';
      if (!signedOutBy) newErrors.signedOutBy = 'Signed out by is required when sign-out is enabled';
    }
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const newErrors = validateForm();
    setErrors(newErrors);
    setShowErrors(true);

    if (Object.keys(newErrors).length === 0) {
      const signedInDateTime = new Date(signedInAt);
      const signedOutDateTime = signedOutAt ? new Date(signedOutAt) : null;
      const date = signedInDateTime.toISOString().split('T')[0];

      const mutationInput = {
        id: record.uuid,
        date,
        signedInAt: signedInDateTime.toISOString(),
        signedInBy: signedBy,
      };

      if (signedOutDateTime && signedOutBy) {
        mutationInput.signedOutAt = signedOutDateTime.toISOString();
        mutationInput.signedOutBy = signedOutBy;
      }

      updateAttendanceRecord({
        variables: {
          input: mutationInput,
        },
      });
    }
  };

  return (
    <>
      <Button outline type="button" onClick={() => setIsOpen(true)}>
        Edit
      </Button>

      <Dialog 
        size="lg" 
        open={isOpen} 
        onClose={() => setIsOpen(false)} 
      >
        <DialogTitle>Edit Attendance Record</DialogTitle>
        <DialogDescription>
          Update student attendance record.
        </DialogDescription>
        <form onSubmit={handleSubmit}>
          <DialogBody>
            <FieldGroup>
              <div className="grid grid-cols-2 gap-4">
                <Field>
                  <Label>Signed In</Label>
                  <Input
                    type="datetime-local"
                    value={signedInAt}
                    onChange={(e) => setSignedInAt(e.target.value)}
                    invalid={showErrors && !!errors.signedInAt}
                  />
                  {showErrors && errors.signedInAt && (
                    <ErrorMessage>{errors.signedInAt}</ErrorMessage>
                  )}
                </Field>
                <Field>
                  <Label>Signed In By</Label>
                  <Select 
                    value={signedBy} 
                    onChange={(e) => setSignedBy(e.target.value)}
                    invalid={showErrors && !!errors.signedBy}
                  >
                    <option value="" disabled>Select a Guardian</option>
                    {guardians.map(guardian => (
                      <option key={guardian.id} value={guardian.id}>
                        {guardian.firstName} {guardian.lastName}
                      </option>
                    ))}
                  </Select>
                  {showErrors && errors.signedBy && (
                    <ErrorMessage>{errors.signedBy}</ErrorMessage>
                  )}
                </Field>
              </div>

              <div className="border-t border-gray-200 dark:border-gray-700 my-4 pt-4">
                <div className="flex items-center justify-between mb-4">
                  <div>
                    <h3 className="text-sm font-medium text-gray-900 dark:text-gray-100">Include Sign-out</h3>
                    <p className="text-sm text-gray-500 dark:text-gray-400">Optionally backfill the sign-out time for the student</p>
                  </div>
                  <Switch
                    checked={includeSignOut}
                    onChange={() => setIncludeSignOut(!includeSignOut)}
                    label="Include sign-out"
                  />
                </div>
              </div>

              {includeSignOut && (
                <div className="grid grid-cols-2 gap-4">
                  <Field>
                    <Label>Signed Out</Label>
                    <Input
                      type="datetime-local"
                      value={signedOutAt}
                      onChange={(e) => setSignedOutAt(e.target.value)}
                      invalid={showErrors && !!errors.signedOutAt}
                    />
                    {showErrors && errors.signedOutAt && (
                      <ErrorMessage>{errors.signedOutAt}</ErrorMessage>
                    )}
                  </Field>
                  <Field>
                    <Label>Signed Out By</Label>
                    <Select 
                      value={signedOutBy} 
                      onChange={(e) => setSignedOutBy(e.target.value)}
                      invalid={showErrors && !!errors.signedOutBy}
                    >
                      <option value="" disabled>Select a Guardian</option>
                      {guardians.map(guardian => (
                        <option key={guardian.id} value={guardian.id}>
                          {guardian.firstName} {guardian.lastName}
                        </option>
                      ))}
                    </Select>
                    {showErrors && errors.signedOutBy && (
                      <ErrorMessage>{errors.signedOutBy}</ErrorMessage>
                    )}
                  </Field>
                </div>
              )}
            </FieldGroup>
          </DialogBody>
          <DialogActions className="flex justify-end">
            <Button plain onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit" color="sprout" disabled={loading}>
              Update Record
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
} 