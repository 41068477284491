import React, { useEffect, useState } from 'react';
import Card from '../../components/catalyst/card';
import { Button } from '../../components/catalyst/button';
import { Select } from '../../components/catalyst/select';
import { Input } from '../../components/catalyst/input';
import { Text } from '../../components/catalyst/text';
import { Heading } from '../../components/catalyst/heading';
import {
  DocumentArrowDownIcon,
  ClockIcon,
  UsersIcon,
  BanknotesIcon,
  DocumentChartBarIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-hot-toast';
import { useUser } from '../../hooks/useUser';
import { usePostHog } from 'posthog-js/react';

const GENERATE_REPORT = gql`
  mutation GenerateReport($input: GenerateReportInput!) {
    generateReport(input: $input) {
      base64Report
    }
  }
`;

const formatDate = (date) => {
  if (!date) return '';
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format(new Date(date));
};

const REPORT_TYPES = [
  {
    id: 'attendance',
    name: 'Attendance Report',
    description: 'Download detailed attendance records for all students',
    icon: ClockIcon,
    color: 'blue',
  },
  {
    id: 'payments',
    name: 'Payments Report',
    description: 'Financial summary of all payments',
    icon: BanknotesIcon,
    color: 'green',
  },
  {
    id: 'enrollment',
    name: 'Enrollment Report',
    description: 'Current and historical enrollment statistics',
    icon: UsersIcon,
    color: 'purple',
  },
];

function ReportsPage() {
  const { user } = useUser();
  const posthog = usePostHog();
  // Get first and current day of current month in organization's timezone
  const getDefaultDateRange = () => {
    const now = new Date();
    const userTimeZone = user?.organization?.timezone || 'UTC';
    
    // Create date in user's timezone
    const today = new Date(now.toLocaleString('en-US', { timeZone: userTimeZone }));
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

    // Format dates to YYYY-MM-DD
    const formatToISODate = (date) => {
      return date.toLocaleDateString('en-CA', { // en-CA gives YYYY-MM-DD format
        timeZone: userTimeZone,
      });
    };

    return {
      from: formatToISODate(firstDay),
      to: formatToISODate(today)
    };
  };

  useEffect(() => {
    posthog.capture('reports_page_viewed');
  }, [posthog]);

  // Initialize state with default date range
  const [dateRange, setDateRange] = useState(getDefaultDateRange());
  
  // Format dates to YYYY-MM-DD for input type="date"
  const formatDateForInput = (date) => {
    return date.toISOString().split('T')[0];
  };

  const [selectedReport, setSelectedReport] = useState('attendance');
  const [generateReport, { loading: generateReportLoading }] = useMutation(GENERATE_REPORT);
  const [selectedStudents, setSelectedStudents] = useState('all');

  const handleGenerateReport = async () => {
    posthog.capture('report_generated', {
      reportType: selectedReport,
      startDate: dateRange.from,
      endDate: dateRange.to,
      include: selectedStudents
    });
    const toastId = 'generate-report';
    toast.loading('Generating report...', { id: toastId });
    try {
      const { data } = await generateReport({ 
        variables: { 
          input: { 
            reportType: selectedReport, 
            startDate: dateRange.from, 
            endDate: dateRange.to,
            include: selectedStudents
          } 
        } 
      });
      
      const base64Report = data.generateReport.base64Report;
      const blob = new Blob([Uint8Array.from(atob(base64Report), c => c.charCodeAt(0))], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${selectedReport}_report.csv`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      toast.success('Report generated successfully', { id: toastId });
    } catch (error) {
      toast.error(error.message, { id: toastId });
    }
  };



  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <Heading>Reports</Heading>
          <Text className="text-gray-600 mt-1">
            Generate detailed reports to track attendance, revenue, and enrollment metrics
          </Text>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {REPORT_TYPES.map((report) => (
          <Card
            key={report.id}
            className={
              selectedReport === report.id 
                ? 'cursor-pointer transition-all hover:shadow-md ring-2 !ring-sprout-600 !ring-opacity-100 bg-sprout-50'
                : 'cursor-pointer transition-all hover:shadow-md hover:border-sprout-200'
            }
            onClick={() => setSelectedReport(report.id)}
          >
            <Card.Body>
              <div className="flex items-center">
                <div className={`shrink-0 p-2 bg-${report.color}-50 rounded-lg`}>
                  <report.icon className={`h-6 w-6 text-${report.color}-600`} />
                </div>
                <div className="min-w-0 ml-4">
                  <Text className={`text-sm font-medium ${
                    selectedReport === report.id 
                      ? 'text-sprout-900' 
                      : 'text-gray-900'
                  }`}>
                    {report.name}
                  </Text>
                  <Text className="text-sm text-gray-500 mt-1">{report.description}</Text>
                </div>
              </div>
            </Card.Body>
          </Card>
        ))}
      </div>

      <Card>
        <Card.Header>
          <div className="flex items-center justify-between">
            <div>
              <Card.Title>Generate Report</Card.Title>
              <Text className="text-sm text-gray-500 mt-1">
                {selectedReport === 'attendance' && 'Track student attendance patterns and total hours'}
                {selectedReport === 'payments' && 'Analyze payment history for all students'}
                {selectedReport === 'enrollment' && 'Monitor program enrollment status and rates'}
              </Text>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="space-y-6">
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div>
                <Text className="block text-sm font-medium text-gray-700 mb-2">Date Range</Text>
                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-1">
                    <Text className="block text-xs text-gray-500">From</Text>
                    <Input
                      type="date"
                      value={dateRange.from || ''}
                      onChange={(e) => setDateRange({ ...dateRange, from: e.target.value })}
                    />
                  </div>
                  <div className="space-y-1">
                    <Text className="block text-xs text-gray-500">To</Text>
                    <Input
                      type="date"
                      value={dateRange.to || ''}
                      onChange={(e) => setDateRange({ ...dateRange, to: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className="space-y-1">
                <Text className="block text-sm font-medium text-gray-700 mb-2">Students</Text>
                <Text className="block text-xs text-gray-500">Select which students to include in the report</Text>
                <Select 
                  className="w-full mt-1"
                  value={selectedStudents}
                  onChange={(e) => setSelectedStudents(e.target.value)}
                >
                  <option value="all">All Students</option>
                  <option value="active">Active Students Only</option>
                  <option value="inactive">Inactive Students Only</option>
                </Select>
              </div>
            </div>

            <div className="flex justify-end">
              <Button
                color="sprout"
                onClick={handleGenerateReport}
                disabled={generateReportLoading || !dateRange.from || !dateRange.to}
                className="flex items-center gap-2"
              >
                <DocumentArrowDownIcon className="h-5 w-5" />
                {generateReportLoading ? 'Generating...' : 'Generate Report'}
              </Button>
            </div>
          </div>
        </Card.Body>
        <Card.Footer className="bg-gray-50">
          <div className="flex items-center text-sm text-gray-600">
            <DocumentChartBarIcon className="h-5 w-5 mr-2 text-gray-500" />
            Reports are generated instantly and include the most up-to-date information
          </div>
        </Card.Footer>
      </Card>
    </div>
  );
}

export default ReportsPage;
