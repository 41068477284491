export function generateAvatar(firstName, lastName, size = 200) {
  const canvas = document.createElement('canvas');
  canvas.width = size;
  canvas.height = size;
  const ctx = canvas.getContext('2d');

  // Set solid background color
  ctx.fillStyle = '#2CC937';
  ctx.fillRect(0, 0, size, size);

  // Generate initials safely
  const initials = getInitials(firstName, lastName);

  // Add initials
  const fontSize = size / 2;
  ctx.font = `bold ${fontSize}px Arial, sans-serif`;
  ctx.fillStyle = 'white';
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  
  // Adjust vertical position slightly
  const verticalOffset = size / 2 + fontSize * 0.05;
  ctx.fillText(initials, size / 2, verticalOffset);

  return canvas.toDataURL();
}

function getRandomColor() {
  return '#' + Math.floor(Math.random()*16777215).toString(16).padStart(6, '0');
}

function getInitials(firstName, lastName) {
  const first = (firstName || '').charAt(0).toUpperCase();
  const last = (lastName || '').charAt(0).toUpperCase();
  return (first + last) || '?';
}
