import { Link as WouterLink } from 'wouter'
import * as Headless from '@headlessui/react'
import React from 'react'

export const Link = React.forwardRef(function Link(props, ref) {
  return (
    <Headless.DataInteractive>
      <WouterLink {...props} ref={ref} />
    </Headless.DataInteractive>
  )
})
