import { useState, useEffect } from 'react'
import { Input } from '../../components/catalyst/input'
import { Button } from '../../components/catalyst/button'
import { Field, FieldGroup, Fieldset, Label } from '../../components/catalyst/fieldset'
import { Text } from '../../components/catalyst/text'
import { Heading } from '../../components/catalyst/heading'
import logoIcon from '../../assets/logo-icon.svg'
import { API_BASE_URL } from '../../config'
import { useLocation } from 'wouter'
import { usePostHog } from 'posthog-js/react';
function GuardianSignUp({ id }) {
  const [, setLocation] = useLocation()
  const [loading, setLoading] = useState(true)
  const [guardianData, setGuardianData] = useState(null)
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  const [passwordError, setPasswordError] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [confirmPasswordError, setConfirmPasswordError] = useState('')
  const posthog = usePostHog();

  useEffect(() => {
    posthog.capture('guardian_signup_viewed', {
      guardianId: id
    });
  }, [posthog, id]);

  useEffect(() => {
    const fetchGuardianInvite = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/v1/guardian/signup/${id}`)
        if (!response.ok) throw new Error('Invalid or expired invitation')
        const data = await response.json()
        setGuardianData(data)
      } catch (err) {
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    fetchGuardianInvite()
  }, [id])

  const validatePassword = (pass) => {
    if (pass.length < 8) return 'Password must be at least 8 characters long'
    if (!/[A-Z]/.test(pass)) return 'Password must contain at least one capital letter'
    if (!/[0-9]/.test(pass)) return 'Password must contain at least one number'
    if (!/[!@#$%^&*]/.test(pass)) return 'Password must contain at least one symbol (!@#$%^&*)'
    return ''
  }

  const validatePasswords = (pass, confirmPass) => {
    const passError = validatePassword(pass)
    if (passError) return { passwordError: passError }
    if (pass !== confirmPass) {
      return { confirmPasswordError: 'Passwords do not match' }
    }
    return {}
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError(null)
    setPasswordError('')
    setConfirmPasswordError('')
    
    const { passwordError, confirmPasswordError } = validatePasswords(password, confirmPassword)
    if (passwordError || confirmPasswordError) {
      setPasswordError(passwordError)
      setConfirmPasswordError(confirmPasswordError)
      return
    }

    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/guardian/signup/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          password: password,
        })
      })
      if (!response.ok) throw new Error('Error completing account setup')
      
      setLocation('/')
    } catch (err) {
      setError(err.message)
    }
  }

  return (
    <div className="flex min-h-screen bg-gradient-to-br from-green-50 to-gray-100 dark:from-zinc-900 dark:to-zinc-800">
      <div className="m-auto w-full max-w-lg p-8">
        {loading ? (
          <div className="text-center">Loading...</div>
        ) : error ? (
          <div className="text-center text-red-600">{error}</div>
        ) : (
          <div className="transform transition-all duration-200 ease-in-out">
            <div className="mb-8 text-center">
              <img src={logoIcon} alt="Sprout Logo" className="mx-auto h-12 w-auto" />
              <Heading level={1} className="mt-6 text-4xl font-bold tracking-tight">
                Complete Account Setup
              </Heading>
              <Text className="mt-2 text-lg text-zinc-600 dark:text-zinc-400">
                Enter your details to complete your account setup
              </Text>
            </div>

            <div className="rounded-xl bg-white/80 backdrop-blur-sm dark:bg-zinc-900/80 p-8 shadow-xl ring-1 ring-zinc-950/5 dark:ring-white/10">
              <form onSubmit={handleSubmit} className="space-y-6">
                <Fieldset>
                  <FieldGroup>
                    <div className="grid grid-cols-2 gap-4">
                      <Field>
                        <Label>First Name</Label>
                        <Input
                          type="text"
                          value={guardianData.first_name}
                          disabled
                          className="w-full bg-gray-50"
                        />
                      </Field>
                      <Field>
                        <Label>Last Name</Label>
                        <Input
                          type="text"
                          value={guardianData.last_name}
                          disabled
                          className="w-full bg-gray-50"
                        />
                      </Field>
                    </div>
                    
                    <Field>
                      <Label>Email address</Label>
                      <Input
                        type="email"
                        value={guardianData.email}
                        disabled
                        className="w-full bg-gray-50"
                      />
                    </Field>
                    
                    <Field>
                      <Label>Set Password</Label>
                      <Input
                        type="password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value)
                          const { passwordError, confirmPasswordError } = validatePasswords(
                            e.target.value,
                            confirmPassword
                          )
                          setPasswordError(passwordError || '')
                          setConfirmPasswordError(confirmPasswordError || '')
                        }}
                        required
                        className="w-full"
                        placeholder="••••••••"
                      />
                      {passwordError && (
                        <Text className="mt-2 text-sm text-red-500">
                          {passwordError}
                        </Text>
                      )}
                      <Text className="mt-2 text-xs text-zinc-500">
                        Password must be at least 8 characters long, contain a capital letter, a number, and a symbol
                      </Text>
                    </Field>

                    <Field>
                      <Label>Confirm Password</Label>
                      <Input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => {
                          setConfirmPassword(e.target.value)
                          const { confirmPasswordError } = validatePasswords(
                            password,
                            e.target.value
                          )
                          setConfirmPasswordError(confirmPasswordError || '')
                        }}
                        required
                        className="w-full"
                        placeholder="••••••••"
                      />
                      {confirmPasswordError && (
                        <Text className="mt-2 text-sm text-red-500">
                          {confirmPasswordError}
                        </Text>
                      )}
                    </Field>
                  </FieldGroup>
                </Fieldset>

                <Button 
                  type="submit" 
                  color="sprout" 
                  className="w-full py-2.5 text-base font-medium transition-all duration-200 hover:opacity-90"
                >
                  Complete Account Setup
                </Button>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default GuardianSignUp
